import {
  Box,
  makeStyles,
  styled,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Table } from "react-bootstrap";

export const useStyle = makeStyles((theme) => ({
  backButton: {
    display: "flex",
    gap: 8,
    alignItems: "center",
    color: "#757575",

    "& span": {
      display: "flex",
      gap: 8,
      fontFamily: "Inter",
      fontSize: 14,
      fontWeight: 400,
      cursor: "pointer",

      "& svg": {
        width: 20,
        height: 20,
        cursor: "pointer",
      },
    },
  },
  circularProgress: {
    maxWidth: "1.875rem",
    maxHeight: "1.875rem",
    position: "absolute",
    color: "#FFF",
  },
  titleDetail: {
    display: "flex",
    marginTop: 16,
    "& h1": {
      fontFamily: "Inter",
      fontSize: 22,
      fontWeight: 600,
    },

    "@media (max-width:1200px)": {
      "& h1": {
        fontFamily: "Inter",
        fontSize: 12,
        fontWeight: 600,
      },
    },
  },

  orderTableContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    border: "0.5px solid #C4C4C4",
    marginTop: 24,
    paddingInline: 24,
  },

  orderTableContent: {
    maxHeight: 620,
    overflow: "auto",
  },

  orderTable: {
    minWidth: 955,
    marginBottom: "1rem",
  },

  orderTableHead: {
    "& .titles": {
      "& .titleCheckbox": {
        width: 26,
      },
      "& .titleSKU": {
        width: 32,
      },
      "& .titleDescription": {
        width: 233,
      },
      "& .titleQuantity": {
        width: 82,
      },
      "& .titleTotal": {
        width: 82,
      },
      "& .titleMissingQty": {
        width: 86,
      },
      "& .titleMissingTotalValue": {
        width: 92,
      },
      "& .titleReason": {
        width: 172,
      },
      "& .titleAttachment": {
        textAlign: "center",
        width: 36,
      },
    },

    "& .titles th": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 500,
      color: "#353535",
      paddingInline: 8,
    },
    "& tr .cellOrder": {
      fontFamily: "Inter",
      fontSize: 18,
      fontWeight: 600,
      color: "#353535",
    },
    "& tr .cellButton": {
      textAlign: "end",

      "& .reportButton": {
        backgroundColor: "#DBE4C7",
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        paddingInline: 16,
        color: "#353535",
        textTransform: "none",
      },
    },

    "& .cellSearch": {
      "& .MuiInputBase-root": {
        width: 350,
        height: 51,
      },
      "& .Mui-focused fieldset": {
        borderColor: "#757575",
      },
    },
  },

  orderTableBody: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    color: "#353535",
    paddingLeft: 16,

    "& .newValue": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
      color: "#353535",
    },

    "& .oldValue": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
      color: "#757575",
      textDecoration: "line-through",
      textDecorationColor: "#E11414",
    },

    "& svg": {
      width: 20,
      height: 20,
      color: "#3EA0DE",
      cursor: "pointer",
    },

    "& .checkBox": {
      width: 26,
    },

    "& .sku": {
      width: 32,
    },

    "& .description": {
      width: 233,
    },

    "& .quantity": {
      width: 82,
    },

    "& .quantityScratched": {
      width: 82,
      "& span": {
        display: "flex",
        flexDirection: "column",
      },
    },

    "& .total": {
      width: 82,
    },
    "& .totalScratched": {
      width: 82,
      "& span": {
        display: "flex",
        flexDirection: "column",
      },
    },

    "& .missingQty": {
      width: 86,
      "& .MuiOutlinedInput-root": {
        width: 70,
        height: 35,
      },

      "& fieldset": {
        borderColor: "#757575",
      },

      "& .Mui-focused fieldset": {
        borderColor: "#757575",
      },
    },

    "& .missingTotalValue": {
      width: 92,
    },

    "& .reason": {
      width: 172,
      "& .MuiOutlinedInput-root": {
        width: 156,
        height: 35,
      },
      "& .Mui-focused fieldset": {
        borderColor: "#757575",
      },
    },

    "& .attachment": {
      textAlign: "center",
      width: 36,

      "& .complaintImg": {
        width: "1.25rem",
        height: "1.25rem",
        objectFit: "contain",
        cursor: "pointer",
      },
    },

    "& tr td": {
      paddingInline: 8,
    },
  },

  orderTableFooter: {
    display: "flex",
    flexDirection: "column",

    "& .information": {
      display: "flex",
      flexGrow: 1,
      fontFamily: "Roboto",
      justifyContent: "space-between",
      fontSize: 14,
      fontWeight: 400,
      paddingBlock: 24,

      "& .observation": {
        display: "flex",
        "& .inputObservation": {
          width: 512,
          height: 99,

          "& textarea": {
            fontFamily: "Roboto",
            fontSize: 16,
            fontWeight: 400,
          },
        },
      },

      "& .subtotal": {
        display: "flex",
        gap: 8,

        "& .subtotalText": {
          color: "#757575",
          width: 105,
        },

        "& .subtotalValue": {
          color: "#353535",
          width: 80,
          textAlign: "end",
        },
      },

      "& .missing": {
        display: "flex",
        gap: 8,

        "& .missingText": {
          color: "#757575",
          width: 105,
        },

        "& .missingValue": {
          color: "#E11414",
          width: 80,
          textAlign: "end",
        },
      },

      "& .shipping": {
        display: "flex",
        gap: 8,

        "& .shippingText": {
          color: "#757575",
          width: 105,
        },

        "& .shippingValue": {
          color: "#353535",
          width: 80,
          textAlign: "end",
        },
      },

      "& .total": {
        display: "flex",
        alignItems: "end",
        gap: 8,

        "& .totalText": {
          color: "#757575",
          width: 105,
        },

        "& .totalValue": {
          fontSize: 16,
          fontWeight: 600,
          color: "#353535",
          width: 80,
          textAlign: "end",
        },
      },

      "@media (max-width:1200px)": {
        flexDirection: "column",
        "& .observation": {
          marginTop: "1rem",
        },
      },
    },

    "& .actions": {
      display: "flex",
      flexGrow: 1,
      paddingBlock: 24,
      gap: 24,
      justifyContent: "flex-end",

      "& button": {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 500,
        color: "#fff",
        paddingInline: 8,
        backgroundColor: "#89BD23",
        textTransform: "none",

        "&:disabled": {
          cursor: "not-allowed",
          pointerEvents: "all !important",
          backgroundColor: "#757575",

          "&:hover": {
            backgroundColor: "#757575",
          },
        },

        "&:hover": {
          backgroundColor: "#89BD23",
        },
      },

      "@media (max-width:1200px)": {
        flexDirection: "column",
      },
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  confirmModalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 493,
    height: 209,
    overflow: "auto",
    padding: 13,
    backgroundColor: "#FFF",

    "& .closeIcon": {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      color: "#757575",
      "& svg": {
        cursor: "pointer",
      },
    },

    "& .title": {
      marginTop: 40,
      fontFamily: "Inter",
      fontSize: 18,
      fontWeight: 500,
    },

    "& .actions": {
      display: "flex",
      gap: 48,
      marginTop: 48,
      "& .btnCancel": {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        color: "#353535",
        textTransform: "none",
        paddingInline: 16,
        backgroundColor: "#DBE4C7",
      },

      "& .btnSave": {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        color: "#FAFAFA",
        textTransform: "none",
        paddingInline: 16,
        backgroundColor: "#89BD23",
      },
    },
  },

  couponModalContent: {
    display: "block",
    width: 544,
    height: "auto",
    overflow: "auto",
    padding: 16,
    backgroundColor: "#FFF",

    "@media (max-width:1200px)": {
      width: "100%",
      height: "100%",
    },

    "& .title": {
      fontFamily: "Inter",
      fontSize: 18,
      fontWeight: 600,
      color: "#353535",
      marginBottom: 24,
    },

    "& .oneField": {
      marginTop: 16,
    },
    "& .twofields": {
      display: "flex",
      gap: 16,
      marginTop: 16,
    },

    "& .actions": {
      display: "flex",
      justifyContent: "flex-end",
      gap: 24,
      marginTop: 16,
      "& .btnCancel": {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        color: "#353535",
        textTransform: "none",
        paddingInline: 16,
        backgroundColor: "#DBE4C7",
      },

      "& .btnSave": {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        color: "#FAFAFA",
        textTransform: "none",
        paddingInline: 16,
        backgroundColor: "#89BD23",
      },
    },
  },

  otherNegotiationsModalContent: {
    width: 544,
    height: 449,
    overflow: "auto",
    padding: 16,
    backgroundColor: "#FFF",

    "& .title": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
      fontFamily: "Inter",
      fontSize: 18,
      fontWeight: 600,
      color: "#353535",
      marginBottom: 24,

      "& svg": {
        width: 20,
        height: 20,
        color: "#757575",
        cursor: "pointer",
      },
    },

    "& .oneField": {
      marginTop: 16,
    },
    "& .twofields": {
      display: "flex",
      gap: 16,
      marginTop: 16,
    },

    "& .actions": {
      display: "flex",
      justifyContent: "flex-end",
      gap: 24,
      marginTop: 16,
      "& .btnCancel": {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        color: "#353535",
        textTransform: "none",
        paddingInline: 16,
        backgroundColor: "#DBE4C7",
      },

      "& .btnSave": {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        color: "#FAFAFA",
        textTransform: "none",
        paddingInline: 16,
        backgroundColor: "#89BD23",
      },
    },
    "& .missingItems": {
      marginBlock: 32,
      "& .title": {
        alignItems: "flex-end",
        fontFamily: "Inter",
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },

  dateTimeContent: {
    display: "flex",
    flexDirection: "column",

    "& .msgError": {
      marginTop: 8,
      fontFamily: "Roboto",
      fontSize: 11,
      fontWeight: 400,
      color: "#E11414",
      width: 163,
    },
  },
  dateInput: {
    marginTop: 32,
    "& > *": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
    },

    "& input": {
      cursor: "pointer",
    },
    "& .Mui-disabled": {
      color: "#757575",
    },
    "& label.Mui-focused": {
      color: "#757575",
    },
    "& .MuiOutlinedInput-root": {
      color: "#757575",
      width: 163,
      "& fieldset": {
        borderColor: "#757575",
      },
      "&:hover fieldset": {
        borderColor: "#757575",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#757575",
      },
    },
  },

  defaultInput: {
    marginTop: 8,
    "& > *": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
    },

    "& .Mui-disabled": {
      color: "#757575",
    },
    "& label.Mui-focused": {
      color: "#757575",
    },
    "& .MuiOutlinedInput-root": {
      color: "#757575",
      width: 235,
      "& fieldset": {
        borderColor: "#757575",
      },
      "&:hover fieldset": {
        borderColor: "#757575",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#757575",
      },
    },
  },
  selectTime: {
    marginTop: 24,
    width: 163,
    "& > *": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,

      "& svg": {
        color: "#757575",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    "& .MuiPaper-root": {
      backgroundColor: "black",
    },
  },
}));

export const BreakTableContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  border: `0.5px solid ${theme.colors.gray}`,
  marginTop: "1.5rem",
  padding: "1.5rem",
}));

export const BreakTableContent = styled(Box)({
  maxHeight: "38.75rem",
  overflow: "auto",
});

export const StyledBreakTable = styled(Table)({
  minWidth: "59.6875rem",
  marginBottom: "1rem",
});

export const BreakTableHeader = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

export const BreakTableHeaderInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "1rem",
  borderBottom: `0.5px solid ${theme.colors.gray}`,
}));

export const BreakTableHeaderSearch = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "1rem",
  borderBottom: `0.5px solid ${theme.colors.gray}`,
}));

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  fontFamily: theme.font.Roboto,
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  color: theme.colors.primary,
  "& tr th": {
    padding: "1rem 0.5rem",
  },
  "& .reason, .qtyComplaint, , .value, .qtyTotal": {
    textAlign: "center",
  },
}));

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  fontFamily: theme.font.Roboto,
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  paddingLeft: 16,
  "& tr td": {
    padding: ".5rem",
    minHeight: "3.8125rem",
    verticalAlign: "middle",
  },
  "& .qtyTotal": {
    textAlign: "center",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& .checkBox": {
    width: "1.75rem",
  },
  "& .sku": {
    width: "3.0625rem",
    justifyContent: "center",
  },
  "& .itemName": {
    width: "16rem",
  },
  "& .qty": {
    width: "3.75rem",
    textAlign: "center",
  },
  "& .value": {
    width: "5.625rem",
    textAlign: "center",
  },
  "& .qtyComplaint": {
    width: "8.5rem",
    textAlign: "center",
    "& .MuiOutlinedInput-root": {
      height: "2.1875rem",
    },

    "& fieldset": {
      borderColor: theme.colors.lightGray,
    },

    "& .Mui-focused fieldset": {
      borderColor: theme.colors.lightGray,
    },
  },
  "& .qtyTotal": {
    width: "5.3125rem",
  },
  "& .reason": {
    width: "11.5625rem",
    textAlign: "center",
    "& .MuiOutlinedInput-root": {
      height: "2.1875rem",
    },
    "& .Mui-focused fieldset": {
      borderColor: theme.colors.lightGray,
    },
  },
  "& .returned": {
    textAlign: "center"
  },
  "& .obs": {
    width: "2.5625rem",
    "& svg": {
      width: "1.25rem",
      height: "1.25rem",
      color: theme.colors.link,
      cursor: "pointer",
    },
  },
  "& .photo": {
    width: "2.375rem",
    "& svg": {
      width: "1.25rem",
      height: "1.25rem",
      color: theme.colors.link,
      cursor: "pointer",
    },
    "& .complaintImg": {
      width: "1.25rem",
      height: "1.25rem",
      objectFit: "contain",
      cursor: "pointer",
    },
  },
}));

export const BreakTableQtyContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",

  "& .originalQty": {
    color: theme.colors.lightGray,
    textDecoration: "line-through",
    textDecorationColor: theme.colors.alertRed,
  },
}));

export const BreakTableProductContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",

  "& .portionName": {
    color: theme.colors.lightGray,
    fontSize: "0.875rem",
    lineHeight: "1rem",
    textTransform: "capitalize",
  },
}));

export const StyledBreakTableFooterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  "& .information": {
    display: "flex",
    flexGrow: 1,
    fontFamily: theme.font.Roboto,
    justifyContent: "space-between",
    fontSize: "0.875rem",
    fontWeight: 400,
    paddingBlock: "1.5rem",

    "& .selectReportedBy": {
      "& .MuiOutlinedInput-root": {
        height: "2.5rem",
      },
      "& .Mui-focused fieldset": {
        borderColor: theme.colors.lightGray,
      },
    },

    "& .observation": {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      "& .inputObservation": {
        width: "32rem",
        height: "6.1875rem",

        "& textarea": {
          fontFamily: theme.font.Roboto,
          fontSize: "1rem",
          fontWeight: 400,
        },
      },
    },

    "& .subtotal": {
      display: "flex",
      gap: "0.5rem",

      "& .subtotalText": {
        color: theme.colors.lightGray,
        width: "6.5625rem",
      },

      "& .subtotalValue": {
        color: theme.colors.primary,
        width: "5rem",
        textAlign: "end",
      },
    },

    "& .missing": {
      display: "flex",
      gap: "0.5rem",

      "& .missingText": {
        color: theme.colors.lightGray,
        width: "9.375rem",
      },

      "& .missingValue": {
        color: theme.colors.primary,
        width: "5rem",
      },
    },

    "& .rewardValue": {
      display: "flex",
      gap: "0.5rem",

      "& .rewardText": {
        color: theme.colors.lightGray,
        width: "9.375rem",
      },

      "& .rewardValue": {
        color: theme.colors.primary,
        width: "3rem",
      },
    },

    "& .total": {
      display: "flex",
      alignItems: "end",
      gap: "0.5rem",

      "& .totalText": {
        color: theme.colors.lightGray,
        width: "9.375rem",
      },

      "& .totalValue": {
        color: theme.colors.primary,
        width: "5rem",
      },
    },

    "@media (max-width:1200px)": {
      flexDirection: "column",
      "& .observation": {
        marginTop: "1rem",
      },
    },
  },

  "& .actions": {
    display: "flex",
    flexGrow: 1,
    paddingBlock: "1.5rem",
    gap: "1.5rem",
    justifyContent: "flex-end",

    "@media (max-width:1200px)": {
      flexDirection: "column",
    },
  },
}));
