import { Box, styled } from "@material-ui/core";

export const CardBody = styled(Box)(({ theme, isSelected }) => ({
  display: "flex",
  border: `0.125rem solid ${
    isSelected ? theme.colors.avocado : theme.colors.gray
  }`,
  alignItems: "center",
  backgroundColor: theme.colors.white,
  borderRadius: "0.3125rem",
  width: "14rem",
  padding: "0.8rem 1rem",
  cursor: "pointer",

  "@media (max-width:782px)": {
    width: "48%",
  },

  "@media (max-width:666px)": {
    width: "100%",
  },
}));

export const CardText = styled(Box)(({ theme }) => ({
  maxWidth: "15rem",
  fontFamily: theme.font.Roboto,
  fontSize: "0.875rem",
  fontWeight: 400,
  lineHeight: "1rem",
  color: theme.colors.primary,
}));
export const CardQty = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  fontFamily: theme.font.Inter,
  fontSize: "2rem",
  fontWeight: 500,
  lineHeight: "2.4375rem",
  color: theme.colors.primary,
  textAlign: "end",
  letterSpacing: "0.01em",
}));

export const CardDashBoard = ({ text, qty, isSelected = true, ...rest }) => {
  return (
    <CardBody isSelected={isSelected} {...rest}>
      <CardText>{text}</CardText>
      <CardQty>{qty}</CardQty>
    </CardBody>
  );
};
