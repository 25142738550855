import React, { useState, useEffect } from "react";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { TextField } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import setInitialDate from "../../../utils/date";
import { TitlePage } from "../../../components/titlePage.js";
import { usePopup } from "../../../hooks/usePopup";
import CustomDataGrid from "../../../components/dataGrid";
import { Button } from "../../../components/Button";
import EditRouteDialog from "../../../components/editRouteDialog";
import routeManagerService from "../../../services/routeManagerService";
import { useAuth } from "../../../hooks/useAuth";

export default function GerenciadorRotas() {
  const { addPopup } = usePopup();
  const { token } = useAuth();

  const [file, setFile] = useState(null);
  const [button, setButton] = useState(true);
  const [orderValue, setOrderValue] = useState("");
  const [dateButton, setDateButton] = useState(false);
  const [checkButton, setCheckButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [orderField, setOrderField] = useState(false);
  const [routeData, setRouteData] = useState([]);
  const [deliveryDate, setdeliveryDate] = useState(setInitialDate());
  const [inputKey, setInputKey] = useState(Date.now());
  const [open, setOpen] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [routeInfo, setRouteInfo] = useState({
    id: null,
    route: null,
  });

  const updateRoute = () => {
    routeManagerService
      .updateRoute(routeInfo, token)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error();
        }
        addPopup({
          type: "success",
          title: "Rota salva com sucesso!",
        });
      })
      .catch((error) => {
        addPopup({
          type: "error",
          title: "Operação não realizada",
        });
      })
      .finally(() => {
        setOpen(false);
        onInit(deliveryDate);
      });
  };

  const columns = [
    {
      field: "id",
      headerName: "Número do Pedido",
      width: 300,
    },
    {
      field: "scheduled_date",
      headerName: "Data de Entrega",
      width: 300,
    },
    {
      field: "route",
      headerName: "Rota",
      width: 300,
    },
    {
      field: "edit",
      headerName: " ",
      width: 60,
      align: "center",
      renderCell: (params) => (
        <IconButton
          onClick={(event) => {
            setRouteInfo({
              id: params.row.id,
              route: params.row.route,
            });
            setOpen(true);
          }}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const setOrder = (e) => {
    if (e.target.value.trim() !== "") {
      setOrderValue(e.target.value);
      setDateButton(true);
      setCheckButton(false);
    } else {
      setOrderValue("");
      setDateButton(false);
      setCheckButton(true);
    }
  };

  const getByOrder = async (e) => {
    setLoading(true);
    setCheckButton(true);
    setRouteData([]);
    await routeManagerService
      .orderRoute(null, orderValue, token)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error();
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          addPopup({
            type: "error",
            title: "Pedido não encontrado!",
          });
          return;
        }
        setRouteData(data);
      })
      .catch((error) => {
        addPopup({
          type: "error",
          title: "Operação não realizada!",
        });
      })
      .finally(() => {
        setDateButton(false);
        setOrderValue("");
        e.preventDefault();
        setLoading(false);
      });
  };

  useEffect(() => {
    onInit(deliveryDate);
  }, [deliveryDate]);

  const handleChange = (e) => {
    setdeliveryDate(e.target.value);
  };

  const onInit = (deliveryDate) => {
    setRouteData([]);
    setLoading(true);
    setOrderField(true);
    routeManagerService
      .orderRoute(deliveryDate, null, token)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error();
        }
        return response.json();
      })
      .then((data) => {
        setRouteData(data);
      })
      .catch((error) => {
        addPopup({
          type: "error",
          title: `Operação não realizada!`,
        });
      })
      .finally(() => {
        setLoading(false);
        setOrderField(false);
      });
  };

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
    setButton(false);
  };

  const onFileUpload = async (event) => {
    event.preventDefault();
    setButton(true);
    setLoadingUpload(true);

    const formData = new FormData();

    formData.append("myFile", file);

    await routeManagerService
      .csvUploader(formData, token)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error();
        }
        addPopup({
          type: "success",
          title: `Dados salvos com sucesso!`,
        });
        return response.json();
      })
      .catch(() => {
        addPopup({
          type: "error",
          title: `Operação não realizada!`,
        });
      })
      .finally(() => {
        setInputKey(Date.now());
        setFile(null);
        setLoadingUpload(false);
      });
  };

  const fileData = () => {
    if (file) {
      return (
        <div style={{ marginTop: 15 }}>
          <h5>Detalhes do arquivo selecionado:</h5>
          <p>Nome: {file.name}</p>
          <p style={{ lineHeight: 0.1 }}>
            Última modificação:{" "}
            {`${file.lastModifiedDate.toDateString()} ${file.lastModifiedDate.toTimeString()} `}
          </p>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div>
      <TitlePage>Gerenciador de Rotas</TitlePage>
      <h5 style={{ marginTop: 20 }}>Upload de Rotas</h5>
      <p>
        Para realizar o upload do arquivo, o mesmo deve estar em formato csv
        contendo apenas duas colunas "order_id" e "route"
      </p>
      <p style={{ lineHeight: 0.1 }}>
        O botão para envio só será habilitado após a escolha do arquivo
      </p>
      <form onSubmit={onFileUpload}>
        <input type="file" onChange={onFileChange} key={inputKey} />
        <Button
          variant="contained"
          startIcon={<CloudUploadIcon />}
          type="submit"
          style={{ marginLeft: 37 }}
          disabled={button}
          loading={loadingUpload}
        >
          Upload
        </Button>
      </form>
      {fileData()}

      <div style={{ marginTop: 30 }}>
        <h5>Pesquisa e Edição de Rotas</h5>
        <p>
          Selecione a data dos pedidos desejada ou ID do mesmo para a
          visualização e edição das rotas
        </p>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{ padding: 6 }}
          spacing={2}
        >
          <Grid item>
            <form noValidate>
              <TextField
                id="date"
                label="Data de Entrega"
                type="date"
                value={deliveryDate}
                disabled={dateButton}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </Grid>
          <Grid item>
            <TextField
              name="orderId"
              variant="outlined"
              required
              style={{ width: 105, marginTop: 8 }}
              size="small"
              id="orderId"
              label="nº Pedido"
              autoFocus
              disabled={orderField}
              value={orderValue}
              autoComplete="off"
              onChange={(event) => setOrder(event)}
            />
            <Button
              onClick={(event) => getByOrder(event)}
              style={{ marginTop: 10 }}
              disabled={checkButton}
              loading={loading}
            >
              Consultar
            </Button>
          </Grid>
        </Grid>
      </div>
      <CustomDataGrid rows={routeData} columns={columns} loading={loading} />
      <EditRouteDialog
        setOpen={setOpen}
        open={open}
        setRouteInfo={setRouteInfo}
        routeInfo={routeInfo}
        onConfirm={updateRoute}
      />
    </div>
  );
}
