import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import { DefaultInput } from "../../../components/Inputs/DefaultInput";
import { ModalBody } from "../../../components/Modals/ModalBody";
import ModalCard from "../../../components/Modals/ModalCard";
import {
  SelectInvoiceTypeContent,
  StyledFormControlLabel,
  StyledModalCardTextValue,
  StyledRadio,
  StyledRadioGroup,
  useStyles,
} from "./styles";
import {
  ItemsOrderTable,
  ItemsTable,
} from "../../../components/Table/ItemsTable";
import { Modal } from "../../../components/Modals/Modal";

const formattedItems = (items) => {
  return (items || []).map((item) => {
    return {
      itemId: item.id,
      itemSku: item.sku,
      itemName: item.product_name,
      complaintQty: item.quantity_complaint,
    };
  });
};

const formattedOrderItems = (items) => {
  return (items || []).map((item) => {
    return {
      itemId: item.id,
      itemSku: item.sku,
      itemName: item.description,
      itemQty: item.purchase_value,
    };
  });
};

export const DetailRefund = ({
  complaint,
  handleClose,
  loading,
  handleSubmit,
}) => {
  const classes = useStyles();
  const [invoiceNumber, setInvoiceNumber] = useState();
  const items = formattedItems(complaint?.items);
  return (
    <ModalBody handleClose={handleClose}>
      {complaint?.action === "Desconto no boleto" && (
        <Box className={classes.modalContent}>
          <Box className={classes.modalHeading}>
            {`Detalhes da Quebra - Pedido #${complaint?.order_id}`}
          </Box>
          <ModalCard.Card>
            <ModalCard.Title>Dados do Cliente</ModalCard.Title>
            <ModalCard.Text>
              Nome:
              <ModalCard.TextValue>
                {complaint?.client?.trade_name || complaint?.client?.name}
              </ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              CPF/CNPJ:
              <ModalCard.TextValue>{complaint?.CpfCnpj}</ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>
          <ItemsTable items={items} />
          <ModalCard.Card>
            <ModalCard.Title>Dados de Tratativa</ModalCard.Title>
            <ModalCard.Text>
              Tratativa:
              <ModalCard.TextValue>Desconto no boleto</ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              Valor de Devolução:
              <ModalCard.TextValue>
                {complaint?.complaintValue}
              </ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>
          <Box>
            <DefaultInput
              variant="outlined"
              label="Nota Fiscal"
              fullWidth
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
            />
          </Box>
          <ModalCard.Text>
            Criado por:
            <ModalCard.TextValue>
              {complaint?.responsible?.created_by}
            </ModalCard.TextValue>
          </ModalCard.Text>
          <ModalCard.ActionContent>
            <Button
              disabled={!invoiceNumber}
              loading={loading}
              onClick={() => handleSubmit(complaint?.id, invoiceNumber)}
            >
              Confirmar
            </Button>
          </ModalCard.ActionContent>
        </Box>
      )}
      {complaint?.action === "Reembolso" && (
        <Box className={classes.modalContent}>
          <Box className={classes.modalHeading}>
            {`Detalhes da Quebra - Pedido #${complaint?.order_id}`}
          </Box>
          <ModalCard.Card>
            <ModalCard.Title>Dados do Cliente</ModalCard.Title>
            <ModalCard.Text>
              Nome:
              <ModalCard.TextValue>
                {complaint?.client?.trade_name || complaint?.client?.name}
              </ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              CPF/CNPJ:
              <ModalCard.TextValue>{complaint?.CpfCnpj}</ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>
          <ItemsTable items={items} />
          <ModalCard.Card>
            <ModalCard.Title>Dados de Tratativa</ModalCard.Title>
            <ModalCard.Text>
              Tratativa:
              <ModalCard.TextValue>Reembolso</ModalCard.TextValue>
            </ModalCard.Text>
            {complaint?.solution?.action_json?.refund?.refundOption ===
              "pix" && (
              <>
                <ModalCard.Text>
                  Tipo Chave Pix:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.typePix}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Chave Pix:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.keyPix}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Favorecido:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.favored ||
                      "Não informado"}
                  </ModalCard.TextValue>
                </ModalCard.Text>
              </>
            )}
            {complaint?.solution?.action_json?.refund?.refundOption ===
              "transferBank" && (
              <>
                <ModalCard.Text>
                  Tipo de Conta:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.typeAccount}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Banco:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.bankName}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Agência:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.bankBranch}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Conta:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.bankAccount}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  CPF/CNPJ:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.bankDocument}
                  </ModalCard.TextValue>
                </ModalCard.Text>
              </>
            )}

            <ModalCard.Text>
              Valor de Devolução:
              <ModalCard.TextValue>
                {complaint?.complaintValue}
              </ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>
          <Box>
            <DefaultInput
              variant="outlined"
              label="Nota Fiscal"
              fullWidth
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
            />
          </Box>
          <ModalCard.Text>
            Criado por:
            <ModalCard.TextValue>
              {complaint?.responsible?.created_by}
            </ModalCard.TextValue>
          </ModalCard.Text>
          <ModalCard.ActionContent>
            <Button
              disabled={!invoiceNumber}
              loading={loading}
              onClick={() => handleSubmit(complaint?.id, invoiceNumber)}
            >
              Confirmar
            </Button>
          </ModalCard.ActionContent>
        </Box>
      )}
    </ModalBody>
  );
};

export const DetailTicket = ({
  ticket,
  handleClose,
  loading,
  handleSubmit,
  openTicketModal,
  handleCloseTicketDetail,
  selectedCard,
}) => {
  const classes = useStyles();
  const items = formattedOrderItems(ticket?.items);
  const complaintItems = formattedItems(ticket?.complaintItems);
  const [invoiceType, setInvoiceType] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState("");

  const handleSelectInvoiceType = (e) => {
    const value = e.target.value;
    setInvoiceType(value);
  };

  useEffect(() => {
    setInvoiceType(null);
    setInvoiceNumber(null);
  }, [openTicketModal]);

  const cantSubmit =
    !invoiceType || (invoiceType === "devolution" && !invoiceNumber?.trim());

  const modalTitle = {
    canceled: `Detalhes do cancelamento - Pedido #${ticket?.orderId}`,
    rescheduled: `Detalhes do reagendamento - Pedido #${ticket?.orderId}`,
    "pending-tickets": `Detalhes da quebra - Pedido #${ticket?.orderId}`,
  }[selectedCard];

  const ticketType = {
    canceled: "cancel",
    rescheduled: "rescheduled",
    "pending-tickets": "complaint",
  }[selectedCard];

  return (
    <Modal open={openTicketModal} onClose={handleCloseTicketDetail}>
      <ModalBody handleClose={handleClose}>
        <Box className={classes.modalContent}>
          <Box className={classes.modalHeading}>{modalTitle}</Box>
          <ModalCard.Card>
            <ModalCard.Title>Dados do Cliente</ModalCard.Title>
            <ModalCard.Text>
              Nome:
              <ModalCard.TextValue>{ticket?.clientName}</ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              CPF/CNPJ:
              <ModalCard.TextValue>{ticket?.clientCpfCnpj}</ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>

          {ticketType === "complaint" && <ItemsTable items={complaintItems} />}
          <ModalCard.Card>
            <ModalCard.Title>Dados do Pagamento</ModalCard.Title>
            {ticketType !== "rescheduled" && (
              <>
                <ModalCard.Text>
                  Tipo de pagamento:
                  <StyledModalCardTextValue>
                    {ticket?.paymentType?.toLowerCase()}
                  </StyledModalCardTextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Pagamento:
                  <StyledModalCardTextValue>
                   {ticket?.paymentStatus}
                  </StyledModalCardTextValue>
                </ModalCard.Text>
              </>
            )}
            <ModalCard.Text>
              Valor do pedido:
              <ModalCard.TextValue>{ticket?.orderValue}</ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              Valor do frete:
              <ModalCard.TextValue>{ticket?.deliveryValue}</ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>

          <ModalCard.Card>
            <ModalCard.Title>Informações de Devolução</ModalCard.Title>
            {ticket?.refundType === "pix" && (
              <>
                <ModalCard.Text>
                  Tipo Chave Pix:
                  <ModalCard.TextValue>{ticket?.typePix}</ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Chave Pix:
                  <ModalCard.TextValue>{ticket?.keyPix}</ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Favorecido:
                  <ModalCard.TextValue>
                    {ticket?.favoredPix || "Não informado"}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Valor de devolução:
                  <ModalCard.TextValue>
                    {ticket?.refundValue}
                  </ModalCard.TextValue>
                </ModalCard.Text>
              </>
            )}
            {ticket?.refundType === "transferBank" && (
              <>
                <ModalCard.Text>
                  Tipo de Conta:
                  <ModalCard.TextValue>
                    {ticket?.typeAccount}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Banco:
                  <ModalCard.TextValue>{ticket?.bankName}</ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Agência:
                  <ModalCard.TextValue>
                    {ticket?.bankBranch}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Conta:
                  <ModalCard.TextValue>
                    {ticket?.bankAccount}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  CPF/CNPJ:
                  <ModalCard.TextValue>
                    {ticket?.bankDocument}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Valor de devolução:
                  <ModalCard.TextValue>
                    {ticket?.refundValue}
                  </ModalCard.TextValue>
                </ModalCard.Text>
              </>
            )}
            {ticket?.refundType === "discountOnTicket" && (
              <>
                <ModalCard.Text>
                  Desconto no boleto
                  <ModalCard.TextValue>
                    {ticket?.refundValue}
                  </ModalCard.TextValue>
                </ModalCard.Text>
              </>
            )}
            {ticket?.refundType === "wallet" && (
              <>
                <ModalCard.Text>
                  Crédito na carteira
                  <ModalCard.TextValue>
                    {ticket?.refundValue}
                  </ModalCard.TextValue>
                </ModalCard.Text>
              </>
            )}
            {ticket?.refundType === "reschedule" && (
              <>
                <ModalCard.Text>
                  Cancelamento de
                  <ModalCard.TextValue>
                    {ticket?.refundValue}
                  </ModalCard.TextValue>
                </ModalCard.Text>
              </>
            )}
          </ModalCard.Card>
          {ticketType === "cancel" && (
            <ModalCard.Card>
              <ModalCard.Title>Motivo da cancelamento</ModalCard.Title>
              <ModalCard.Text>
                Motivo:
                <ModalCard.TextValue>
                  {ticket?.reason?.name}
                </ModalCard.TextValue>
              </ModalCard.Text>
              <ModalCard.Text>
                Comentário:
                <ModalCard.TextValue>
                  {ticket?.canceledObservation}
                </ModalCard.TextValue>
              </ModalCard.Text>
            </ModalCard.Card>
          )}

          {ticketType === "rescheduled" && (
            <ModalCard.Card>
              <ModalCard.Title>Motivo do Reagendamento</ModalCard.Title>
              <ModalCard.Text>
                Motivo:
                <ModalCard.TextValue>
                  {ticket?.reasonName}
                </ModalCard.TextValue>
              </ModalCard.Text>
              <ModalCard.Text>
                Comentário:
                <ModalCard.TextValue>
                  {ticket?.comments}
                </ModalCard.TextValue>
              </ModalCard.Text>
            </ModalCard.Card>
          )}

          <SelectInvoiceTypeContent>
            <StyledRadioGroup aria-label="invoice type">
              <StyledFormControlLabel
                value="canceled"
                control={<StyledRadio />}
                label="Nota Fiscal Cancelada"
                name="canceledInvoice"
                onChange={handleSelectInvoiceType}
              />

              <StyledFormControlLabel
                value="devolution"
                control={<StyledRadio color="#757575" />}
                label="Nota Fiscal de Devolução"
                name="invoiceReturn"
                onChange={handleSelectInvoiceType}
              />
            </StyledRadioGroup>
            {invoiceType === "devolution" && (
              <DefaultInput
                variant="outlined"
                label="Nota Fiscal"
                fullWidth
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
              />
            )}
          </SelectInvoiceTypeContent>

          <ModalCard.Text>
            Criado por:
            <ModalCard.TextValue>{ticket?.createdBy?.name}</ModalCard.TextValue>
          </ModalCard.Text>
          <ModalCard.ActionContent>
            <Button
              disabled={cantSubmit}
              loading={loading}
              onClick={() => handleSubmit(invoiceType, invoiceNumber)}
            >
              Confirmar
            </Button>
          </ModalCard.ActionContent>
        </Box>
      </ModalBody>
    </Modal>
  );
};
