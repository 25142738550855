export const paymentStatusList = {
    'PENDING':'pendente',
    'RECEIVED':'recebido',
    'CONFIRMED':'confirmado',
    'OVERDUE':'vencido',
    'REFUNDED':'reembolsado',
    'RECEIVED_IN_CASH':'recebido em dinheiro',
    'REFUND_REQUESTED':'estorno solicitado',
    'CHARGEBACK_REQUESTED': 'estorno recebido',
    'CHARGEBACK_DISPUTE':'disputa de estorno',
    'AWAITING_CHARGEBACK_REVERSAL':'aguardando recebimento do estorno',
    'DUNNING_REQUESTED':'em processo de negativação',
    'DUNNING_RECEIVED':'reclamação recebida',
    'AWAITING_RISK_ANALYSIS':'pagamento em análise'
}