import { FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { memo } from "react";
import { StyledSelectOrderBy } from "./style";

export const SelectOrder = memo(
  ({ selectedOrder, handleChangeSelectedOrder }) => {
    return (
      <FormControl variant="outlined">
        <InputLabel id="select-order">Ordem</InputLabel>
        <StyledSelectOrderBy
          labelId="select-order"
          value={selectedOrder}
          label="Ordem"
          onChange={(e) => handleChangeSelectedOrder(e.target.value)}
        >
          <MenuItem value="asc">Crescente</MenuItem>

          <MenuItem value="desc">Decrescente</MenuItem>
        </StyledSelectOrderBy>
      </FormControl>
    );
  }
);
