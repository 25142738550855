import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "118.75rem",
    margin: "0 auto",

    "@media (max-width:1200px)": {
      padding: "1rem 2%",
    },
  },
}));

export const ContainerPage = ({ children }) => {
  const classes = useStyle();
  return (
    <Box id="back-to-top-anchor" className={classes.container}>
      {children}
    </Box>
  );
};
