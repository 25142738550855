import {
    Box,
    TextField,
  } from "@material-ui/core";
  import { Button } from "../Button";
  import { ModalBody } from "./ModalBody";
  import { 
        WalletActionContent,
        WalletBodyContent,
        ModalTitle,
        DivOneField } from "./styles";
  import { H1 } from "../Heading/Heading";
export const WalletModalBody = ({
    data,
    complaintValue,
    handleClose,
    handleConfirm,
  }) => {
    const clientName = data?.client?.name ? data?.client?.name : data?.clientName
    return (
      <ModalBody handleClose={handleClose}>
        <ModalTitle>
          <H1>Crédito na Carteira</H1>
        </ModalTitle>
        <WalletBodyContent>
          <DivOneField>
            <TextField
              label="Nome do cliente"
              variant="outlined"
              fullWidth
              value={clientName}
              InputProps={{
                readOnly: true,
              }}
              focused={false}
            />
          </DivOneField>
          <DivOneField>
            <Box>
              <TextField
                label="Valor do Crédito"
                variant="outlined"
                value={complaintValue}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                focused={false}
              />
            </Box>
          </DivOneField>
          <WalletActionContent>
            <Button btn="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button onClick={() => handleConfirm("negotiation")}>
              <span>Confirmar</span>
            </Button>
          </WalletActionContent>
        </WalletBodyContent>
      </ModalBody>
    );
  };