import { styled } from "@material-ui/core";
import ReactPaginate from "react-paginate";

export const Paginate = styled(ReactPaginate)(({ theme }) => ({
  display: "flex",
  gap: "0.5rem",
  listStyleType: "none",

  "& li": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "2rem",
    minHeight: "2rem",
    fontFamily: theme.font.Roboto,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1rem",
  },

  "& .previous, .next": {
    color: theme.colors.gray,
    "& a": {
      color: theme.colors.gray,
      textDecoration: "none",
    },
  },

  "& .selected": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "2rem",
    minHeight: "2rem",
    borderRadius: "50%",
    backgroundColor: theme.colors.mediumAvocado,
    color: theme.colors.white,
  },
}));
