import {
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { Button } from "../../../../components/Button";
import { H1 } from "../../../../components/Heading/Heading";
import { DefaultInput } from "../../../../components/Inputs/DefaultInput";
import {
  CancelRefusedActionContent,
  CancelRefusedDivContent,
  CancelRefusedObservationContent,
  CancelRefusedSelectContent,
  CancelRefusedSubTitle
} from "../style";
import { useEffect } from "react";

export const InitialScreen = ({
  selectedCancelRefused,
  handleChangeCancelRefused,
  cancelReasonList,
  canceledObservation,
  handleChangeCancelObservation,
  close,
  nextStep,
}) => {
  return (
    <>
      <H1>Cancelar Pedido</H1>
      <CancelRefusedSubTitle>Qual é o motivo do cancelamento?</CancelRefusedSubTitle>
      <CancelRefusedSelectContent>
        <Select
          value={selectedCancelRefused}
          variant="outlined"
          fullWidth
          onChange={handleChangeCancelRefused}
        >
          <MenuItem value={0} selected disabled>
            Motivo
          </MenuItem>
          {cancelReasonList.map((item) => (
            (item.id !== 14 && item.id !== 15) ? (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            ) : null
          ))}
        </Select>
      </CancelRefusedSelectContent>
      <CancelRefusedObservationContent>
        <DefaultInput
          variant="outlined"
          multiline
          fullWidth
          placeholder="Comentários"
          rows={3}
          value={canceledObservation}
          onChange={handleChangeCancelObservation}
        />
      </CancelRefusedObservationContent>
      <CancelRefusedActionContent>
        <Button btn="secondary" onClick={close}>
          Cancelar
        </Button>
        <Button onClick={() => nextStep(1)} disabled={!selectedCancelRefused}>
          Continuar
        </Button>
      </CancelRefusedActionContent>
    </>
  );
};

export const BasicRefuse = ({
  clientName,
  clientType,
  paymentMethod,
  paymentStatus,
  orderId,
  formattedValue,
  previousStep,
  handleConfirm,
  handleChangeCancelInfo
}) => {
  const refundType = paymentMethod === "creditCard" ? "chargeback" : (paymentMethod === "pix" || (paymentMethod === "boleto" && paymentStatus === 'RECEIVED') ? "pix" : '')

  useEffect(() => {
    handleChangeCancelInfo({field: "type", value: refundType})
  }, [])

  const handleChange = (event) => {
    const { name, value } = event.target;
    handleChangeCancelInfo({field: name, value: value})
  }
  return (
    <>
      <H1>Dados do Cancelamento</H1>
      <CancelRefusedDivContent>
        <DefaultInput
          variant="outlined"
          fullWidth
          label="Nome do cliente"
          value={clientName}
          focused={false}
          InputProps={{
            readOnly: true,
          }}
        />
      </CancelRefusedDivContent>
      <CancelRefusedDivContent>
        <DefaultInput
          variant="outlined"
          fullWidth
          label="Nº do pedido"
          value={orderId}
          focused={false}
          InputProps={{
            readOnly: true,
          }}
        />
      </CancelRefusedDivContent>
      <CancelRefusedDivContent>
        <DefaultInput
          variant="outlined"
          fullWidth
          label="Valor do cancelamento"
          value={formattedValue}
          focused={false}
          InputProps={{
            readOnly: true,
          }}
        />
      </CancelRefusedDivContent>
      {clientType === 'PF' && (paymentMethod === 'pix' || paymentMethod === 'boleto') && paymentStatus === "RECEIVED" && (
        <>
        <CancelRefusedDivContent>
          <DefaultInput
            variant="outlined"
            fullWidth
            label="Tipo de estorno"
            value="PIX"
            focused={false}
            InputProps={{
              readOnly: true,
            }}
          />
        </CancelRefusedDivContent>
          <CancelRefusedDivContent>
            <TextField
              variant="outlined"
              fullWidth
              label="Chave Pix"
              onChange={handleChange}
              name="keyPix"
            />
          </CancelRefusedDivContent>
          <CancelRefusedDivContent>
            <TextField
              variant="outlined"
              fullWidth
              label="Favorecido"
              onChange={handleChange}
              name="favored"
            />
          </CancelRefusedDivContent>
        </>
      )}
      {clientType === 'PF' && paymentMethod === "creditCard" && (
        <CancelRefusedDivContent>
          <DefaultInput
            variant="outlined"
            fullWidth
            label="Tipo de estorno"
            value="Cartão de Crédito"
            focused={false}
            InputProps={{
              readOnly: true,
            }}
          />
        </CancelRefusedDivContent>
      )}
      <CancelRefusedActionContent>
        <Button btn="secondary" onClick={previousStep}>
          Voltar
        </Button>
        <Button onClick={() => handleConfirm("orderCanceled")}>Cancelar pedido</Button>
      </CancelRefusedActionContent>
    </>
  );
};