import formatCurrency from "../../../utils/formatCurrency";
import { formatDate } from "../../../utils/formatDate";
import { paymentStatusList } from "../../../utils/paymentStatusList";

const formattedCanceledOrdersTickets = (tickets) => {
  return tickets.results
    ?.filter(({ status }) => status === "aberto")
    .map((ticket) => ({
      ...ticket,
      clientName:
        ticket.referenceObj?.order?.client?.trade_name ||
        ticket.referenceObj?.order?.client?.name,
      clientType: ticket.referenceObj?.order?.client?.type,
      reason: ticket.actionJson?.canceledInfo?.reason,
      createdDate: formatDate(ticket.created),
      createdTime: new Date(ticket.created).toLocaleTimeString(),
      updatedDate: formatDate(ticket.updated),
      updatedTime: new Date(ticket.updated).toLocaleTimeString(),
    }));
};

const formattedRescheduleTickets = (tickets) => {
  return tickets.results
    ?.filter(({ status }) => status === "aberto")
    .map((ticket) => ({
      ...ticket,
      clientName:
        ticket.referenceObj?.order?.client?.trade_name ||
        ticket.referenceObj?.order?.client?.name,
      clientType: ticket.referenceObj?.order?.client?.type,
      reason: ticket.actionJson?.reschedule?.reason,
      reasonName: ticket.actionJson?.reschedule?.reasonName,
      createdDate: formatDate(ticket.created),
      createdTime: new Date(ticket.created).toLocaleTimeString(),
      updatedDate: formatDate(ticket.updated),
      updatedTime: new Date(ticket.updated).toLocaleTimeString(),
    }));
};

const formattedTicket = (ticketDetail) => {
  if (!ticketDetail) {
    return {};
  }

  return {
    ...ticketDetail,
    clientName:
      ticketDetail.referenceObj?.order?.client?.trade_name ||
      ticketDetail.referenceObj?.order?.client?.name,
    clientType: ticketDetail.referenceObj?.order?.client?.type,
    clientCpfCnpj: ticketDetail.referenceObj?.order?.client?.cpf_cnpj,
    paymentType: Array.isArray(ticketDetail.referenceObj?.order?.payment) ? ticketDetail.referenceObj?.order?.payment?.find(payment => payment.billingType)?.billingType ?? "Não informado" : ticketDetail.referenceObj?.order?.payment?.billingType,
    paymentStatus:
      paymentStatusList[Array.isArray(ticketDetail.referenceObj?.order?.payment) ? ticketDetail.referenceObj?.order?.payment?.find(payment => payment.status)?.status : ticketDetail.referenceObj?.order?.payment?.billingType] ||
      "Não  informado",
    orderValue: formatCurrency(
      ticketDetail.referenceObj?.order?.sub_total_value
    ),
    deliveryValue: formatCurrency(
      ticketDetail.referenceObj?.order?.delivery_value
    ),
    refundType:
      ticketDetail.actionJson?.refund?.type ||
      ticketDetail.actionJson?.solution?.action_json?.refund?.refundOption || (ticketDetail.actionJson?.reschedule && "reschedule") ||
      (ticketDetail.actionJson?.solution?.action_json?.wallet ? "wallet" : "discountOnTicket") || 
      "discountOnTicket",
    refundValue: formatCurrency(
      ticketDetail.actionJson?.refund?.value ||
        ticketDetail.actionJson?.solution?.action_json?.refund?.refundValue ||
        ticketDetail.actionJson?.solution?.action_json?.discountOnTicket
          ?.discountValue || ticketDetail.actionJson?.solution?.action_json?.wallet?.creditValue ||
        ticketDetail.actionJson?.canceledInfo?.refund?.value || (ticketDetail.referenceObj?.order?.sub_total_value + ticketDetail.referenceObj?.order?.delivery_value)
    ),
    reasonName: ticketDetail.actionJson?.reschedule?.reasonName,
    comments: ticketDetail.actionJson?.reschedule?.comments,
    typePix:
      ticketDetail.actionJson?.refund?.typePix ||
      ticketDetail.actionJson?.solution?.action_json?.refund?.typePix,
    keyPix:
      ticketDetail.actionJson?.refund?.keyPix ||
      ticketDetail.actionJson?.solution?.action_json?.refund?.keyPix,
    favoredPix:
      ticketDetail.actionJson?.refund?.favored ||
      ticketDetail.actionJson?.solution?.action_json?.refund?.favored,

    typeAccount:
      ticketDetail.actionJson?.refund?.typeAccount ||
      ticketDetail.actionJson?.solution?.action_json?.refund?.typeAccount,
    bankName:
      ticketDetail.actionJson?.refund?.bankName ||
      ticketDetail.actionJson?.solution?.action_json?.refund?.bankName,
    bankBranch:
      ticketDetail.actionJson?.refund?.bankBranch ||
      ticketDetail.actionJson?.solution?.action_json?.refund?.bankBranch,
    bankAccount:
      ticketDetail.actionJson?.refund?.bankAccount ||
      ticketDetail.actionJson?.solution?.action_json?.refund?.bankAccount,
    bankDocument:
      ticketDetail.actionJson?.refund?.bankDocument ||
      ticketDetail.actionJson?.solution?.action_json?.refund?.bankDocument,

    canceledObservation: ticketDetail.actionJson?.canceledInfo?.observation,
    reason: ticketDetail.actionJson?.canceledInfo?.reason,
    items: ticketDetail.referenceObj?.order?.item || [],
    complaintItems: ticketDetail.referenceObj?.complaint?.items || [],
    createdDate: formatDate(ticketDetail.created),
    createdTime: new Date(ticketDetail.created).toLocaleTimeString(),
    updatedDate: formatDate(ticketDetail.updated),
    updatedTime: new Date(ticketDetail.updated).toLocaleTimeString(),
  };
};

const formattedComplaintTickets = (tickets) => {
  return tickets.results
    ?.filter(({ status }) => status === "aberto")
    .map((ticket) => ({
      ...ticket,
      clientName:
        ticket.referenceObj?.order?.client?.trade_name ||
        ticket.referenceObj?.order?.client?.name,
      clientType: ticket.referenceObj?.order?.client?.type,
      reason: ticket.actionJson?.canceledInfo?.reason,
      createdDate: formatDate(ticket.created),
      createdTime: new Date(ticket.created).toLocaleTimeString(),
      updatedDate: formatDate(ticket.updated),
      updatedTime: new Date(ticket.updated).toLocaleTimeString(),
      complaintId: ticket.externalCode?.split("#")[0],
      complaintValue: formatCurrency(
        ticket.actionJson?.solution?.action_json?.refund?.refundValue ||
          ticket.actionJson?.solution?.action_json?.discountOnTicket
            ?.discountValue ||
          ticket.actionJson?.canceledInfo?.refund?.value || ticket.actionJson?.solution?.action_json?.wallet?.creditValue
      ),
      refundType:
        ticket.actionJson?.refund?.type |
        ticket.actionJson?.solution?.action_json?.refund?.refundOption |
        "discountOnTicket",
    }));
};

const formatInvoicingData = {
  formattedCanceledOrdersTickets,
  formattedRescheduleTickets,
  formattedTicket,
  formattedComplaintTickets,
};

export default formatInvoicingData;
