import { Backdrop, CircularProgress, styled } from "@material-ui/core";

const StyledBackDrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 4000,
  color: theme.colors.white,
}));

export const LoadingBackDrop = ({ open }) => {
  return (
    <StyledBackDrop open={open}>
      <CircularProgress color="inherit" />
    </StyledBackDrop>
  );
};
