import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import depotCityService from "../../../services/depotCityService";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import AlertDialog from "../../../utils/deleteAlert";
import { usePopup } from "../../../hooks/usePopup";
import { Button } from "../../../components/Button";
import { TitlePage } from "../../../components/titlePage";
import CustomDataGrid from "../../../components/dataGrid";
import EditInitialsDialog from "../../../components/editInitialsDialog";
import { useAuth } from "../../../hooks/useAuth";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  rootDelete: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  rootAlert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  h5: {
    margin: "1.8rem auto",
  },
  divFlex: {
    display: "flex",
    margin: "15px",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#8bc34a",
    color: "white",
  },
}));

export default function DepotCity() {
  const classes = useStyles();
  const { addPopup } = usePopup();
  const { token } = useAuth();

  const columnsCity = [
    {
      field: "id",
      headerName: "ID",
      width: 91,
      align: "center",
    },
    {
      field: "name",
      headerName: "Nome",
      width: 300,
    },
    {
      field: "state",
      headerName: "UF",
      headerAlign: "center",
      width: 160,
      align: "center",
    },
    {
      field: "depot",
      headerName: "CD ID",
      width: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "priority",
      headerName: "Prioridade",
      align: "center",
      headerAlign: "center",
      width: 200,
      editable: true,
      valueParser: (newValue, { value }) => {
        if (/\W|[0-9]/.test(newValue)) {
          addPopup({
            type: "error",
            title: "O valor da prioridade precisa ser uma letra",
          });
          return value;
        }

        return newValue?.toUpperCase();
      },
    },
    {
      field: "action",
      headerName: " ",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <IconButton
          id={params.row.id}
          aria-label="delete"
          onClick={(e) => {
            setId(e.currentTarget.id);
            setRegistry("city");
            setOpen(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const columnsDepot = [
    {
      field: "id",
      headerName: "ID",
      width: 91,
    },
    {
      field: "name",
      headerName: "CD",
      width: 419,
    },
    {
      field: "external_id",
      headerName: "Id Externo",
      width: 419,
    },
    {
      field: "initials",
      headerName: "Sigla",
      width: 150,
    },
    {
      field: "action",
      headerName: " ",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <IconButton
          id={params.row.id}
          aria-label="delete"
          onClick={(e) => {
            setId(e.currentTarget.id);
            setRegistry("depot");
            setOpen(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
    {
      field: "edit",
      headerName: " ",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <IconButton
          onClick={(e) => {
            setInitialsInfo({
              id: params.row.id,
              initials: params.row.initials,
            });
            setOpenInitials(true);
          }}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const updateInitials = () => {
    depotCityService
      .changeDepotInitials(initialsInfo.id, initialsInfo.initials, token)
      .then((resp) => {
        if (resp.status === 200) {
          addPopup({
            type: "success",
            title: "Alteração feita com sucesso!",
          });
        }
      })
      .catch((err) => {
        addPopup({
          type: "error",
          title: "Falha ao alterar a sigla!",
        });
      })
      .finally(() => {
        setOpenInitials(false);
        init();
      });
  };

  const init = () => {
    depotCityService
      .getDepot(token)
      .then((resp) => {
        if (resp.status !== 200) throw new Error("");
        return resp.json();
      })
      .then((data) => {
        setDepotList(data);
      })
      .catch((err) => {
        addPopup({
          type: "error",
          title: "Falha ao consultar estados",
        });
      });

    depotCityService
      .getCity(token)
      .then((resp) => {
        if (resp.status !== 200) throw new Error("");
        return resp.json();
      })
      .then((data) => {
        setCityList(data);
      })
      .catch((err) => {
        addPopup({
          type: "error",
          title: "Falha ao consultar cidades",
        });
      });
  };

  useEffect(() => {
    init();
  }, []);

  const [open, setOpen] = useState(false);

  const [openInitials, setOpenInitials] = useState(false);

  const [registry, setRegistry] = useState("");

  const [id, setId] = useState(null);

  const [stateList] = useState(["SP", "RJ"]);

  const [depotList, setDepotList] = useState([]);

  const [initialsInfo, setInitialsInfo] = useState({
    id: null,
    initials: null,
  });

  const [cityList, setCityList] = useState([]);

  const [depot, setDepot] = useState({
    name: "",
    external_id: "",
    initials: "",
  });

  const [city, setCity] = useState({
    name: "",
    state: "",
    depot: "",
  });

  const [isCityLoading, setCityLoading] = useState(false);
  const [isDepotLoading, setDepotLoading] = useState(false);

  const cleanDepotFields = (event) => {
    setDepot({ name: "", external_id: "", initials: "" });
  };

  const cleanCityFields = (e) => {
    setCity({ name: "", state: "", depot: "" });
  };

  const changeDepotState = (name, event) => {
    setDepot({ ...depot, [name]: event.target.value });
  };

  const changeCityState = (name, event) => {
    setCity({ ...city, [name]: event.target.value });
  };

  const saveDepot = (event) => {
    if (
      depot.name?.trim() &&
      depot.external_id?.trim() &&
      depot.initials?.trim()
    ) {
      setDepotLoading(true);
      depotCityService
        .createDepot(depot.name, depot.external_id, depot?.initials, token)
        .then((resp) => {
          if (resp.status === 200) {
            addPopup({
              type: "success",
              title: "Operação realizada com sucesso!",
            });
            init();
          } else {
            throw new Error("");
          }
        })
        .catch((err) => {
          addPopup({
            type: "error",
            title: "Operação não realizada",
          });
        })
        .finally(() => {
          cleanDepotFields();
          setDepotLoading(false);
        });
    } else {
      addPopup({
        type: "info",
        title: "Preencha todos os campos",
      });
    }
  };

  const saveCity = (event) => {
    if (city.name?.trim() && city.state && city.depot) {
      setCityLoading(true);
      depotCityService
        .createCity(city.name, city.state, city.depot, token)
        .then((resp) => {
          if (resp.status === 200) {
            addPopup({
              type: "success",
              title: "Operação realizada com sucesso!",
            });
            init();
          } else {
            throw new Error("");
          }
        })
        .catch((err) => {
          addPopup({
            type: "error",
            title: "Operação não realizada",
          });
        })
        .finally(() => {
          cleanCityFields();
          setCityLoading(false);
        });
    } else {
      addPopup({
        type: "info",
        title: "Preencha todos os campos",
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  const handlePriority = async (item) => {
    if (/\W|[0-9]/.test(item.value)) return;
    await depotCityService
      .changePriority(item.id, item.value.toUpperCase(), token)
      .then((resp) => {
        if (resp.status === 200) {
          addPopup({
            type: "success",
            title: "Prioridade alterada com sucesso.",
          });
        } else {
          addPopup({
            type: "error",
            title: "Operação não realizada",
          });
        }
      });
  };

  return (
    <div>
      <TitlePage>Cidades e CD's</TitlePage>

      <h5 className={classes.h5}>Gerenciamento de CD's</h5>
      <hr />

      <div className={classes.divFlex}>
        <TextField
          value={depot.name}
          style={{ marginRight: "10px" }}
          onChange={(e) => changeDepotState("name", e)}
          id="outlined-basic"
          label="Nome do CD"
          variant="outlined"
        />

        <TextField
          value={depot.external_id}
          onChange={(e) => changeDepotState("external_id", e)}
          id="outlined-basic"
          label="Id externo"
          variant="outlined"
        />
        <TextField
          value={depot?.initials}
          onChange={(e) => changeDepotState("initials", e)}
          id="outlined-basic"
          label="Sigla"
          variant="outlined"
          style={{ marginLeft: "10px" }}
        />
        <Button
          onClick={(event) => saveDepot(event)}
          style={{ marginTop: 1 }}
          type="submit"
          disabled={isDepotLoading}
          loading={isDepotLoading}
        >
          Salvar
        </Button>
      </div>

      <CustomDataGrid rows={depotList} columns={columnsDepot} />

      <h5 className={classes.h5}>Gerenciamento de Cidades</h5>

      <div className={classes.divFlex}>
        <TextField
          value={city.name}
          onChange={(e) => changeCityState("name", e)}
          id="outlined-basic"
          label="Nome da Cidade"
          variant="outlined"
        />

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="state">Estado</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="state"
            value={city.state}
            onChange={(e) => changeCityState("state", e)}
            label="Estado"
          >
            {stateList?.map((element) => (
              <MenuItem key={element} value={element}>
                {element}
              </MenuItem>
            ))}
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="cd">CD</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="cd"
            value={city.depot}
            onChange={(e) => changeCityState("depot", e)}
            label="CD"
          >
            {depotList?.map((element) => (
              <MenuItem key={element.id} value={element.id}>
                {element.name}
              </MenuItem>
            ))}
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          </Select>
        </FormControl>
        <Button
          onClick={(event) => saveCity(event)}
          style={{ marginTop: 1 }}
          type="submit"
          disabled={isCityLoading}
          loading={isCityLoading}
        >
          Salvar
        </Button>
      </div>

      <CustomDataGrid
        rows={cityList}
        columns={columnsCity}
        onCellValueChange={handlePriority}
      />

      <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
      <EditInitialsDialog
        open={openInitials}
        setOpen={setOpenInitials}
        initialsInfo={initialsInfo}
        setInitialsInfo={setInitialsInfo}
        onConfirm={updateInitials}
      />
      <AlertDialog
        open={open}
        setOpen={setOpen}
        id={id}
        registry={registry}
        init={init}
      ></AlertDialog>
    </div>
  );
}
