import formatCurrency from "../../../utils/formatCurrency";
import { formatDate } from "../../../utils/formatDate";
import { formatPhone } from "../../../utils/formatPhone";
import { getComplaintValue } from "./utils/getComplaintValue";

const formatWithoutSolutionComplaints = (complaints) => {
  return complaints.results
    ?.filter(({ status }) => status.id === 1)
    .map((complaint) => {
      return {
        ...complaint,
        complaintId: complaint.id,
        orderId: complaint.order?.id,
        clientName:
          complaint.order?.client?.trade_name || complaint.order?.client?.name,
        createdDate: formatDate(complaint?.created),
        createdTime: new Date(complaint.created).toLocaleTimeString(),
        complaintValue: formatCurrency(getComplaintValue(complaint.items)),
        updatedDate: formatDate(complaint.updated),
        updatedTime: new Date(complaint.updated).toLocaleTimeString(),
      };
    });
};

const formatDriverComplaints = (complaints) => {
  return complaints.results
    ?.filter(({ status }) => status.id === 1)
    .map((complaint) => {
      return {
        ...complaint,
        complaintId: complaint.id,
        orderId: complaint.order?.id,
        clientName:
          complaint.order?.client?.trade_name || complaint.order?.client?.name,
        createdDate: formatDate(complaint.created),
        createdTime: new Date(complaint.created).toLocaleTimeString(),
        complaintValue: formatCurrency(getComplaintValue(complaint.items)),
        updatedDate: formatDate(complaint.updated),
        updatedTime: new Date(complaint.updated).toLocaleTimeString(),
      };
    });
};

const formatExpectedComplaints = (complaints) => {
  return complaints.results
    ?.filter(({ status }) => status.id === 1)
    .map((complaint) => {
      return {
        ...complaint,
        complaintId: complaint.id,
        orderId: complaint.order?.id,
        clientName:
          complaint.order?.client.trade_name || complaint.order?.client.name,
        createdDate: formatDate(complaint.created),
        createdTime: new Date(complaint.created).toLocaleTimeString(),
        complaintValue: formatCurrency(getComplaintValue(complaint.items)),
        updatedDate: formatDate(complaint.updated),
        updatedTime: new Date(complaint.updated).toLocaleTimeString(),
      };
    });
};

const formatComplaintDetail = (complaint) => {
  if (!complaint) {
    return {};
  }
  return {
    ...complaint,
    orderId: complaint.order?.id,
    complaintId: complaint.id,
    clientName:
      complaint.order?.client?.trade_name || complaint.order?.client?.name,
    clientPhone:
      formatPhone(complaint.order?.client?.phone_number_1) ||
      formatPhone(complaint.order?.client?.phone_number_2),
    clientEmail: complaint.order?.client?.email,
    clientId: complaint.order?.client?.id,
    createdBy: complaint.created_by?.username,
    complaintValue: formatCurrency(getComplaintValue(complaint.items)),
    payment: complaint.order?.payment,
  };
};

const formatEnchantmentData = {
  formatWithoutSolutionComplaints,
  formatDriverComplaints,
  formatExpectedComplaints,
  formatComplaintDetail,
};

export default formatEnchantmentData;
