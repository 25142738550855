import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    fontFamily: '"Inter",sans-serif',
    fontWeight: 600,
    fontSize: "22px",
    color: "#353535",
    margin: "44px 0px 24px 0px",
  },
});

export const TitlePage = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography variant="h4" className={classes.root}>
      {children}
    </Typography>
  );
};
