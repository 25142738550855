import { Box, MenuItem, TableCell } from "@material-ui/core";
import { H1 } from "../../../components/Heading/Heading";
import ModalCard from "../../../components/Modals/ModalCard";
import {
  BreakInfoCard,
  ItensCard,
  ResolveSelectedActionContent,
  ResolveSelectedContent,
  ResolveSelectedSolutionContent,
  SeeMoreCloseButton,
  SeeMoreContainer,
  SeeMoreContent,
  SeeMoreFooter,
  SeeMoreInfoContent,
  SeeMoreSelectResponsible,
  SeeMoreTable,
  SeeMoreTableBody,
  SeeMoreTableHead,
  SeeMoreTableRow,
  SeeMoreTitleContent,
} from "./style";
import { Button } from "../../../components/Button";
import { DefaultInput } from "../../../components/Inputs/DefaultInput";
import { Modal } from "../../../components/Modals/Modal";
import { ModalBody } from "../../../components/Modals/ModalBody";
import { CloseIconButton } from "../../../components/StyledIconButton";
const originReportDict = {
  1: "Motorista",
  2: "Encantador",
  3: "Vendedor",
  4: "Quebra prevista",
  5: "Autoreporte"
};

export const ModalBodySeeMore = ({
  close,
  open,
  complaint,
  responsibleReasonList,
  selectedResponsible,
  handleChangeResponsible,
  responsibleObservation,
  handleChangeResponsibleObservation,
  handleSubmit,
  handleOpenImage,
}) =>  { 
  return (
  <Modal open={open} onClose={close}>
    <SeeMoreContainer>
      <SeeMoreCloseButton>
        <CloseIconButton onClick={close} />
      </SeeMoreCloseButton>
      <SeeMoreTitleContent>
        <H1>{`Detalhes da Quebra - Pedido #${complaint.orderId}`}</H1>
      </SeeMoreTitleContent>
      <SeeMoreContent>
        <SeeMoreInfoContent>
          <ModalCard.Card>
            <ModalCard.Title>Dados do cliente</ModalCard.Title>
            <ModalCard.Text>
              Nome:
              <ModalCard.TextValue>{complaint.clientName}</ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              Tipo de cliente:
              <ModalCard.TextValue>{complaint.clientType}</ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>
          <ItensCard>
            <ModalCard.Title>Item da quebra</ModalCard.Title>
            <SeeMoreTable>
              <SeeMoreTableHead>
                <SeeMoreTableRow>
                  <TableCell className="sku">SKU</TableCell>
                  <TableCell className="name">Produto</TableCell>
                  <TableCell className="qtyOriginal">Quant original</TableCell>
                  <TableCell className="qtyComplaint">Quant quebra</TableCell>
                  <TableCell className="qtyComplaint">Foto</TableCell>
                </SeeMoreTableRow>
              </SeeMoreTableHead>
              <SeeMoreTableBody>
                <SeeMoreTableRow>
                  <TableCell className="sku">{complaint.itemSku}</TableCell>
                  <TableCell className="name">{complaint.itemName}</TableCell>
                  <TableCell className="qtyOriginal">
                    {complaint.originalQty}
                  </TableCell>
                  <TableCell className="qtyComplaint">
                    {complaint.complaintQty}
                  </TableCell>
                  <TableCell className="image">
                    {complaint.complaintImage && (
                      <img
                        src={complaint.complaintImage}
                        alt="Product"
                        onClick={handleOpenImage}
                      />
                    )}
                  </TableCell>
                </SeeMoreTableRow>
              </SeeMoreTableBody>
            </SeeMoreTable>
          </ItensCard>
          <BreakInfoCard>
            <ModalCard.Title>Informações da quebra</ModalCard.Title>
            <ModalCard.Text>
              Quebra nº:
              <ModalCard.TextValue>{complaint.complaintId}</ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              Data de quebra:
              <ModalCard.TextValue>
                {complaint.complaintDate}
              </ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              Data de entrega:
              <ModalCard.TextValue>
                {complaint.rescheduleDate}
              </ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              Tratativa:
              <ModalCard.TextValue>{complaint.action}</ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              Valor da quebra:
              <ModalCard.TextValue>
                {complaint.complaintValue}
              </ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              Item devolvido:
              <ModalCard.TextValue>
                {complaint.originReport === 5 ? "Não confirmado" : (complaint?.returnedQty ? "Sim" : "Não")}
              </ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              Origem do contato:
              <ModalCard.TextValue>
                {originReportDict[complaint.originReport]}
              </ModalCard.TextValue>
            </ModalCard.Text>
          </BreakInfoCard>
        </SeeMoreInfoContent>
        <SeeMoreInfoContent>
          <ModalCard.Card style={{ minHeight: "11rem" }}>
            <ModalCard.Title>
              Motivo:
              <ModalCard.Text>{complaint.complaintReason?.name}</ModalCard.Text>
            </ModalCard.Title>
            <ModalCard.Title>
              Comentários:
              <ModalCard.Text>{complaint.itemObservation}</ModalCard.Text>
            </ModalCard.Title>
          </ModalCard.Card>
          <Box>
            <SeeMoreSelectResponsible
              value={selectedResponsible}
              variant="outlined"
              onChange={handleChangeResponsible}
            >
              <MenuItem value={0} selected disabled>
                Responsável
              </MenuItem>
              {responsibleReasonList.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </SeeMoreSelectResponsible>
          </Box>
          <DefaultInput
            label="Comentários"
            value={responsibleObservation}
            onChange={handleChangeResponsibleObservation}
            multiline
            variant="outlined"
            inputProps={{
              style: {
                height: "12.9rem",
              },
            }}
          />
        </SeeMoreInfoContent>
      </SeeMoreContent>
      <SeeMoreFooter>
        <Box className="createdBy">
          <ModalCard.Text>
            Criado por:
            <ModalCard.TextValue>{complaint.createdBy}</ModalCard.TextValue>
          </ModalCard.Text>
        </Box>
        {complaint.classifiedBy?.map((classification, index) => (
          <Box className="classifiedBy" key={classification.id}>
            <ModalCard.Text>
              {!index ? "Classificado por:" : "Reclassificado por:"}
              <ModalCard.TextValue>
                {classification.created_by.username}
              </ModalCard.TextValue>
            </ModalCard.Text>
          </Box>
        ))}
        <Box className="action">
          <Button onClick={() => handleSubmit("one")}>Salvar</Button>
        </Box>
      </SeeMoreFooter>
    </SeeMoreContainer>
  </Modal>
)};

export const ResolveSelectedModal = ({
  close,
  open,
  responsibleReasonList,
  selectedResponsible,
  responsibleObservation,
  handleChangeResponsible,
  handleChangeResponsibleObservation,
  handleSubmit,
}) => {
  return (
    <Modal open={open}>
      <ModalBody handleClose={close}>
        <ResolveSelectedContent>
          <H1>Classificar quebras selecionadas</H1>
          <ResolveSelectedSolutionContent>
            <SeeMoreSelectResponsible
              value={selectedResponsible}
              variant="outlined"
              onChange={handleChangeResponsible}
            >
              <MenuItem value={0} selected disabled>
                Responsável
              </MenuItem>
              {responsibleReasonList.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </SeeMoreSelectResponsible>

            <DefaultInput
              label="Comentários"
              value={responsibleObservation}
              onChange={handleChangeResponsibleObservation}
              multiline
              variant="outlined"
              inputProps={{
                style: {
                  height: "10.15rem",
                },
              }}
            />
          </ResolveSelectedSolutionContent>
          <ResolveSelectedActionContent>
            <Button onClick={() => handleSubmit("many")}>Salvar</Button>
          </ResolveSelectedActionContent>
        </ResolveSelectedContent>
      </ModalBody>
    </Modal>
  );
};
