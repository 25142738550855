import jwt_decode from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import authenticationService from "../services/authenticationService";
import permissionsService from "../services/permissionsService";
import { api } from "../utils/Api/apiClient";
import { usePopup } from "./usePopup";
const AuthContext = createContext({});

class AuthError extends Error {
  constructor(message) {
    super(message);
    this.name = "AuthError";
  }
}

const AuthProvider = ({ children }) => {
  const { addPopup } = usePopup();
  const history = useHistory();

  const [permissions, setPermissions] = useState(() => {
    const permissionList = JSON.parse(localStorage.getItem("permissions"));
    if (permissionList) return permissionList;

    return [];
  });

  const [token, setToken] = useState(() => {
    const token = localStorage.getItem("access");
    if (token) return token;

    return false;
  });

  const [refresh, setRefresh] = useState(() => {
    const refresh = localStorage.getItem("refresh");
    if (refresh) return refresh;

    return false;
  });

  const signIn = async ({ username, password }) => {
    try {
      const { data } = await authenticationService.login(username, password);
      const access = data.access;
      const refresh = data.refresh;
      localStorage.setItem("access", access);
      localStorage.setItem("refresh", refresh);
      setToken(access);
      setRefresh(refresh);
      await getPermissions(username);
    } catch (error) {
      const statusCode = error?.response?.status;
      if (statusCode === 401) {
        throw new AuthError(error?.response?.data?.message);
      } else {
        throw new Error();
      }
    }
  };

  const signOut = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("permissions");

    setPermissions([]);

    setToken(false);
    setRefresh(false);

    history.push("/login");
  };

  const getPermissions = async (username) => {
    try {
      const { data } = await permissionsService.getPerms(username);
      const payload = data.map(({ name }) => name);
      setPermissions(payload);
      localStorage.setItem("permissions", JSON.stringify(payload));
    } catch {
      toast.error("Erro ao procurar permissões");
      return;
    }
  };

  useEffect(() => {
    if (token) {
      const decodedToken = jwt_decode(token);

      const now = Date.now() / 1000;
      try {
        if (decodedToken.exp < now) {
          addPopup({
            type: "info",
            title: "Sua sessão expirou!",
          });
          signOut();
        }
      } catch (err) {
        signOut();
        addPopup({
          type: "info",
          title: "Login expirado",
        });
      }
    }
  }, [token]);

  function refreshValidator() {
    if (token) {
      const decodedToken = jwt_decode(token);

      try {
        authenticationService
          .refresh(refresh)
          .then(({ data }) => {
            localStorage.setItem("access", data.access);
            setToken(data.access);
          })
          .then(() => getPermissions(decodedToken?.user_id))
          .catch(() => {
            addPopup({
              type: "error",
              title: "Erro ao renovar token!",
            });
          });

        addPopup({
          type: "info",
          title: "Renovando seu Token!",
        });
      } catch (err) {
        signOut();
        addPopup({
          type: "info",
          title: "Login expirado",
        });
      }
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      refreshValidator();
    }, 30 * 60000);
    return () => {
      clearInterval(interval);
    };
  }, [token, refresh]);

  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return (
    <AuthContext.Provider value={{ token, permissions, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

const Token = () => {
  const token = localStorage.getItem("access");
  if (token) {
    return token;
  } else {
    const refresh = localStorage.getItem("refresh");
    if (refresh) {
      return refresh;
    } else {
      return false;
    }
  }
};

export { AuthProvider, useAuth, AuthError, Token };
