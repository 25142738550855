import React, { useEffect, useState } from "react";
import {
  BreakTableContainer,
  BreakTableHeader,
  BreakTableHeaderInfo,
  BreakTableHeaderSearch,
  useStyle,
} from "./style";
import { useHistory, useParams } from "react-router-dom";
import { Box, InputAdornment } from "@material-ui/core";

import Services from "../../../services/orderServices";
import { useMemo } from "react";

import { toast } from "react-toastify";

import { orderTypes, breakTypes } from "../../../types/orderTypes";
import OrderServices from "../../../services/orderServices";
import { ContainerPage } from "../../../components/ContainerPage";
import { BreakTable, BreakTableFooter } from "./tables";
import PageLoader from "../../../components/PageLoader";
import formatCurrency from "../../../utils/formatCurrency";
import { AlertModal } from "../../../components/AlertModal";
import { CustomLineProgress } from "../../../components/CustomLineProgress";
import { H3 } from "../../../components/Heading/Heading";
import { Button } from "../../../components/Button";
import { DefaultInput } from "../../../components/Inputs/DefaultInput";
import { StyledSearchIcon } from "../../../components/Icons/SearchIcon";
import { useDebaunce } from "../../../utils/debaunce";
import { ConfirmModal } from "../../../components/Modals/ConfirmModal";
import { BackIconButton } from "../../../components/StyledIconButton";
import { obligatoryReasons } from "./obligatoryReasons";
import TicketService from "../../../services/ticketService";
import ProductsServices from "../../../services/productsService";
import { useCallback } from "react";
import { format, isValid } from "date-fns";
import { ptBR } from "date-fns/locale";

const CreateBreak = () => {
  const { orderID } = useParams();
  const classes = useStyle();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [loadingOnBreak, setLoadingOnBreak] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsWeightInfo, setItemsWeightInfo] = useState([]);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [breakInfo, setBreakInfo] = useState(breakTypes);
  const [breaks, setBreaks] = useState([]);
  const [orderData, setOrderData] = useState(orderTypes);
  const [reasonList, setReasonList] = useState([]);
  const [formattedItens, setFormattedItens] = useState([]);
  const [searchText, setSearchText] = useState("");
  const reasonsToHide = [6, 7, 9];
  const originReportToHide = [4, 5];
  const [originReportList, setOriginReportList] = useState([]);
  const [selectedOriginReport, setSelectedOriginReport] = useState(null);

  const debounceGetItemValue = useDebaunce({
    fn: getBreakItemValue,
    delay: 800,
  });

  const debounceSearch = useDebaunce({
    fn: handleSearch,
    delay: 800,
  });

  const init = async () => {
    try {
      const { data } = await Services.GetOrderDetail(orderID);
      const { data: reasonData } = await OrderServices.getReasonList();
      const { data: originReportData } =
        await OrderServices.getOriginReportList();
      setOrderData((prev) => ({ ...prev, ...data }));
      setItems(data.item);

      setReasonList(
        reasonData?.filter(({ id }) => !reasonsToHide.includes(id))
      );
      setOriginReportList(
        originReportData?.filter(({ id }) => !originReportToHide.includes(id))
      );

      const productsWeight = await ProductsServices.getProductsWeight({
        order_date: data.order_date,
        products: data.item.map((item) => ({ sku: item.sku })),
      });

      setItemsWeightInfo(productsWeight);
    } catch {
      toast.error("Erro ao carregar os dados");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [orderID]);

  async function getBreakItemValue(breaks) {
    setLoadingOnBreak(true);
    const body = { orderId: orderID, products: breaks };

    try {
      const response = await OrderServices.getItemsComplaintValues(body);

      setBreakInfo((prev) => {
        const updatedBreakList = prev.breakList.map((item) => {
          const matchingBreak = response.data.find(
            (breakItem) => breakItem.sku === item.sku
          );
          const complaint_value = matchingBreak
            ? matchingBreak.complaint_value
            : 0;
          return {
            ...item,
            complaint_value,
          };
        });
        return { ...prev, breakList: updatedBreakList };
      });
    } catch {
      toast.error("Erro ao efetuar calculo");
    }
    setLoadingOnBreak(false);
  }

  const reducerBreakerQty = (productSku) => {
    const { total_quantity } = items.find((item) => item.sku === productSku);
    const { received_quantity } = items.find((item) => item.sku === productSku);

    return orderData?.complaints?.reduce(
      (acc, { items }) => {
        acc = items.reduce((accItem, { sku, quantity_complaint }) => {
          if (sku === productSku) {
            return accItem - quantity_complaint;
          }
          return accItem;
        }, acc);
        return acc;
      },
      orderData?.is_dinamic_price
        ? (received_quantity ?? total_quantity).toFixed(3)
        : total_quantity
    );
  };

  const getItemWeightInfo = useCallback(
    (sku) => {
      if (!itemsWeightInfo) return null;

      const itemWeightInfo = itemsWeightInfo.find((item) => item.sku === sku);
      if (!itemWeightInfo) return null;

      const parsedDate = new Date(itemWeightInfo.date);

      if (isValid(parsedDate)) {
        const formattedDate = format(parsedDate, "dd/MM/yyyy", {
          locale: ptBR,
        });
        const formattedTime = format(parsedDate, "HH:mm", { locale: ptBR });
        return `Peso unitário de ${itemWeightInfo.unitWeight} na data: ${formattedDate} às ${formattedTime}`;
      } else {
        return null;
      }
    },
    [itemsWeightInfo]
  );

  useMemo(() => {
    if (items.length) {
      const feeInPercent = (orderData?.fee_in_percent ?? 0) / 100;
      const formatted = items.map((item) => {
        const unitPrice = item?.unit_price - feeInPercent * item?.unit_price;
        const priceAdjustment = orderData?.is_dinamic_price
          ? item?.price_adjustment
          : 0;
        const availableQuantity = reducerBreakerQty(item.sku) || 0;
        const portionType = item?.portion_type === "peso" ? "Kg" : "Un";
        const receivedQuantity = orderData?.is_dinamic_price
          ? item?.received_quantity ?? item?.total_quantity
          : item?.total_quantity;

        return {
          id: item?.id,
          sku: item?.sku,
          productName: item?.description,
          orderId: item?.order,
          portionName: item?.portion_name,
          portionType: portionType,
          quantity: item?.quantity,
          availableQuantity: availableQuantity,
          totalQuantity: item?.total_quantity,
          receivedQuantity: Number.isInteger(receivedQuantity)
            ? receivedQuantity
            : receivedQuantity.toFixed(3),
          totalItensValue: formatCurrency(
            item?.total_quantity * unitPrice + priceAdjustment
          ),
          isChecked: false,
          itemWeightInfo: getItemWeightInfo(item.sku),
        };
      });
      setFormattedItens(formatted);
    }
  }, [items, itemsWeightInfo]);

  const handleCheck = (e) => {
    const id = e.target.id;
    const isChecked = e.target.checked;
    setFormattedItens((state) => {
      return state.map((item) => {
        if (item.sku === id) {
          item.isChecked = isChecked;
        }

        return item;
      });
    });
    if (isChecked) {
      const item = items.find(({ sku }) => sku === id);
      setBreakInfo((prev) => ({
        ...prev,
        breakList: [
          ...prev.breakList,
          {
            sku: id,
            item_image: "",
            order_item_id: item.id,
            product: item.product_id,
            product_name: item.description,
            quantity_original: item.total_quantity,
            quantity_complaint: 0,
            quantity_returned: 0,
            complaint_value: 0,
            reason: 0,
            observation: null,
          },
        ],
      }));
    } else {
      setBreakInfo((prev) => ({
        ...prev,
        breakList: breakInfo.breakList?.filter(({ sku }) => sku !== id),
      }));
    }
  };

  const encodeBase64 = (image) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(image[0]);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (breaks.length > 0) debounceGetItemValue(breaks);
  }, [breaks]);

  const handleBreakInfoChange = async (field, sku, value) => {
    const skuIsEqual = (sku, id) => sku === id;
    if (field === "quantity_complaint") {
      const { unit_price: quantity = 0 } = orderData.item.find(({ sku: id }) =>
        skuIsEqual(id, sku)
      );
      let reduceQty = reducerBreakerQty(sku);
      if (reduceQty === false) {
        reduceQty = quantity;
      }

      if (Number(value) > reduceQty) {
        return;
      }

      const index = breaks.findIndex((item) => item.sku === sku);
      if (index !== -1) {
        const newBreaks = [...breaks];
        if (value === "0") {
          newBreaks.splice(index, 1);
        } else {
          newBreaks[index] = { sku, complaintQuantity: value };
        }
        setBreaks(newBreaks);
      } else {
        setBreaks([...breaks, { sku, complaintQuantity: value }]);
      }

      const updatedBreakList = breakInfo.breakList.map((item) => {
        const formattedItem = formattedItens.find((formatted) =>
          skuIsEqual(formatted.sku, sku)
        );
        if (skuIsEqual(item.sku, sku)) {
          return {
            ...item,
            [field]: Number(value) || 0,
            quantity_returned: formattedItem?.wasReturned
              ? Number(value) || 0
              : 0,
          };
        }
        return item;
      });
      setBreakInfo((prev) => ({
        ...prev,
        breakList: updatedBreakList,
      }));
    } else if (field === "quantity_returned") {
      const breakInfoItem = breakInfo.breakList.find((item) =>
        skuIsEqual(item.sku, sku)
      );
      if (Number(breakInfoItem.reason) === 3)
        return toast.error("Não é possível devolver um item não entregue");
      const updatedBreakList = breakInfo.breakList.map((item) => {
        if (skuIsEqual(item.sku, sku)) {
          return {
            ...item,
            [field]: value ? Number(item.quantity_complaint) || 0 : 0,
          };
        }
        return item;
      });
      setBreakInfo((prev) => ({
        ...prev,
        breakList: updatedBreakList,
      }));
      setFormattedItens((state) => {
        return state.map((item) => {
          if (item.sku === sku) {
            item.wasReturned = value;
          }

          return item;
        });
      });
    } else if (field === "reason_id") {
      setBreakInfo((prev) => ({
        ...prev,
        breakList: breakInfo.breakList?.map((item) =>
          skuIsEqual(item.sku, sku) ? { ...item, reason: value } : item
        ),
      }));
      if (Number(value) === 3) {
        const updatedBreakList = breakInfo.breakList.map((item) => {
          if (skuIsEqual(item.sku, sku)) {
            return {
              ...item,
              quantity_returned: 0,
              reason: value,
            };
          }
          return item;
        });
        setBreakInfo((prev) => ({
          ...prev,
          breakList: updatedBreakList,
        }));
        setFormattedItens((state) => {
          return state.map((item) => {
            if (item.sku === sku) {
              item.wasReturned = false;
            }

            return item;
          });
        });
      }
    } else if (field === "item_image") {
      const image = await encodeBase64(value);
      setBreakInfo((prev) => ({
        ...prev,
        breakList: breakInfo.breakList?.map((item) =>
          skuIsEqual(item.sku, sku) ? { ...item, [field]: image || "" } : item
        ),
      }));
    } else if (field === "itemObservation") {
      const updatedBreakList = breakInfo.breakList.map((item) => {
        if (skuIsEqual(item.sku, sku)) {
          return {
            ...item,
            observation: value || null,
          };
        }

        return item;
      });

      setBreakInfo((prev) => ({
        ...prev,
        breakList: updatedBreakList,
      }));
    } else {
      setBreakInfo((prev) => ({ ...prev, [field]: value }));
    }
  };

  function handleSearch(e) {
    const text = e.target.value.toLowerCase().trim();
    setSearchText(text);
  }

  const handleCancel = () => {
    setBreakInfo(breakTypes);
    history.goBack();
  };

  const complaintValue = () => {
    const totalMissing = breakInfo.breakList?.reduce(
      (acc, { complaint_value }) => {
        return acc + complaint_value;
      },
      0
    );
    return totalMissing;
  };

  const handleClose = () => {
    setOpenConfirmModal(false);
  };

  const handleConfirm = () => {
    const { breakList } = breakInfo;
    const missingObligatoryObs = breakInfo.breakList.find(
      (item) =>
        obligatoryReasons.includes(Number(item.reason)) &&
        !item.observation?.trim()
    );

    if (!breakList.length) {
      return toast.error("A quebra não pode ser vazia");
    }

    if (breakList.some(({ quantity_complaint }) => !quantity_complaint)) {
      return toast.error("Insira a quantidade faltante");
    }

    if (breakList.some(({ reason }) => !reason)) {
      return toast.error("Selecione um motivo");
    }

    if (missingObligatoryObs) {
      return toast.error(
        `O comentário no item ${missingObligatoryObs.product_name} é obrigatório`
      );
    }

    if (!selectedOriginReport) {
      return toast.error("Informe a origem do contato");
    }

    return setOpenConfirmModal(true);
  };

  const showAlert = () => {
    setOpenAlert(true);
  };

  const closeAlert = () => {
    setOpenAlert(false);
    history.goBack();
  };

  const handleSubmit = async () => {
    setOpenConfirmModal(false);
    const { order: _, ...complaint } = orderData?.complaint || {};
    const data = {
      subjectId: 1,
      externalCode: "",
      orderId: orderID,
      clientId: orderData?.client_id,
      referenceObj: {
        order: {
          client: orderData?.client,
          payment: orderData?.payment,
          status: orderData?.status,
          sub_total_value: orderData?.sub_total_value,
          delivery_value: orderData?.delivery_value,
          store_name: orderData?.store_name,
          catalog_id: orderData?.catalog_id,
          id: orderID,
        },
        complaint: complaint,
      },
      actionJson: {
        order: orderID,
        origin: "abacate",
        origin_report: selectedOriginReport,
        observation: breakInfo?.observation,
        items: breakInfo.breakList,
      },
    };
    try {
      setLoadingOnBreak(true);
      await TicketService.createTicket(data);
      showAlert();
      handleClose();
    } catch {
      toast.error("Erro ao criar quebra");
    } finally {
      setLoadingOnBreak(false);
    }
  };

  const handleChangeOriginReport = (e) => {
    const value = e.target.value;
    setSelectedOriginReport(value);
  };

  if (loading) {
    return <PageLoader />;
  }
  return (
    <ContainerPage>
      <Box className={classes.backButton}>
        <BackIconButton onClick={() => history.goBack(-1)} />
        Voltar
      </Box>

      <Box className={classes.titleDetail}>
        <h1>{`Consulta de pedido > Pedido #${orderID} > Quebra`}</h1>
      </Box>
      {loadingOnBreak && <CustomLineProgress />}
      <BreakTableContainer>
        <BreakTableHeader>
          <BreakTableHeaderInfo>
            <H3>{`Pedido #${orderID}`}</H3>
            <Button btn="secondary" onClick={handleCancel}>
              Cancelar
            </Button>
          </BreakTableHeaderInfo>
          <BreakTableHeaderSearch>
            <DefaultInput
              variant="outlined"
              placeholder="SKU, nome do produto"
              onChange={(e) => debounceSearch(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <StyledSearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </BreakTableHeaderSearch>
        </BreakTableHeader>
        <BreakTable
          items={formattedItens}
          handleCheck={handleCheck}
          breakList={breakInfo.breakList}
          handleBreakInfoChange={handleBreakInfoChange}
          reasonList={reasonList}
          searchText={searchText}
        />

        <BreakTableFooter
          complaintValue={complaintValue()}
          breakInfo={breakInfo}
          handleBreakInfoChange={handleBreakInfoChange}
          handleSave={handleConfirm}
          loading={loadingOnBreak}
          handleChangeOriginReport={handleChangeOriginReport}
          selectedOriginReport={selectedOriginReport}
          originReportList={originReportList}
        />
      </BreakTableContainer>
      <ConfirmModal
        open={openConfirmModal}
        handleAction={handleSubmit}
        handleClose={handleClose}
        confirmText="Tem certeza que deseja realizar esta tratativa?"
      />

      <AlertModal
        handleClose={closeAlert}
        open={openAlert}
        text={`Quebra para o pedido #${orderID} foi salva com sucesso`}
      />
    </ContainerPage>
  );
};

export default CreateBreak;
