import { styled } from "@material-ui/core";

export const H1 = styled("h1")({
  fontFamily: "'Inter', Sans-serif",
  fontWeight: 600,
  fontSize: "1.375rem",
  lineHeight: "1.6875rem",
  letterSpacing: "0.01em",
  margin: 0,
  color: "#353535",

  "@media (max-width:800px)": {
    fontSize: "1rem",
  },
});

export const H3 = styled("h3")(({ theme }) => ({
  fontFamily: theme.font.Inter,
  fontWeight: 600,
  fontSize: "1.125rem",
  lineHeight: "1.6875rem",
  letterSpacing: "0.01em",
  margin: 0,
  color: theme.colors.primary,

  "@media (max-width:800px)": {
    fontSize: "1rem",
  },
}));
