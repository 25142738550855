import { styled, TextField } from "@material-ui/core";

export const DefaultInput = styled(TextField)(({ theme }) => ({
  fontFamily: theme.font.Roboto,
  fontSize: "0.875rem",
  fontWeight: 400,
  "& .Mui-disabled": {
    color: theme.colors.lightGray,
  },
  "& label.Mui-focused": {
    color: theme.colors.lightGray,
  },
  "& .MuiOutlinedInput-root": {
    color: theme.colors.lightGray,
    "& fieldset": {
      borderColor: theme.colors.lightGray,
    },
    "&:hover fieldset": {
      borderColor: theme.colors.lightGray,
    },
    "& .Mui-focused fieldset": {
      borderColor: theme.colors.lightGray,
    },
  },
}));
