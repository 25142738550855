import { api } from "../utils/Api/apiClient";

const getBillsAndInvoices = async ({ deliveryDate, order, page = 1 }) => {
  const url = `/api/abacate/v1/bills_and_invoices/`;
  return await api.get(url, {
    params: {
      scheduled_date: deliveryDate,
      order_id: order,
      page: page,
    },
  });
};

const billsAndInvoicesService = {
  getBillsAndInvoices,
};
export default billsAndInvoicesService;
