import React, { useState } from "react";
import { AppBar } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { Drawer } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Slide } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { forwardRef } from "react";
import { Link as LinkRouter, Switch, useHistory } from "react-router-dom";
import { styled } from "@material-ui/styles";
import Route from "./route";

import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import ReceiptIcon from "@material-ui/icons/Receipt";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import { Home } from "@material-ui/icons";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";

import GerenciadorRotas from "../pages/private/routeManager/routeManager.js";
import PdfGenerator from "../pages/private/pdfGenerator/pdfGenerator.js";
import OrderGroup from "../pages/private/orderGroup/orderGroup.js";
import BillsAndInvoices from "../pages/private/billsAndInvoices/billsAndInvoices.js";
import OrderConsult from "../pages/private/orderConsult/orderConsult";
import DepotCity from "../pages/private/depotCity/depotCity";
import PageDeafult from "../pages/private/home/default";
import CreateBreak from "../pages/private/orderBreak/createBreak";
import EditBreak from "../pages/private/orderBreak/editBreak";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import FlareIcon from "@material-ui/icons/Flare";

import OrderDetail from "../pages/private/orderDetail/orderDetail";

import { PermissionGate } from "../components/permissionGate";
import { usePopup } from "../hooks/usePopup";
import { useAuth } from "../hooks/useAuth";

import { alpha, makeStyles } from "@material-ui/core";
import { OrderInvoicing } from "../pages/private/orderInvoicing/OrderInvoicing";
import { OrderFinancial } from "../pages/private/orderFinancial/OrderFinancial";
import { OrderEnchantment } from "../pages/private/orderEnchantment/OrderEnchantment";

import { BreakAnalysis } from "../pages/private/breakAnalysis/BreakAnalysis";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { GiFarmer } from "react-icons/gi";
import { Farmers } from "../pages/private/farmers/Farmers";

const drawerWidth = 270;

const useStylesDesktop = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    boxShadow: "none",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)",
    backgroundColor: "#8BC34A",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.between("0", "1000")]: {
      display: "none",
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    color: "#fff",
    textDecoration: "none",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    background: alpha("#fff", 0.9),
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.between("0", "1000")]: {
      display: "none",
    },
    height: "100vh",
    boxSizing: "border-box",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  container: {
    position: "center",
    marginLeft: "0px",
    marginRight: "0px",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: "#fafafa",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  link: {
    display: "block",
    padding: "12px 0px",
    paddingLeft: "23px",
    width: "100%",
    height: "100%",
    "& .MuiTypography-root": {
      color: "#fff",
    },
  },
}));

const useStylesMobile = makeStyles((theme) => ({
  appBar: {
    position: "absolute",
    boxShadow: "none",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)",
    backgroundColor: "#8BC34A",
  },
  appBarDialog: {
    background: "#8BC34A",
  },
  listNav: {
    boxShadow: "none",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)",
    backgroundColor: alpha("#fff", 0.4),
  },
  titleNav: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#FFF",
    fontWeight: 600,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#fff",
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  root: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fafafa",
    [theme.breakpoints.up("1000")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    marginTop: "75px",
  },
}));

const Link = styled(LinkRouter)({
  display: "flex",
  textDecoration: "none",
  color: "#353535",
  alignItems: "center",
  width: "100%",
  height: "100%",
  "&:hover": {
    color: "#8BC34A",
    textDecoration: "none",
  },
});

const componentList = [
  {
    label: "Home",
    icon: <Home />,
    page: PageDeafult,
    permission: [],
    link: "/app/home",
  },
  {
    label: "Gerenciador de Rotas",
    icon: <LocalShippingIcon />,
    page: GerenciadorRotas,
    permission: ["logistic | order_route"],
    link: "/app/route_manager",
  },
  {
    label: "Gerador de PDFs",
    icon: <PictureAsPdfIcon />,
    page: PdfGenerator,
    permission: ["logistic | pdf_routes"],
    link: "/app/pdf_generator",
  },
  {
    label: "Notas e Boletos",
    icon: <ReceiptIcon />,
    page: BillsAndInvoices,
    permission: ["abacate | bills_and_invoices"],
    link: "/app/bills_invoices",
  },
  {
    label: "Cidades e CD's",
    icon: <LocationCityIcon />,
    page: DepotCity,
    permission: ["logistic | depot", "logistic | city"],
    link: "/app/depot_city",
  },
  {
    label: "Itens de Pedidos",
    icon: <FormatListBulletedIcon />,
    page: OrderGroup,
    permission: ["abacate | order"],
    link: "/app/itens_pedidos",
  },

  {
    label: "Consultar Pedidos",
    icon: <LocationSearchingIcon />,
    page: OrderConsult,
    permission: ["order | tracking"],
    link: "/app/orders/consult",
  },
  {
    label: "Farmers",
    icon: <GiFarmer />,
    page: Farmers,
    permission: ["abacate | farmers"],
    link: "/app/orders/farmers",
  },
  {
    label: "Financeiro",
    icon: <MonetizationOnIcon />,
    page: OrderFinancial,
    permission: ["abacate | bills_and_invoices"],
    link: "/app/orders/financial",
  },
  {
    label: "Encantamento",
    icon: <FlareIcon />,
    page: OrderEnchantment,
    permission: ["abacate | bills_and_invoices"],
    link: "/app/orders/enchantment",
  },
  {
    label: "Faturamento",
    icon: <LocalAtmIcon />,
    page: OrderInvoicing,
    permission: ["abacate | bills_and_invoices"],
    link: "/app/orders/invoicing",
  },
  {
    label: "Análise de Quebras",
    icon: <AssessmentIcon />,
    page: BreakAnalysis,
    permission: ["abacate | order | tracking"],
    link: "/app/orders/break/analysis",
  },
];

const DesktopNavbar = () => {
  const { permissions, signOut } = useAuth();
  const { addPopup } = usePopup();
  const classes = useStylesDesktop();
  const [open, setOpen] = useState(true);
  const history = useHistory();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  function logOut() {
    signOut();
    addPopup({
      type: "success",
      title: "Deslogado com sucesso",
    });

    history.push("/login");
  }

  return (
    <div>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon style={{ color: "#fff", fontSize: 30 }} />
          </IconButton>

          <Link to="/" className={classes.title}>
            <Typography
              component="h1"
              variant="h6"
              noWrap
              style={{ fontWeight: "bold", color: "#FFF" }}
            >
              Abacate Web
            </Typography>
          </Link>
          <IconButton
            color="inherit"
            onClick={logOut}
            style={{ marginLeft: "auto" }}
          >
            <ExitToAppIcon style={{ color: "#fff", fontSize: 30 }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <Typography
            style={{
              color: "#8BC34A",
              textAlign: "center",
              width: "100%",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            Menu
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {componentList.map((component, index) => (
            <PermissionGate
              key={index}
              permissions={component.permission}
              userPermissions={permissions}
            >
              <ListItem
                style={{
                  padding: "20px",
                }}
              >
                <Link to={component.link} onClick={handleClickOpen}>
                  <ListItemIcon style={{ color: "#8BC34A" }}>
                    {component.icon}
                  </ListItemIcon>
                  <ListItemText primary={component.label} />
                </Link>
              </ListItem>
            </PermissionGate>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MobileNavbar = () => {
  const { signOut, permissions } = useAuth();
  const { addPopup } = usePopup();
  const classes = useStylesMobile();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function logOut() {
    signOut();
    addPopup({
      type: "success",
      title: "Deslogado com sucesso",
    });

    history.push("/login");
  }

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleClickOpen}
          >
            <MenuIcon style={{ color: "#fff", fontSize: 25 }} />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.titleNav}
          >
            Abacate
          </Typography>
          <IconButton color="inherit" onClick={logOut}>
            <ExitToAppIcon style={{ color: "#fff", fontSize: 25 }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBarDialog}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Menu
            </Typography>
          </Toolbar>
        </AppBar>
        <List style={{ marginTop: 50 }}>
          {componentList.map((component, index) => (
            <PermissionGate
              key={index}
              permissions={component.permission}
              userPermissions={permissions}
            >
              <ListItem
                style={{
                  padding: "20px",
                }}
              >
                <Link to={component.link} onClick={handleClose}>
                  <ListItemIcon style={{ color: "#8BC34A" }}>
                    {component.icon}
                  </ListItemIcon>
                  <ListItemText primary={component.label} />
                </Link>
              </ListItem>
            </PermissionGate>
          ))}
        </List>
      </Dialog>
    </div>
  );
};

const useStylesLayout = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    backgroundColor: "#fafafa",
    overflow: "hidden",
  },
  content: {
    width: "100%",
    height: "100vh",
    overflow: "auto",
    padding: "75px 2% 10px",
    [theme.breakpoints.between("0", "1000")]: {
      position: "absolute",
    },
  },
}));

const Layout = () => {
  const classes = useStylesLayout();

  return (
    <div className={classes.root}>
      <DesktopNavbar />
      <MobileNavbar />
      <main className={classes.content}>
        <Switch>
          <Route
            path="/app/orders/:orderID/detail"
            exact
            component={OrderDetail}
            isPrivate
            permissionsRoute={["order | tracking"]}
          />
          <Route
            path="/app/orders/:orderID/break/create"
            exact
            component={CreateBreak}
            isPrivate
            permissionsRoute={["order | tracking"]}
          />
          <Route
            path="/app/orders/:orderID/break/:complaintID/edit"
            exact
            component={EditBreak}
            isPrivate
            permissionsRoute={["order | tracking"]}
          />
          {componentList.map(({ page: Page, ...component }, index) => (
            <Route
              key={index}
              path={component.link}
              exact
              component={Page}
              isPrivate
              permissionsRoute={component.permission}
            />
          ))}
        </Switch>
      </main>
    </div>
  );
};

export default Layout;
