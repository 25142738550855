import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import {
  StyledRefusedTable,
  StyledRefusedTableBody,
  StyledRefusedTableHead,
  StyledRefusedTableRow,
  useStyles,
} from "./styles";
import { Button } from "../../../components/Button";
import { StatusIcon } from "../../../components/Icons/StatusIcon";
import {
  SortArrowDown,
  SortArrowUp,
} from "../../../components/StyledIconButton";

export const HistoryTable = ({
  complaints,
  handleShowDetail,
  sort,
  handleSort,
}) => {
  const classes = useStyles();

  return (
    <Table stickyHeader className={classes.historyTable}>
      <TableHead>
        <TableRow>
          <TableCell className="titleStatus"></TableCell>
          <TableCell className="titleId">Quebra Nº</TableCell>
          <TableCell className="titleOrderId">Pedido</TableCell>
          <TableCell className="titleDate">
            Data{" "}
            {sort === "desc" ? (
              <SortArrowDown onClick={handleSort} />
            ) : (
              <SortArrowUp onClick={handleSort} />
            )}
          </TableCell>
          <TableCell className="titleValue">Valor</TableCell>
          <TableCell className="titleNegotiation">Tratativa</TableCell>
          <TableCell className="titleUpdated">Última Atualização</TableCell>
          <TableCell className="titleAction"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {complaints?.map((complaint, index) => (
          <TableRow key={complaint?.id}>
            <TableCell className="contentStatus">
              <StatusIcon statusId={complaint?.status?.id} />
            </TableCell>
            <TableCell className="contentId">{complaint?.id}</TableCell>
            <TableCell className="contentOrderId">
              {complaint?.order_id}
            </TableCell>
            <TableCell className="contentDate">
              <span>
                <span className="date">{complaint?.createdDate}</span>
                <span className="time">{complaint?.createdTime}</span>
              </span>
            </TableCell>

            <TableCell className="contentValue">
              {complaint?.complaintValue}
            </TableCell>

            <TableCell className="contentNegotiation">
              {complaint?.action}
            </TableCell>
            <TableCell className="contentUpdated">
              <span>
                <span className="date">{complaint?.updatedDate}</span>
                <span className="time">{complaint?.updatedTime}</span>
              </span>
            </TableCell>
            <TableCell className="contentAction">
              <Button
                btn="Secondary"
                onClick={() => handleShowDetail(complaint?.id)}
              >
                Ver detalhes
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const OrdersRefusedTable = ({ tickets, handleShowDetail }) => {
  return (
    <StyledRefusedTable stickyHeader>
      <StyledRefusedTableHead>
        <StyledRefusedTableRow>
          <TableCell className="status"></TableCell>
          <TableCell className="orderId">Pedido</TableCell>
          <TableCell className="clientName">Nome</TableCell>
          <TableCell className="clientType">Pessoas</TableCell>
          <TableCell className="reason">Motivo</TableCell>
          <TableCell className="updated">Última atualização</TableCell>
          <TableCell className="action"></TableCell>
        </StyledRefusedTableRow>
      </StyledRefusedTableHead>
      <StyledRefusedTableBody>
        {tickets?.map((ticket) => (
          <StyledRefusedTableRow key={ticket?.id}>
            <TableCell className="status">
              <StatusIcon statusId={2} />
            </TableCell>
            <TableCell className="orderId">{ticket?.orderId}</TableCell>
            <TableCell className="clientName">{ticket?.clientName}</TableCell>
            <TableCell className="clientType">{ticket?.clientType}</TableCell>
            <TableCell className="reason">{ticket?.reason?.name}</TableCell>
            <TableCell className="updated">
              <span>
                <span className="date">{ticket?.updatedDate}</span>
                <span className="time">{ticket?.updatedTime}</span>
              </span>
            </TableCell>
            <TableCell className="action">
              <Button
                btn="Secondary"
                onClick={() => handleShowDetail(ticket?.id)}
              >
                Ver detalhes
              </Button>
            </TableCell>
          </StyledRefusedTableRow>
        ))}
      </StyledRefusedTableBody>
    </StyledRefusedTable>
  );
};

export const TableComplaintTickets = ({ tickets, handleShowDetail }) => {
  const classes = useStyles();

  return (
    <Table stickyHeader className={classes.historyTable}>
      <TableHead>
        <TableRow>
          <TableCell className="titleStatus"></TableCell>
          <TableCell className="titleId">Quebra Nº</TableCell>
          <TableCell className="titleOrderId">Pedido</TableCell>
          <TableCell className="titleDate">Data</TableCell>
          <TableCell className="titleValue">Valor</TableCell>
          <TableCell className="titleNegotiation">Tratativa</TableCell>
          <TableCell className="titleUpdated">Última Atualização</TableCell>
          <TableCell className="titleAction"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tickets?.map((tickets) => (
          <TableRow key={tickets?.id}>
            <TableCell className="contentStatus">
              <StatusIcon statusId={2} />
            </TableCell>
            <TableCell className="contentId">{tickets?.complaintId}</TableCell>
            <TableCell className="contentOrderId">{tickets?.orderId}</TableCell>
            <TableCell className="contentDate">
              <span>
                <span className="date">{tickets?.createdDate}</span>
                <span className="time">{tickets?.createdTime}</span>
              </span>
            </TableCell>

            <TableCell className="contentValue">
              {tickets?.complaintValue}
            </TableCell>

            <TableCell className="contentNegotiation">Reembolso</TableCell>
            <TableCell className="contentUpdated">
              <span>
                <span className="date">{tickets?.updatedDate}</span>
                <span className="time">{tickets?.updatedTime}</span>
              </span>
            </TableCell>
            <TableCell className="contentAction">
              <Button
                btn="Secondary"
                onClick={() => handleShowDetail(tickets?.id)}
              >
                Ver detalhes
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
