import React from "react";

import { Popover } from "@material-ui/core";
import styled from "styled-components";
import {
  CloseIconButton,
  SendIconButton,
} from "../../../components/StyledIconButton";

const ActionContent = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  top: -144px;
  left: -378px;
`;

const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    display: flex;
    flex-direction: column;

    min-width: 390px;
    border: 1px solid #c4c4c4;
    background-color: #fff;
    border-radius: 4px 4px 0px 4px;
    padding: 1rem;
    gap: 0.5rem;
  }
`;

export const PopOverComment = ({
  id,
  open,
  anchorEl,
  handleClose,
  content,
  handleSave,
}) => {
  return (
    <StyledPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      elevation={0}
    >
      {content}
      <ActionContent>
        <SendIconButton onClick={handleSave} />
        <CloseIconButton onClick={handleClose} />
      </ActionContent>
    </StyledPopover>
  );
};
