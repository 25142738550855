import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CustomLineProgress } from "./CustomLineProgress";

export default function CustomTable({
  headRows,
  dataRows,
  data,
  classes,
  loading,
}) {
  return (
    <>
      <CustomLineProgress hidden={!loading} />
      <Table className={classes && classes.dataTable} cellSpacing="0">
        <TableHead>
          <TableRow className={classes && classes.tableHeader}>
            {headRows.map((cell, index) => (
              <TableCell key={index} className={cell.className}>
                {cell.content}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes && classes.tableBody}>
          {data.map((row, index) => (
            <TableRow key={index} className={classes.tableData}>
              {dataRows.map((cell, index) => (
                <TableCell key={index} className={cell.className}>
                  {cell.content(row)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell
              colSpan={headRows.length}
              style={{
                padding: "24px",
              }}
            ></TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
}
