import jwt_decode from "jwt-decode";
export const isAuthenticated = () => {
  if (sessionStorage.getItem("access") || localStorage.getItem("access")) {
    return true;
  } else {
    return false;
  }
};

export const returnToken = () => {
  return sessionStorage.getItem("access") || localStorage.getItem("access");
};

export const returnRefreshToken = () => {
  return sessionStorage.getItem("refresh") || localStorage.getItem("refresh");
};

export const loggedInUserInfo = () => {
  const token =
    sessionStorage.getItem("access") || localStorage.getItem("access");
  const decodedToken = jwt_decode(token);
  return decodedToken;
};
