import * as React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import CustomCircularProgress from "../utils/CircularProgress";
import { useState } from "react";

const theme = makeStyles({
  columnHeader: {
    backgroundColor: "#8BC34A",
    color: "#FFF",
    borderRight: "1px solid #cccccc",
  },
  root: {
    "& .MuiDataGrid-columnsContainer": {
      borderRadius: "10px 10px 0px 0px",
      backgroundColor: "#8BC34A",
    },
    borderRadius: "10px",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-cell": {
      "& + .MuiDataGrid-cell": {
        borderLeft: "1px solid #cccccc",
      },
    },
  },
});

export default function CustomDataGrid({
  rows,
  columns,
  loading,
  checkBox,
  showPaginate = true,
  ...rest
}) {
  const classes = theme();
  const [params, setParams] = useState({
    pageSize: 15,
    page: 1,
  });

  const handleChangeParams = (newParams) => {
    setParams((state) => ({ ...state, ...newParams }));
  };

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        classes={{
          columnHeader: classes.columnHeader,
          root: classes.root,
        }}
        rows={rows}
        columns={columns}
        pageSize={params.pageSize}
        page={params.page - 1}
        onPageSizeChange={({ pageSize }) =>
          handleChangeParams({ page: 1, pageSize })
        }
        onPageChange={(newPage) =>
          handleChangeParams({ page: newPage.page + 1 })
        }
        rowsPerPageOptions={[15, 25, 100]}
        checkboxSelection={checkBox}
        disableSelectionOnClick={false}
        loading={loading}
        autoHeight={true}
        pagination
        hideFooterPagination={!showPaginate}
        components={{
          LoadingOverlay: CustomCircularProgress,
        }}
        {...rest}
      />
    </div>
  );
}
