import { styled } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

export const CustomLineProgress = styled(LinearProgress)({
  backgroundColor: "#89BD23",

  "& .MuiLinearProgress-barColorPrimary": {
    backgroundColor: "#c5ed78d6",
  },
});
