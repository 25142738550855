import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import depotCityService from "../services/depotCityService";
import { usePopup } from "../hooks/usePopup";
import { useAuth } from "../hooks/useAuth";

export default function AlertDialog({ open, setOpen, registry, id, init }) {
  const handleClose = () => {
    setOpen(false);
  };
  const { addPopup } = usePopup();
  const { token } = useAuth();
  const deleteRegistry = () => {
    if (registry === "depot") {
      depotCityService
        .deleteDepot(id, token)
        .then((resp) => {
          if (resp.status === 200) {
            addPopup({
              type: "success",
              title: "Operação Concluída",
            });
            init();
          } else {
            throw new Error("");
          }
        })
        .catch((err) => {
          addPopup({
            type: "error",
            title: "Operação falhou",
          });
        })
        .finally(() => {
          setOpen(false);
        });
    } else if (registry === "city") {
      depotCityService
        .deleteCity(id, token)
        .then((resp) => {
          if (resp.status === 200) {
            addPopup({
              type: "success",
              title: "Operação Concluída",
            });
            init();
          } else {
            throw new Error("");
          }
        })
        .catch((err) => {
          addPopup({
            type: "error",
            title: "Operação falhou",
          });
        })
        .finally(() => {
          setOpen(false);
        });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Excluir registro"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem certeza que deseja excluir o registro? Isso implicará no
            fluxo de automações já existentes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteRegistry} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
