import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { GridOverlay } from "@material-ui/data-grid";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    zIndex: 1200,
  },
  circularProgress: {
    color: "#88bc23",
  },
});

const CustomCircularProgress = () => {
  const classes = useStyles();
  return (
    <GridOverlay>
      <div className={classes.root}>
        <CircularProgress className={classes.circularProgress} />
      </div>
    </GridOverlay>
  );
};

export default CustomCircularProgress;
