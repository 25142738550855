import React from "react";
import logo from "./../assets/images/logo.png";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    msg: {
      fontFamily: '"Inter", sans-serif',
      fontSize: 14,
      fontWeight: 600,
    },
    rotateImage: {
      width: 100,
      animation: "$spin 2s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotateY(360deg)",
      },
      "100%": {
        transform: "rotateY(0deg)",
      },
    },
  })
);

export default function PageLoader() {
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minWidth: "100%",
        minHeight: "100%",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 34343434343,
      }}
    >
      <img src={logo} alt="Logo Frexco" className={classes.rotateImage} />
      <span className={classes.msg}>
        Aguarde, estamos carregando os dados...
      </span>
    </div>
  );
}
