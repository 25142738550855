import { Box, styled } from "@material-ui/core";

const Card = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "0.5rem",
  border: `1px solid ${theme.colors.lightGray}`,
  borderRadius: "0.25rem",
  overflow: "auto",
  gap: "0.5rem",
}));

const Title = styled("h2")(({ theme }) => ({
  display: "flex",
  gap: "0.5rem",
  fontFamily: theme.font.Inter,
  fontSize: "1rem",
  fontWeight: 600,
  fontStyle: "normal",
  lineHeight: "1.171875rem",
  color: theme.colors.primary,
  margin: 0,
}));

const Text = styled("h3")(({ theme }) => ({
  display: "flex",
  fontFamily: theme.font.Roboto,
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: "1.171875rem",
  fontStyle: "normal",
  color: theme.colors.lightGray,
  gap: "0.5rem",
  margin: 0,
}));

const TextValue = styled("span")(({ theme }) => ({
  fontFamily: theme.font.Roboto,
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: "1.171875rem",
  fontStyle: "normal",
  color: theme.colors.primary,
}));

const ActionContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: "0.5rem",
  gap: "0.5rem",
  marginTop: "1.5rem",
}));

const ModalCard = {
  Card,
  Title,
  Text,
  TextValue,
  ActionContent,
};

export default ModalCard;
