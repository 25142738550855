import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function EditRouteDialog({
  open,
  setOpen,
  setRouteInfo,
  onConfirm,
  routeInfo,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Alterar Rotas</DialogTitle>
        <DialogContent>
          <p>
            Digite a nova rota para o pedido <strong>{routeInfo.id}</strong>
          </p>
          <TextField
            fullWidth
            variant="outlined"
            label="Rota"
            value={routeInfo.route}
            onChange={(event) => {
              setRouteInfo({ ...routeInfo, route: event.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ color: "#f44336" }}
          >
            Cancelar
          </Button>
          <Button
            variant="outlined"
            onClick={onConfirm}
            style={{ color: "#8BC34A" }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
