import React from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { formatDate } from "../../../utils/formatDate";
import formatCurrency from "../../../utils/formatCurrency";
import { FaRegFileAlt } from "react-icons/fa";
import {
  OrderTableProductContent,
  OrderTableQtyContent,
  OrderTableValueContent,
  StyledOrderTable,
  StyledTableBody,
  StyledTableHead,
  StyledTableRow,
  useStyle,
} from "./style";
import { Button } from "../../../components/Button";
import { StatusIcon } from "../../../components/Icons/StatusIcon";

export const HistoryTable = ({ complaints, handleShowDetail, orderData }) => {
  const classes = useStyle();
  return (
    <Table stickyHeader className={classes.historyTable}>
      <TableHead>
        <TableRow>
          <TableCell className="titleStatus"></TableCell>
          <TableCell className="titleId">Quebra Nº</TableCell>
          <TableCell className="titleDate">Data</TableCell>
          <TableCell className="titleValue">Valor</TableCell>
          <TableCell className="titleNegotiation">Tratativa</TableCell>
          <TableCell className="titleDetailNegotiation">
            Detalhe da tratativa
          </TableCell>
          <TableCell className="titleUpdated">Atualização</TableCell>
          <TableCell className="titleAction"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {complaints?.map((complaint, index) => (
          <TableRow key={complaint?.complaint_id}>
            <TableCell className="contentStatus">
              <StatusIcon statusId={complaint?.status?.id} />
            </TableCell>
            <TableCell className="contentId">
              {complaint?.complaint_id}
            </TableCell>
            <TableCell className="contentDate">
              <span>
                <span className="date">
                  {new Date(complaint?.created).toLocaleDateString()}
                </span>
                <span className="time">
                  {new Date(complaint?.created).toLocaleTimeString()}
                </span>
              </span>
            </TableCell>

            <TableCell className="contentValue">
              {complaint?.action?.name === "Cupom" &&
                formatCurrency(
                  complaint?.action_json?.coupon?.discountValue || 0
                )}

              {complaint?.action?.name === "Reembolso" &&
                formatCurrency(
                  complaint?.action_json?.refund?.refundValue || 0
                )}
              {complaint?.action?.name === "Desconto no boleto" &&
                formatCurrency(
                  complaint?.action_json?.discount_on_ticket?.discountValue ||
                    complaint?.action_json?.discountOnTicket?.discountValue ||
                    0
                )}

              {complaint?.action?.name === "Reposição" &&
                formatCurrency(
                  complaint?.action_json?.reposition?.repositionValue || 0
                )}

              {complaint?.action?.name === "Sem tratativa" &&
                formatCurrency(
                  complaint?.action_json?.without_solution?.complaintValue || 0
                )}
              {complaint?.action?.name === "Creditado na carteira" &&
                formatCurrency(
                  complaint?.action_json?.wallet?.amount || complaint?.action_json?.wallet?.creditValue || 0
                )}
            </TableCell>

            <TableCell className="contentNegotiation">
              {complaint?.action?.name}
            </TableCell>
            <TableCell className="content" style={{ maxWidth: 195 }}>
              {complaint?.action?.name === "Cupom" &&
                complaint?.action_json?.coupon?.key}

              {complaint?.action?.name === "Reembolso" && (
                <Grid>
                  {complaint?.action_json?.refund?.refundOption ===
                    "transferBank" && "Transferência bancária"}

                  {complaint?.action_json?.refund?.refundOption === "pix" &&
                    "Pix"}
                </Grid>
              )}

              {complaint?.action?.name === "Desconto no boleto" && (
                <Grid
                  className={classes.orderButtonsCard}
                  style={{ justifyContent: "normal" }}
                >
                  <span style={{ display: "flex" }}>
                    {new Date(orderData?.payments?.date) >
                      new Date(complaint?.updated) && (
                      <>
                        <FaRegFileAlt />
                        <a
                          href={orderData?.bill_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Acessar boleto
                        </a>
                      </>
                    )}
                  </span>
                </Grid>
              )}
              {complaint?.action?.name === "Reposição" && (
                <Grid>
                  {complaint?.action_json?.reposition?.redeliveryOption ===
                    "ifood" && (
                    <Box>
                      <Grid>Entrega via ifood</Grid>
                      <Grid style={{ fontSize: 12 }}>
                        {formatDate(complaint?.action_json?.reposition?.date)}
                      </Grid>
                    </Box>
                  )}

                  {complaint?.action_json?.reposition?.redeliveryOption ===
                    "nextDay" && (
                    <Box>
                      <Grid>{complaint?.action_json?.reposition?.newReplacementOrder ? `Pedido #${complaint?.action_json?.reposition?.newReplacementOrder}` : 'Agendado'}</Grid>
                      <Grid style={{ fontSize: 12 }}>
                        {`${formatDate(
                          complaint?.action_json?.reposition?.scheduleDate
                        )} ${
                          complaint?.action_json?.reposition?.scheduleTimeFrom
                        } às ${
                          complaint?.action_json?.reposition?.scheduleTimeTo
                        } `}
                      </Grid>
                    </Box>
                  )}
                  {complaint?.action_json?.reposition?.redeliveryOption ===
                    "nextDelivery" && "Próximo pedido"}
                </Grid>
              )}

              {complaint.action?.name === "Sem tratativa" && "Sem tratativa"}
              {complaint.action?.name === "Creditado na carteira" && (complaint.action_json?.wallet?.reason || complaint?.action_json?.wallet?.observation)}
            </TableCell>
            <TableCell className="contentUpdated">
              <span>
                <span className="date">{formatDate(complaint?.updated)}</span>
                <span className="time">
                  {new Date(complaint?.updated).toLocaleTimeString()}
                </span>
              </span>
            </TableCell>
            <TableCell className="contentAction">
              {complaint?.status?.id === 1 || complaint?.status?.id === 6 ? (
                <Button
                  btn="Secondary"
                  onClick={() => handleShowDetail(index, "resolve")}
                >
                  Tratar quebra
                </Button>
              ) : (
                <Button
                  btn="Secondary"
                  onClick={() =>
                    handleShowDetail(index, complaint?.action?.name)
                  }
                >
                  Ver detalhes
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const OrderTable = ({ items }) => {
  return (
    <StyledOrderTable>
      <StyledTableHead>
        <StyledTableRow>
          <TableCell className="sku">SKU</TableCell>
          <TableCell className="itemName">Item</TableCell>
          <TableCell className="qty">Quant de itens</TableCell>
          <TableCell className="qtyTotal">Total do Pedido</TableCell>
          <TableCell className="value">Valor</TableCell>
        </StyledTableRow>
      </StyledTableHead>

      <StyledTableBody>
        {items?.map((item) => (
          <StyledTableRow key={item?.id}>
            <TableCell className="sku">{item.sku}</TableCell>
            <TableCell className="itemName">
              <OrderTableProductContent>
                <Box>{item.productName}</Box>
                <Box className="portionName">{item.portionName}</Box>
              </OrderTableProductContent>
            </TableCell>
            <TableCell className="qty">{item.quantity}</TableCell>
            <TableCell className="qtyTotal">
              {item.availableQuantity < item.totalQuantity ? (
                <OrderTableQtyContent>
                  <Box className="originalQty">{`${item.totalQuantity} ${item.portionType}`}</Box>
                  <Box>{`${String(item.availableQuantity).replace('.', ',')} ${item.portionType}`}</Box>
                </OrderTableQtyContent>
              ) : (
                `${String(item.receivedQuantity).replace('.', ',')} ${item.portionType}`
              )}
            </TableCell>
            <TableCell className="value">
              {item.newTotalItensValue ? (
                <OrderTableValueContent>
                  <Box className="originalValue">{item.totalItensValue}</Box>
                  <Box>{item.newTotalItensValue}</Box>
                </OrderTableValueContent>
              ) : (
                item.totalItensValue
              )}
            </TableCell>
          </StyledTableRow>
        ))}
      </StyledTableBody>
    </StyledOrderTable>
  );
};

export const OrderTableFooter = ({ orderData, subTotal, complaintsTotalValue }) => {
  const classes = useStyle();
  return (
    <Grid className={classes.orderTableFooter}>
      <span className="subtotal">
        <span className="subtotalText">Subtotal:</span>
        <span className="subtotalValue">
          {formatCurrency(subTotal || 0)}
        </span>
      </span>
      {complaintsTotalValue > 0 && (
        <span className="missing">
          <span className="missingText">Valor Faltante:</span>
          <span className="missingValue">{`- ${formatCurrency(
            complaintsTotalValue
          )}`}</span>
        </span>
      )}

      <span className="shipping">
        <span className="shippingText">Frete:</span>
        <span className="shippingValue">
          {formatCurrency(orderData?.delivery_value || 0)}
        </span>
      </span>

      <span className="total">
        <span className="totalText">Total:</span>
        <span className="totalValue">
          {formatCurrency(subTotal + orderData?.delivery_value - complaintsTotalValue || 0)}
        </span>
      </span>
    </Grid>
  );
};
