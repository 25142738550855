import { api } from "../utils/Api/apiClient";

const getPerms = async (username) => {
  const url = `/api/access/user/permission/${username}`;
  return await api.get(url);
};

const permissionsService = {
  getPerms,
};
export default permissionsService;
