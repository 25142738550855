import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { animated } from "@react-spring/web";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";

import { usePopup } from "../../hooks/usePopup";

const icons = {
  info: <InfoOutlinedIcon size={24} style={{ margin: "4px 12px 0 0" }} />,
  error: <InfoOutlinedIcon size={24} style={{ margin: "4px 12px 0 0" }} />,
  success: (
    <CheckCircleOutlineRoundedIcon
      size={24}
      style={{ margin: "4px 12px 0 0" }}
    />
  ),
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "360px",
    position: "relative",
    zIndex: 4000,
    padding: "16px 30px 16px 16px",
    boxShadow: "2px 2px 8px rgba(0,0,0,0.2)",
    display: "flex",
    "& + div": {
      marginTop: "6px",
    },
    [theme.breakpoints.between("0", "600")]: {
      width: "100%",
    },
  },
  box: {
    flex: "1",
  },
  description: {
    marginTop: " 4px",
    fontSize: "14px",
    opacity: "0.8",
    lineHeight: "20px",
    [theme.breakpoints.between("0", "350")]: {
      fontSize: "13px",
    },
  },
  span: {
    position: "absolute",
    bottom: 0,
    left: 0,
    height: "6px",
    animation: "$spanAnimation 7s",
  },
  "@keyframes spanAnimation": {
    "0%": { width: "0%" },
    "100%": { width: "100%" },
  },
  strong: {
    [theme.breakpoints.between("0", "350")]: {
      fontSize: "12px",
    },
  },
  button: {
    position: "absolute",
    right: "2%",
    top: "10px",
    opacity: "0.6",
    border: "0",
    background: "transparent",
    color: "inherit",
    cursor: "pointer",
  },
}));

const variantColorsPopup = {
  info: {
    background: "#ebf8ff",
    color: "#3172b7",
  },
  success: {
    background: "#e6fffa",
    color: "#2e656a",
  },
  error: {
    background: "#fddede",
    color: "#c53030",
  },
};

const variantColorsSpan = {
  info: {
    background: "#3172b7",
  },
  success: {
    background: "#2e656a",
  },
  error: {
    background: "#c53030",
  },
};

export const Popup = ({ message, style }) => {
  const { removePopup } = usePopup();
  const classes = useStyles();
  const [color, setColor] = useState({
    colorPopup: {
      background: "#ebf8ff",
      color: "#3172b7",
    },
    spanPopup: {
      background: "#3172b7",
    },
  });

  useEffect(() => {
    const timer = window.setTimeout(() => {
      removePopup(message.id);
    }, 7000);

    const alignItem = !message.description
      ? {
          alignItems: "center",
          "> svg": {
            marginTop: 0,
          },
        }
      : {};

    setColor({
      colorPopup: {
        ...variantColorsPopup[message.type],
        ...alignItem,
      },
      spanPopup: variantColorsSpan[message.type],
    });

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <animated.div
      className={classes.root}
      style={{ ...color.colorPopup, ...style }}
    >
      {icons[message.type || "info"]}

      <div className={classes.box}>
        <strong className={classes.strong}>{message.title}</strong>
        {message.description && (
          <p className={classes.description}>{message.description}</p>
        )}
      </div>

      <button
        onClick={() => removePopup(message.id)}
        className={classes.button}
        type="button"
      >
        <HighlightOffOutlinedIcon size={18} />
      </button>
      <span className={classes.span} style={color.spanPopup}></span>
    </animated.div>
  );
};
