import formatCurrency from "../../../utils/formatCurrency";
import { formatDate } from "../../../utils/formatDate";

export const formatItem = (item) => {
  return {
    id: item?.id,
    complaintId: item?.complaint?.id,
    complaintDate: formatDate(item?.complaint?.created),
    rescheduleDate: formatDate(item?.complaint?.order?.scheduled_date),
    orderId: item?.complaint?.order?.id,
    clientName:
      item?.complaint?.order?.client?.trade_name ||
      item?.complaint?.order?.client?.name,
    clientType: item?.complaint?.order?.client?.type,
    itemSku: item?.origin_sku,
    itemName: item?.product?.name,
    itemObservation: item?.observation,
    originalQty: item?.quantity_original,
    complaintQty: item?.quantity_complaint,
    returnedQty: item?.quantity_returned,
    complaintValue: formatCurrency(item?.complaint_value),
    complaintReason: item?.reason,
    complaintObservation: item?.complaint?.observation,
    complaintImage: item?.item_image,
    responsibleId:
      item?.classification?.responsible?.id ||
      item?.classification?.at(-1)?.responsible?.id,
    responsibleName: item?.classification?.responsible?.name,
    responsibleObservation:
      item?.classification?.observation ||
      item?.classification?.at(-1)?.observation,
    action: item?.complaint?.solution?.action?.name || "Não tratada",
    createdBy: item?.complaint?.created_by?.username || "Não informado",
    isChecked: false,
    originReport: item?.complaint?.origin_report,
    itemImage: item?.item_image,
    productBaseId:
      item?.product?.product_base_id || item?.product?.product_base,
    classifiedBy:
      item.classification?.created_by?.username || item.classification,
    classifier: item.classification?.created_by,
  };
};

export const formatProductBase = (product) => {
  return {
    id: product.id,
    name: product.name,
    isChecked: false,
  };
};

export const formatReason = (reason) => {
  return {
    id: reason.id,
    name: reason.name,
    isChecked: false,
  };
};

export const formatResponsible = (responsible) => {
  return {
    id: responsible.id,
    name: responsible.name,
    isChecked: false,
  };
};

export const formatClassifier = (classifier) => {
  return {
    id: classifier.id,
    name: classifier.username,
    isChecked: false,
  };
};
