import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import { useCallback, useMemo } from "react";
import { memo } from "react";
import { Button } from "../../../components/Button";
import { SelectClearButtonContent, StyledSelectResponsible } from "./style";

export const SelectResponsible = memo(
  ({
    responsibleReasonList,
    items,
    handleChangeSelectedResponsible,
    handleClearSelectedResponsible,
    statusFilters,
  }) => {
    const filteredItems = useMemo(() => {
      return items?.filter((item) =>
        statusFilters !== "all"
          ? statusFilters === "unclassified"
            ? !item?.responsibleId
            : item?.responsibleId
          : item
      );
    }, [statusFilters, items, responsibleReasonList]);

    const itemsResponsible = useMemo(() => {
      return filteredItems?.reduce((acc, item) => {
        if (!acc?.includes(item?.responsibleId)) {
          return [...acc, item?.responsibleId];
        }
        return acc;
      }, []);
    }, [filteredItems]);

    const qtyResponsible = useCallback(
      (id) => {
        return filteredItems?.reduce(
          (acc, item) => (item.responsibleId === id ? acc + 1 : acc),
          0
        );
      },
      [filteredItems]
    );

    const menuItems = useMemo(() => {
      return responsibleReasonList
        ?.filter(({ id }) => itemsResponsible?.includes(id))
        ?.map((responsible) => {
          return (
            <MenuItem key={responsible.id} value={responsible.id}>
              <Checkbox
                value={responsible.id}
                checked={responsible.isChecked}
                onChange={() => handleClickItem(responsible.id)}
              />
              <ListItemText
                primary={`${responsible.name} (${qtyResponsible(
                  responsible.id
                )})`}
              />
            </MenuItem>
          );
        });
    }, [filteredItems]);

    const handleClickItem = (responsibleId) => {
      handleChangeSelectedResponsible(responsibleId);
    };

    return (
      <FormControl variant="outlined">
        <InputLabel id="select-product-base">Responsável</InputLabel>
        <StyledSelectResponsible
          labelId="select-product-base"
          multiple
          value={responsibleReasonList?.filter(({ id }) =>
            itemsResponsible?.includes(id)
          )}
          onClick={(e) => {
            const responsibleId = e.target.value?.slice(-1)[0];
            handleClickItem(responsibleId);
          }}
          label="Responsável"
          disabled={!menuItems?.length}
          renderValue={() =>
            responsibleReasonList
              .filter(({ isChecked }) => isChecked)
              .map((responsible) => responsible.name)
              .join(", ")
          }
        >
          {menuItems}
          <SelectClearButtonContent>
            <Button
              fullWidth
              onClick={(e) => {
                e.stopPropagation();
                handleClearSelectedResponsible();
              }}
            >
              Limpar
            </Button>
          </SelectClearButtonContent>
        </StyledSelectResponsible>
      </FormControl>
    );
  }
);
