import { Box, styled } from "@material-ui/core";
import { CloseIconButton } from "../StyledIconButton";

const StyledModalBody = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: 544,
  maxHeight: "90vh",
  overflow: "auto",
  paddingBlock: 8,
  paddingInline: 8,
  backgroundColor: "#FFF",

  "@media (max-width:800px)": {
    width: "95%",
    maxHeight: "100%",
  },

  "& .closeIcon": {
    textAlign: "end",
    "& svg": {
      color: "#757575",
      width: 20,
      height: 20,
      cursor: "pointer",
    },
  },
});

export const ModalBody = ({ children, handleClose }) => {
  return (
    <StyledModalBody>
      <Box className="closeIcon">
        <CloseIconButton onClick={handleClose} />
      </Box>
      {children}
    </StyledModalBody>
  );
};
