export const formatOrderGroupItems = (items) => {
  return (items || []).map((item, index) => ({
    id: index + 1,
    external_id: item.order?.id,
    status: item.order?.status.toUpperCase(),
    created: new Date(item.order?.created).toLocaleString(),
    delivery_date: new Date(item.order?.scheduled_date).toLocaleString(),
    route: item.order?.route?.route,
    tipo_cliente: item.order?.client.type,
    cpf_cnpj: item.order?.client.cpf_cnpj,
    trade_name: item.order?.client.trade_name,
    dd_name: item.order?.client.name,
    name: item.order?.client.name,
    id_item: item.id,
    item_sku: item.sku,
    description: item.description,
    quantity: item.quantity,
    weight_kilo: item.product?.weight_kilo?.toFixed(2),
    order_weight_sum: item.order?.order_weight_sum?.toFixed(2),
    item_weight_sum: item.item_weight_sum?.toFixed(2),
    separation_order: item.sequence,
    address: `${item.order?.shipping_address.street}, ${item.order?.shipping_address.number}, ${item.order?.shipping_address.complement}`,
    neighborhood: item.order?.shipping_address.neighborhood,
    postal_code: item.order?.shipping_address.zip,
    region: item.order?.shipping_address?.neighborhood?.toUpperCase(),
  }));
};
