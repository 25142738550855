import { makeStyles } from "@material-ui/core";
import { ReactComponent as IconStatus } from "../../assets/images/status.svg";
import Tooltip from "@material-ui/core/Tooltip";

const useStyle = makeStyles({
  1: {
    width: 16,
    height: 16,
    "& path": {
      fill: "#E11414",
    },
  },
  2: {
    width: 16,
    height: 16,
    "& path": {
      fill: "#0788FF",
    },
  },
  3: {
    width: 16,
    height: 16,
    "& path": {
      fill: "#B007FF",
    },
  },
  4: {
    width: 16,
    height: 16,
    "& path": {
      fill: "#FF0760",
    },
  },
  5: {
    width: 16,
    height: 16,
    "& path": {
      fill: "#89BD23",
    },
  },
  6: {
    width: 16,
    height: 16,
    "& path": {
      fill: "#E66111",
    },
  },
  7: {
    width: 40,
    height: 40,
    "& path": {
      fill: "#89BD23",
    },
  },
  8: {
    width: 40,
    height: 40,
    "& path": {
      fill: "#E11414",
    },
  },
  9: {
    width: 40,
    height: 40,
    "& path": {
      fill: "#520000",
    },
  },
  10: {
    width: 30,
    height: 30,
    "& path": {
      fill: "#E11414",
    },
  },
  11: {
    width: 28,
    height: 28,
    "& path": {
      fill: "#6a5d43",
    },
  },
});

export const StatusIcon = ({ statusId }) => {
  const classes = useStyle();
  const statusTip = {
    1: "Não iniciado",
    2: "Financeiro",
    3: "Encantamento",
    4: "Faturamento",
    5: "Finalizado",
    6: "Farmers",
    7: "Reagendado",
    8: "Recusado",
    9: "Cancelado",
    10: "Não devolvido",
    11: "Alerta",
  };
  return (
    <Tooltip title={statusTip[statusId]} aria-label="Status">
      <IconStatus className={classes[statusId]} />
    </Tooltip>
  );
};
