import React from "react";
import { BrowserRouter } from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import { AuthProvider } from "./hooks/useAuth";
import { PopupProvider } from "./hooks/usePopup";
import Routes from "./routes/public";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/core";
import { useTheme } from "./styles/theme";
import { ApiInterceptor } from "./utils/Api/interceptor";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer autoClose={3000} />
      <ThemeProvider theme={useTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <PopupProvider>
            <AuthProvider>
              <ApiInterceptor>
                <Routes />
              </ApiInterceptor>
            </AuthProvider>
          </PopupProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
