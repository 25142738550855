import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from "@material-ui/core";
import { Modal } from "./Modal";
import { Button } from "../Button";
import CustomDataGrid from "../../components/dataGrid";
import { useState } from "react";

const ConfirmModalContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  backgroundColor: "#FFF",
  fontFamily: '"Inter", sans-serif',
  borderRadius: "9px",
  maxWidth: "28rem",
  maxHeight: "36rem",
  height: "100%",
  width: "100%",
  padding: "0 0.9rem",

  "& .closeIcon": {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    color: theme.colors.lightGray,
    "& svg": {
      cursor: "pointer",
    },
  },

  "& .title": {
    marginTop: "1rem",
    fontFamily: theme.font.Inter,
    fontSize: "1.2rem",
    fontWeight: 500,
  },

  "& .subtitle": {
    fontFamily: '"Inter", sans-serif',
    fontSize: "1.10rem",
    marginTop: "1rem",
    marginBottom: "1rem",
    fontWeight: 500,
    textAlign: "center",
  },

  "& .legend": {
    fontFamily: '"Inter", sans-serif',
    fontSize: "1.10rem",
    fontWeight: 350,
    textAlign: "center",
    marginTop: "3rem",
  },

  "& .actions": {
    display: "flex",
    gap: "4rem",
    marginTop: "1rem",
    marginBottom: "1rem",
  },

  "& .customDataGrid": {
    width: "100%",
    maxHeight: "18rem",
    overflow: "auto",
    flexGrow: 1,
  },
}));

export const ConfirmModalWithTable = ({
  handleClose,
  confirmText,
  subtitle,
  legend,
  open,
  onClose,
  rows,
  columns,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ConfirmModalContent>
        <span className="title">{confirmText}</span>
        <span className="subtitle">{subtitle}</span>
        <div className="customDataGrid">
          <CustomDataGrid rows={rows} columns={columns} />
        </div>
        <span className="legend">{legend}</span>
        <span className="actions">
          <Button
            btn="secondary"
            onClick={handleClose}
            style={{ padding: "15px 30px" }}
          >
            Beleza!
          </Button>
        </span>
      </ConfirmModalContent>
    </Modal>
  );
};
