import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import { useCallback, useMemo } from "react";
import { memo } from "react";
import { Button } from "../../../components/Button";
import { SelectClearButtonContent, StyledSelectResponsible } from "./style";

export const SelectClassifier = memo(
  ({
    classifierList,
    items,
    handleChangeSelectedClassifier,
    handleClearSelectedClassifier,
    statusFilters,
  }) => {
    const filteredItems = useMemo(() => {
      return (items || []).filter((item) =>
        statusFilters !== "all"
          ? statusFilters === "unclassified"
            ? !item.classifier?.id
            : item.classifier?.id
          : item
      );
    }, [statusFilters, items, classifierList]);

    const qtyClassifier = useCallback(
      (id) => {
        return filteredItems?.reduce(
          (acc, item) => (item.classifier?.id === id ? acc + 1 : acc),
          0
        );
      },
      [filteredItems]
    );

    const menuItems = useMemo(() => {
      return classifierList?.reduce((acc, classifier) => {
        const qty = qtyClassifier(classifier.id);
        if (!qty) {
          return acc;
        }
        const item = (
          <MenuItem key={classifier.id} value={classifier.id}>
            <Checkbox
              value={classifier.id}
              checked={classifier.isChecked}
              onChange={() => handleClickItem(classifier.id)}
            />
            <ListItemText
              primary={`${classifier.name} (${qtyClassifier(classifier.id)})`}
            />
          </MenuItem>
        );
        return [...acc, item];
      }, []);
    }, [filteredItems]);
    const handleClickItem = (classifier) => {
      handleChangeSelectedClassifier(classifier);
    };

    return (
      <FormControl variant="outlined">
        <InputLabel id="select-classifier">Classificado por</InputLabel>
        <StyledSelectResponsible
          labelId="select-classifier"
          multiple
          value={classifierList}
          onClick={(e) => {
            const classifierId = e.target.value?.slice(-1)[0];
            handleClickItem(classifierId);
          }}
          label="Classificado por"
          disabled={!menuItems?.length}
          renderValue={() =>
            classifierList
              .filter(({ isChecked }) => isChecked)
              .map((classifier) => classifier.name)
              .join(", ")
          }
        >
          {menuItems}
          <SelectClearButtonContent>
            <Button
              fullWidth
              onClick={(e) => {
                e.stopPropagation();
                handleClearSelectedClassifier();
              }}
            >
              Limpar
            </Button>
          </SelectClearButtonContent>
        </StyledSelectResponsible>
      </FormControl>
    );
  }
);
