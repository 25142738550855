import { createContext, useContext, useState } from "react";
import { v4 } from "uuid";

import { PopupContainer } from "../components/popup/popupContainer";

const PopupContext = createContext({});

const PopupProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const addPopup = ({ type, title, description }) => {
    const id = v4();

    const popup = {
      id,
      type,
      title,
      description,
    };

    setMessages((state) => [...state, popup]);
  };

  const removePopup = (id) => {
    setMessages((state) => state.filter((message) => id !== message.id));
  };

  return (
    <PopupContext.Provider value={{ addPopup, removePopup }}>
      {children}
      <PopupContainer messages={messages} />
    </PopupContext.Provider>
  );
};

const usePopup = () => {
  const context = useContext(PopupContext);
  return context;
};

export { PopupProvider, usePopup };
