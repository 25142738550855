import { TableCell } from "@material-ui/core";
import ModalCard from "../../Modals/ModalCard";
import {
  ItensCard,
  StyledItensTable,
  StyledTableBody,
  StyledTableHead,
  StyledTableRow,
} from "./style";

export const ItemsTable = ({ items, title }) => {
  return (
    <ItensCard>
      <ModalCard.Title>{title || "Itens da Quebra"}</ModalCard.Title>
      <StyledItensTable>
        <StyledTableHead>
          <StyledTableRow>
            <TableCell className="sku">SKU</TableCell>
            <TableCell className="name">Produto</TableCell>
            <TableCell className="qtyComplaint">Quant quebra</TableCell>
          </StyledTableRow>
        </StyledTableHead>
        <StyledTableBody>
          {items?.map((item) => (
            <StyledTableRow key={item.itemId || item.id}>
              <TableCell className="sku">{item.itemSku || item.sku}</TableCell>
              <TableCell className="name">
                {item.itemName || item.name || item.product_name}
              </TableCell>
              <TableCell className="qtyComplaint">
                {(item.complaintQty || item.quantity_complaint).toFixed(3)}
              </TableCell>
            </StyledTableRow>
          ))}
        </StyledTableBody>
      </StyledItensTable>
    </ItensCard>
  );
};

export const ItemsOrderTable = ({ items, title }) => {
  return (
    <ItensCard>
      <ModalCard.Title>{title || "Itens da Quebra"}</ModalCard.Title>
      <StyledItensTable>
        <StyledTableHead>
          <StyledTableRow>
            <TableCell className="sku">SKU</TableCell>
            <TableCell className="name">Produto</TableCell>
            <TableCell className="qtyComplaint">Quantidade</TableCell>
          </StyledTableRow>
        </StyledTableHead>
        <StyledTableBody>
          {items?.map((item) => (
            <StyledTableRow key={item.itemId}>
              <TableCell className="sku">{item.itemSku}</TableCell>
              <TableCell className="name">{item.itemName}</TableCell>
              <TableCell className="qtyComplaint">{item.itemQty}</TableCell>
            </StyledTableRow>
          ))}
        </StyledTableBody>
      </StyledItensTable>
    </ItensCard>
  );
};
