import { Checkbox, TableCell } from "@material-ui/core";
import {
  StyledTable,
  StyledTableBody,
  StyledTableHead,
  StyledTableRow,
} from "./style";

import { AnalysisStatusIcon } from "../../../components/Icons/AnalysisStatusIcon";
import { StyledPhotoIcon } from "../../../components/Icons/PhotoIcon";
import { StyledNoPhotoIcon } from "../../../components/Icons/NoPhotoIcon";
import { Fragment, memo } from "react";
import { TableVirtuoso } from "react-virtuoso";
import { forwardRef } from "react";

export const BreakTable = memo(
  ({ handleOpenSeeMore, handleCheckItem, items }) => {
    return (
      <TableVirtuoso
        style={{ height: "50vh" }}
        data={items}
        components={{
          Table: (props) => <StyledTable stickyHeader {...props} />,
          TableHead: (props) => <StyledTableHead {...props} />,
          TableRow: ({ item, index, ...props }) => (
            <StyledTableRow
              {...props}
              key={item.id}
              onClick={() => {
                handleOpenSeeMore(item.id);
              }}
            />
          ),
          TableBody: forwardRef((props, ref) => (
            <StyledTableBody {...props} ref={ref} />
          )),
        }}
        fixedHeaderContent={() => (
          <StyledTableRow>
            <TableCell className="check"></TableCell>
            <TableCell className="status"></TableCell>
            <TableCell className="breakDate">Data da quebra</TableCell>
            <TableCell className="scheduleDate">Data da entrega</TableCell>
            <TableCell className="orderId">Pedido</TableCell>
            <TableCell className="clientName">Nome</TableCell>
            <TableCell className="sku">SKU</TableCell>
            <TableCell className="productName">Nome do produto</TableCell>
            <TableCell className="qtyOriginal">Quant original</TableCell>
            <TableCell className="qtyComplaint">Quant quebra</TableCell>
            <TableCell className="responsibleReclassification">
              Classificado por
            </TableCell>
            <TableCell className="responsibleName">Responsável</TableCell>
            <TableCell className="photo">Foto</TableCell>
          </StyledTableRow>
        )}
        itemContent={(index, item) => (
          <Fragment key={index}>
            <TableCell className="check">
              <Checkbox
                checked={item?.isChecked}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={() => {
                  handleCheckItem(item?.id);
                }}
              />
            </TableCell>

            <TableCell className="status">
              <AnalysisStatusIcon isResolved={item.responsibleId} />
            </TableCell>

            <TableCell className="breakDate">{item.complaintDate}</TableCell>
            <TableCell className="scheduleDate">
              {item.rescheduleDate}
            </TableCell>
            <TableCell className="orderId">{item.orderId}</TableCell>
            <TableCell className="clientName">{item.clientName}</TableCell>
            <TableCell className="sku">{item.itemSku}</TableCell>
            <TableCell className="productName">{item.itemName}</TableCell>
            <TableCell className="qtyOriginal">{item.originalQty}</TableCell>
            <TableCell className="qtyComplaint">{Number.isInteger(item.complaintQty)  ? item.complaintQty : item.complaintQty.toFixed(3)}</TableCell>
            <TableCell className="responsibleReclassification">
              {item.classifiedBy}
            </TableCell>
            <TableCell className="responsibleName">
              {item.responsibleName}
            </TableCell>
            <TableCell className="responsibleName">
              {item.itemImage ? <StyledPhotoIcon /> : <StyledNoPhotoIcon />}
            </TableCell>
          </Fragment>
        )}
      />
    );
  }
);
