import { styled } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const StyledIcon = styled(FiberManualRecordIcon)(({ theme, isResolved }) => ({
  width: "1.25rem",
  height: "1.25rem",
  color: isResolved ? theme.colors.avocado : theme.colors.alertRed,
}));

export const AnalysisStatusIcon = ({ isResolved }) => {
  const statusTip = isResolved ? "Classificado" : "Não classificado";
  return (
    <Tooltip title={statusTip} aria-label="Status">
      <StyledIcon isResolved={isResolved} />
    </Tooltip>
  );
};
