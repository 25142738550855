import {
  Box,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import { Button } from "../../../components/Button";
import { ModalBody } from "../../../components/Modals/ModalBody";
import ModalCard from "../../../components/Modals/ModalCard";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Calendar from "../../../components/Calendar/ScheduleCalendar";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import WatchLaterRoundedIcon from "@material-ui/icons/WatchLaterRounded";
import {
  CouponActionContent,
  CouponBodyContent,
  ModalTitle,
  DivOneField,
  DivTwoField,
  ModalAttachment,
  ModalBillLink,
  ModalContent,
  ModalHeading,
  ResolveModalContent,
  OtherNegotiationActionContent,
  DateTimeContent,
  CalendarContent,
  FormControlSelectTime,
  OtherNegotiationContent,
  RedeliveryModalContent,
  RedeliveryIfoodModalContent,
  NoSolutionModalContent,
  NoSolutionModalSubTitle,
  NoSolutionModalAction,
  NoSolutionModalTitleContent,
  NoContactModal,
  AlertInfo,
  PixContent,
} from "./styles";
import { BsPaperclip } from "react-icons/bs";

import { RiFileTextLine } from "react-icons/ri";
import { ItemsTable } from "../../../components/Table/ItemsTable";
import { useMemo } from "react";
import { H1 } from "../../../components/Heading/Heading";
import { formatDate } from "../../../utils/formatDate";
import SelectTime from "./selectTime";
import NumberFormat from "react-number-format";
import { DefaultInput } from "../../../components/Inputs/DefaultInput";
import {
  CloseIconButton,
  EditIconButton,
} from "../../../components/StyledIconButton";
import { Modal } from "../../../components/Modals/Modal";
import { useHistory } from "react-router-dom";

const formattedItens = (itens) => {
  if (!itens) {
    return [];
  }
  return itens?.map((item) => {
    return {
      itemId: item.id,
      itemSku: item?.sku,
      itemName: item?.product_name,
      complaintQty: item?.quantity_complaint,
    };
  });
};

export const DetailRefund = ({
  complaint,
  handleClose,
  loading,
  handleConfirm,
}) => {
  const itens = formattedItens(complaint?.items);
  return (
    <ModalBody handleClose={handleClose}>
      {complaint?.action === "Desconto no boleto" && (
        <ModalContent>
          <ModalHeading>
            {`Detalhes da Quebra - Pedido #${complaint?.order_id}`}
          </ModalHeading>
          <ModalCard.Card>
            <ModalCard.Title>Dados do Cliente</ModalCard.Title>
            <ModalCard.Text>
              Nome:
              <ModalCard.TextValue>
                {complaint?.client?.trade_name || complaint?.client?.name}
              </ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              CPF/CNPJ:
              <ModalCard.TextValue>{complaint?.CpfCnpj}</ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>
          <ItemsTable items={itens} />
          <ModalCard.Card>
            <ModalCard.Title>Dados de Tratativa</ModalCard.Title>
            <ModalCard.Text>
              Tratativa:
              <ModalCard.TextValue>Desconto no boleto</ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              Valor de Devolução:
              <ModalCard.TextValue>
                {complaint.complaintValue}
              </ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>

          <ModalBillLink>
            <a
              href={complaint?.bill_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <RiFileTextLine /> Acessar boleto
            </a>
          </ModalBillLink>

          <ModalCard.Text>
            Criado por:
            <ModalCard.TextValue>
              {complaint.responsible?.created_by}
            </ModalCard.TextValue>
          </ModalCard.Text>
          <ModalCard.ActionContent>
            <Button loading={loading} onClick={() => handleConfirm("solution")}>
              Finalizar
            </Button>
          </ModalCard.ActionContent>
        </ModalContent>
      )}
      {complaint.action === "Reembolso" && (
        <ModalContent>
          <ModalHeading>
            {`Detalhes da Quebra - Pedido #${complaint.order_id}`}
          </ModalHeading>
          <ModalCard.Card>
            <ModalCard.Title>Dados do Cliente</ModalCard.Title>
            <ModalCard.Text>
              Nome:
              <ModalCard.TextValue>
                {complaint.client.trade_name || complaint.client.name}
              </ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              CPF/CNPJ:
              <ModalCard.TextValue>{complaint.CpfCnpj}</ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>
          <ItemsTable items={itens} />
          <ModalCard.Card>
            <ModalCard.Title>Dados de Tratativa</ModalCard.Title>
            <ModalCard.Text>
              Tratativa:
              <ModalCard.TextValue>Reembolso</ModalCard.TextValue>
            </ModalCard.Text>
            {complaint?.solution?.action_json?.refund?.refundOption ===
              "pix" && (
              <>
                <ModalCard.Text>
                  Tipo Chave Pix:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.typePix}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Chave Pix:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.keyPix}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Favorecido:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.favored ||
                      "Não informado"}
                  </ModalCard.TextValue>
                </ModalCard.Text>
              </>
            )}
            {complaint?.solution?.action_json?.refund?.refundOption ===
              "transferBank" && (
              <>
                <ModalCard.Text>
                  Tipo de Conta:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.typeAccount}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Banco:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.bankName}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Agência:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.bankBranch}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Conta:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.bankAccount}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  CPF/CNPJ:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.bankDocument}
                  </ModalCard.TextValue>
                </ModalCard.Text>
              </>
            )}
            <ModalCard.Text>
              Valor de Devolução:
              <ModalCard.TextValue>
                {complaint.complaintValue}
              </ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>
          <ModalCard.Card>
            <ModalCard.Title>Nota fiscal</ModalCard.Title>
            <ModalCard.Text>
              Nº:
              <ModalCard.TextValue>
                {complaint?.solution?.invoice_number}
              </ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>

          <ModalCard.Text>
            Estorno parcial no cartão:
            <ModalCard.TextValue>
              {complaint?.solution?.chargeback_card ? "Sim" : "Não"}
            </ModalCard.TextValue>
          </ModalCard.Text>

          <ModalAttachment>
            <span>
              <BsPaperclip />{" "}
              <a
                href={complaint?.payment_receipt_url}
                target="_blank"
                rel="noreferrer"
              >
                Baixar comprovante
              </a>
            </span>
          </ModalAttachment>
          <ModalCard.Text>
            Criado por:
            <ModalCard.TextValue>
              {complaint?.responsible?.created_by}
            </ModalCard.TextValue>
          </ModalCard.Text>
          <ModalCard.ActionContent>
            <Button loading={loading} onClick={() => handleConfirm("solution")}>
              Finalizar
            </Button>
          </ModalCard.ActionContent>
        </ModalContent>
      )}
    </ModalBody>
  );
};

export const BreakResolve = ({
  complaint,
  handleClose,
  handleOpenCreditAnnotation,
  handleOpenCoupon,
  handleOpenOtherNegotiations,
  handleOpenWithoutSolution,
}) => {
  const history = useHistory();
  const complaintValue = useMemo(() => {
    return complaint?.items?.reduce((acc, { complaint_value }) => {
      return acc + complaint_value;
    }, 0);
  }, [complaint]);

  const itens = formattedItens(complaint?.items) || [];
  return (
    <ResolveModalContent>
      <Box className="closeIcon">
        <CloseIconButton onClick={handleClose} />
      </Box>
      <Box className="content">
        <Box className="head">
          <span>{`Detalhe da Quebra - Pedido #${complaint?.orderId}`}</span>

          <Box className="icons">
            <EditIconButton
              onClick={() =>
                history.push(
                  `../orders/${complaint?.order_id}/break/${complaint?.id}/edit`
                )
              }
            />
          </Box>
        </Box>

        <ModalCard.Card>
          <ModalCard.Title>Dados do Cliente</ModalCard.Title>
          <ModalCard.Text>
            Nome:
            <ModalCard.TextValue>{complaint?.clientName}</ModalCard.TextValue>
          </ModalCard.Text>
          <ModalCard.Text>
            Contato:
            <ModalCard.TextValue>{complaint?.clientPhone}</ModalCard.TextValue>
          </ModalCard.Text>
          <ModalCard.Text>
            Email:
            <ModalCard.TextValue>{complaint?.clientEmail}</ModalCard.TextValue>
          </ModalCard.Text>
        </ModalCard.Card>

        <ItemsTable items={itens} />

        <ModalCard.Text>
          Criado por:
          <ModalCard.TextValue>
            {complaint?.responsible?.created_by || "Não informado"}
          </ModalCard.TextValue>
        </ModalCard.Text>

        <AlertInfo>
          <h3>
            <ErrorOutlineIcon /> Evitar tratativas de reposição e reembolso.
          </h3>
        </AlertInfo>
        <Box>
            <Box className="principal-action">
              <Button
                onClick={() => handleOpenCreditAnnotation(complaintValue)}
              >
                Carteira
              </Button>
            </Box>
            <Box className="action">
              <Button
                onClick={() => handleOpenWithoutSolution(complaintValue)}
              >
                Sem tratativa
              </Button>
              <Button
                onClick={() => handleOpenCoupon(complaintValue)}
              >
                Cupom
              </Button>
              <Button
                onClick={() => handleOpenOtherNegotiations(complaintValue)}
              >
                Outras tratativas
              </Button>
            </Box>
          </Box>
      </Box>
    </ResolveModalContent>
  );
};

export const CouponModalBody = ({
  complaint,
  couponCode,
  complaintValue,
  handleClose,
  handleConfirm,
}) => {
  const todayDate = new Date();
  return (
    <ModalBody handleClose={handleClose}>
      <ModalTitle>
        <H1>Novo Cupom</H1>
      </ModalTitle>
      <CouponBodyContent>
        <DivOneField>
          <TextField
            label="Nome do cliente"
            variant="outlined"
            fullWidth
            value={complaint?.clientName}
            InputProps={{
              readOnly: true,
            }}
            focused={false}
          />
        </DivOneField>
        <DivOneField>
          <TextField
            label="Chave do Cupom"
            variant="outlined"
            value={couponCode}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            focused={false}
          />
        </DivOneField>
        <DivTwoField>
          <Box>
            <TextField
              label="Valor do Cupom"
              variant="outlined"
              value={complaintValue}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              focused={false}
            />
          </Box>
          <Box>
            <TextField
              label="Forma de Uso"
              variant="outlined"
              value="Único"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              focused={false}
            />
          </Box>
        </DivTwoField>
        <DivTwoField>
          <Box>
            <TextField
              label="Válido a partir"
              value={formatDate(new Date())}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              focused={false}
            />
          </Box>
          <Box>
            <TextField
              label="Válido até"
              variant="outlined"
              value={formatDate(
                new Date(todayDate.setDate(todayDate.getDate() + 30))
              )}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              focused={false}
            />
          </Box>
        </DivTwoField>
        <CouponActionContent>
          <Button btn="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={() => handleConfirm("negotiation")}>
            <span>Salvar</span>
          </Button>
        </CouponActionContent>
      </CouponBodyContent>
    </ModalBody>
  );
};

export const OtherNegotiationsModalBody = ({
  handleClose,
  complaint,
  orderDetail,
  breakInfo,
  handleBreakInfoChange,
  handleConfirm,
  selectedDate,
  handleShowCalendar,
  showCalendar,
  handleDateChange,
  selectedTimeFrom,
  handleChangeTimeFrom,
  selectedTimeTo,
  handleChangeTimeTo,
  iFoodOptions,
  handleIfoodOptionsChange,
  withOutContactObservation,
  selectedCard,
}) => {
  const startingHours = [
    { value: 7, label: "7:00" },
    { value: 8, label: "8:00" },
    { value: 9, label: "9:00" },
    { value: 10, label: "10:00" },
    { value: 11, label: "11:00" },
    { value: 12, label: "12:00" },
  ];

  const endingHours = [
    {
      value: 10,
      label: "10:00",
      disabled: selectedTimeFrom + 3 > 10,
    },
    {
      value: 11,
      label: "11:00",
      disabled: selectedTimeFrom + 3 > 11,
    },
    {
      value: 12,
      label: "12:00",
      disabled: selectedTimeFrom + 3 > 12,
    },
    {
      value: 13,
      label: "13:00",
      disabled: selectedTimeFrom + 3 > 13,
    },
    {
      value: 14,
      label: "14:00",
      disabled: selectedTimeFrom + 3 > 14,
    },
    {
      value: 15,
      label: "15:00",
      disabled: selectedTimeFrom + 3 > 15,
    },
  ];
  return (
    <ModalBody handleClose={handleClose}>
      <ModalTitle>
        <H1>Outras tratativas</H1>
      </ModalTitle>
      <OtherNegotiationContent>
        <DivOneField>
          <TextField
            label="Nome do cliente"
            variant="outlined"
            value={complaint?.clientName}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            focused={false}
          />
        </DivOneField>
        <DivTwoField>
          {complaint?.payment?.status !== "RECEIVED" &&
          complaint?.payment?.status !== "CONFIRMED" ? (
            <Select
              native
              variant="outlined"
              defaultValue={breakInfo.negotiation}
              onChange={(e) =>
                handleBreakInfoChange("negotiation", e.target.value)
              }
            >
              <option value="" selected disabled>
                Tratativa
              </option>
              <option
                disabled={!orderDetail?.urls?.bill_url}
                value={5}
              >
                Desconto no boleto
              </option>
              <option value={3}>Reposição</option>
              {selectedCard === "withoutSolution" && (
                <option value={6}>Sem contato</option>
              )}
            </Select>
          ) : (
            <Select
              native
              variant="outlined"
              defaultValue={breakInfo.negotiation}
              fullWidth
              onChange={(e) =>
                handleBreakInfoChange("negotiation", e.target.value)
              }
            >
              <option value="" selected disabled>
                Tratativa
              </option>
              <option value={2}>Reembolso</option>
              <option value={3}>Reposição</option>
              {selectedCard === "withoutSolution" && (
                <option value={6}>Sem contato</option>
              )}
            </Select>
          )}
        </DivTwoField>
        {breakInfo.negotiation === "5" && (
          <>
            <DivOneField>
              <TextField
                variant="outlined"
                value={complaint?.orderId}
                label="Nº do Pedido"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                focused={false}
              />
            </DivOneField>
            <DivOneField>
              <TextField
                variant="outlined"
                value={complaint?.complaintValue}
                label="Valor do Desconto"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                focused={false}
              />
            </DivOneField>
          </>
        )}

        {breakInfo.negotiation === "3" && (
          <RedeliveryModalContent>
            <RadioGroup
              value={breakInfo.redeliveryOption}
              fullWidth
              onChange={(e) =>
                handleBreakInfoChange("redeliveryOption", e.target.value)
              }
            >
              <FormControlLabel
                value="nextDay"
                control={<Radio color="#757575" />}
                label={`${formatDate(selectedDate)} (Dia seguinte)`}
              />
              {breakInfo?.redeliveryOption === "nextDay" && (
                <DateTimeContent>
                  <FormControlSelectTime variant="outlined">
                    <DefaultInput
                      fullWidth={false}
                      variant="outlined"
                      label="Data"
                      value={formatDate(selectedDate)}
                      format="dd/MM/yyyy"
                      disabled={true}
                      onClick={handleShowCalendar}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarTodayIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControlSelectTime>
                  {showCalendar && (
                    <CalendarContent>
                      <Calendar
                        value={selectedDate}
                        onChange={handleDateChange}
                      />
                    </CalendarContent>
                  )}

                  <FormControlSelectTime variant="outlined">
                    <SelectTime
                      label="Hora Início"
                      icon={<AccessTimeIcon />}
                      value={selectedTimeFrom}
                      onChange={handleChangeTimeFrom}
                      menuItems={startingHours}
                    />
                  </FormControlSelectTime>

                  <FormControlSelectTime variant="outlined">
                    <SelectTime
                      label="Hora Fim"
                      icon={<WatchLaterRoundedIcon />}
                      value={selectedTimeTo}
                      onChange={handleChangeTimeTo}
                      menuItems={endingHours}
                    />
                  </FormControlSelectTime>
                  {selectedTimeFrom + 3 > selectedTimeTo && (
                    <span className="msgError">
                      Encontramos um problema. Deve haver um intervalo de 3
                      horas entre a entrega inicial e a entrega final.
                    </span>
                  )}
                </DateTimeContent>
              )}

              <FormControlLabel
                value="ifood"
                control={<Radio color="#757575" />}
                label="iFood"
              />
              {breakInfo?.redeliveryOption === "ifood" && (
                <RedeliveryIfoodModalContent>
                  <FormControlSelectTime variant="outlined">
                    <NumberFormat
                      name="value"
                      customInput={TextField}
                      variant="outlined"
                      label="Valor do iFood"
                      decimalSeparator="."
                      onChange={(e) => handleIfoodOptionsChange(e)}
                      autoComplete="off"
                      isNumericString
                    />
                  </FormControlSelectTime>
                  <FormControlSelectTime variant="outlined">
                    <DefaultInput
                      variant="outlined"
                      name="trackingCode"
                      value={iFoodOptions?.trackingCode}
                      label="Código da Entrega"
                      onChange={(e) => handleIfoodOptionsChange(e)}
                    />
                  </FormControlSelectTime>
                </RedeliveryIfoodModalContent>
              )}
            </RadioGroup>
          </RedeliveryModalContent>
        )}

        {breakInfo.negotiation === "2" && (
          <>
            <DivOneField>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={breakInfo.refundOption}
                onChange={(e) =>
                  handleBreakInfoChange("refundOption", e.target.value)
                }
              >
                <FormControlLabel
                  value="pix"
                  control={<Radio color="#757575" />}
                  label="Pix"
                />
                <FormControlLabel
                  value="transferBank"
                  control={<Radio color="#757575" />}
                  label="Transferência bancária"
                />
              </RadioGroup>
            </DivOneField>
            {breakInfo.refundOption === "transferBank" && (
              <DivOneField>
                <DivTwoField>
                  <Box>
                    <TextField
                      variant="outlined"
                      label="Banco"
                      value={breakInfo.bankName}
                      onChange={(e) =>
                        handleBreakInfoChange("bankName", e.target.value)
                      }
                      fullWidth
                    />
                  </Box>
                  <Box>
                    <TextField
                      variant="outlined"
                      label="Agência"
                      value={breakInfo.bankBranch}
                      onChange={(e) =>
                        handleBreakInfoChange("bankBranch", e.target.value)
                      }
                      fullWidth
                    />
                  </Box>
                </DivTwoField>
                <DivTwoField>
                  <Box>
                    <Select
                      native
                      variant="outlined"
                      defaultValue={breakInfo.typeAccount}
                      fullWidth
                      onChange={(e) =>
                        handleBreakInfoChange("typeAccount", e.target.value)
                      }
                    >
                      <option value="" selected disabled>
                        Tipo de Conta
                      </option>
                      <option value="Corrente">Conta corrente</option>
                      <option value="Poupança">Conta poupança</option>
                    </Select>
                  </Box>
                  <Box>
                    <TextField
                      variant="outlined"
                      label="Conta"
                      value={breakInfo.bankAccount}
                      onChange={(e) =>
                        handleBreakInfoChange("bankAccount", e.target.value)
                      }
                      fullWidth
                    />
                  </Box>
                </DivTwoField>
                <DivOneField>
                  <TextField
                    variant="outlined"
                    label="CPF/CNPJ do titular"
                    value={breakInfo.bankDocument}
                    onChange={(e) =>
                      handleBreakInfoChange("bankDocument", e.target.value)
                    }
                    fullWidth
                  />
                </DivOneField>
              </DivOneField>
            )}
            {breakInfo.refundOption === "pix" && (
              <PixContent>
                <DivTwoField>
                  <Box>
                    <Select
                      native
                      variant="outlined"
                      defaultValue={breakInfo.typePix}
                      fullWidth
                      onChange={(e) =>
                        handleBreakInfoChange("typePix", e.target.value)
                      }
                    >
                      <option value="" selected disabled>
                        Chave Pix
                      </option>
                      <option value="CPF">CPF</option>
                      <option value="CNPJ">CNPJ</option>
                      <option value="E-mail">E-mail</option>
                      <option value="Celular">Celular</option>
                    </Select>
                  </Box>
                  <Box>
                    <TextField
                      variant="outlined"
                      label="Inserir chave pix"
                      value={breakInfo.keyPix}
                      onChange={(e) =>
                        handleBreakInfoChange("keyPix", e.target.value)
                      }
                      fullWidth
                    />
                  </Box>
                </DivTwoField>
                <Box>
                  <TextField
                    variant="outlined"
                    label="Favorecido"
                    value={breakInfo.favored}
                    onChange={(e) =>
                      handleBreakInfoChange("favored", e.target.value)
                    }
                    fullWidth
                  />
                </Box>
              </PixContent>
            )}
          </>
        )}
        {breakInfo.negotiation === "6" && (
          <>
            <NoContactModal>
              Não conseguiu contato?
              <AlertInfo>
                <h3>
                  <ErrorOutlineIcon /> Informe o motivo
                </h3>
              </AlertInfo>
              <DefaultInput
                inputRef={withOutContactObservation}
                label="Comentários"
                multiline
                rows={4}
                variant="outlined"
              />
            </NoContactModal>
          </>
        )}
        <OtherNegotiationActionContent>
          <Button btn="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button loading={Object.keys(orderDetail).length <= 0} disabled={Object.keys(orderDetail).length <= 0} onClick={() => handleConfirm("negotiation")}>Salvar</Button>
        </OtherNegotiationActionContent>
      </OtherNegotiationContent>
    </ModalBody>
  );
};

export const NoSolutionModal = ({
  close,
  open,
  withOutSolutionObservation,
  handleConfirm,
}) => {
  return (
    <Modal open={open}>
      <ModalBody handleClose={close}>
        <NoSolutionModalTitleContent>
          <H1>Finalizar sem tratativa</H1>
        </NoSolutionModalTitleContent>
        <NoSolutionModalContent>
          <NoSolutionModalSubTitle>
            Qual é o motivo da finalização sem tratativa?
          </NoSolutionModalSubTitle>
          <DefaultInput
            inputRef={withOutSolutionObservation}
            label="Comentários"
            multiline
            rows={4}
            variant="outlined"
          />
        </NoSolutionModalContent>
        <NoSolutionModalAction>
          <Button btn="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button onClick={() => handleConfirm("withoutSolution")}>
            Salvar
          </Button>
        </NoSolutionModalAction>
      </ModalBody>
    </Modal>
  );
};
