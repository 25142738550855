export const formatCpfCnpj = (number) => {
  number = number.replace(/\D/g, "");

  if (number.length <= 11) {
    number = number.replace(/(\d{3})(\d)/, "$1.$2");
    number = number.replace(/(\d{3})(\d)/, "$1.$2");
    number = number.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    number = number.replace(/^(\d{2})(\d)/, "$1.$2");
    number = number.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    number = number.replace(/\.(\d{3})(\d)/, ".$1/$2");
    number = number.replace(/(\d{4})(\d)/, "$1-$2");
  }

  return number;
};
