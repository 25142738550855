import Tooltip from "@material-ui/core/Tooltip";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { ButtonLink } from "../../../components/Button";

export const HeadRows = (ordSort, ordField, handleSort) => {
  return [
    {
      content: "Nº Pedido",
      className: "hId",
    },
    {
      content: "Nome",
      className: "hName",
    },
    {
      content: "CPF/CNPJ",
      className: "hCpf_CNPJ",
    },
    {
      content: (
        <>
          Data Pedido{" "}
          {ordSort === "desc" && ordField === "order_date" ? (
            <Tooltip title="Exibir mais antigos primeiro">
              <ArrowDropDownIcon
                onClick={() => handleSort("order_date", "asc")}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Exibir mais recentes primeiro">
              <ArrowDropUpIcon
                onClick={() => handleSort("order_date", "desc")}
              />
            </Tooltip>
          )}
        </>
      ),

      className: "hDataPedido",
    },
    {
      content: (
        <>
          Data Entrega{" "}
          {ordSort === "desc" && ordField === "scheduled_date" ? (
            <Tooltip title="Exibir mais antigos primeiro">
              <ArrowDropDownIcon
                onClick={() => handleSort("scheduled_date", "asc")}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Exibir mais recentes primeiro">
              <ArrowDropUpIcon
                onClick={() => handleSort("scheduled_date", "desc")}
              />
            </Tooltip>
          )}
        </>
      ),

      className: "hDataEntrega",
    },
    {
      content: "Status",
      className: "hStatus",
    },
    {
      content: "",
      className: "hAction",
    },
  ];
};

export const DataRows = (color, history) => {
  return [
    {
      className: "dId",
      content: (order) => order.id,
    },
    {
      className: "dName",
      content: (order) => order?.client?.trade_name || order?.client?.name,
    },
    {
      className: "dCpf_CNPJ",
      content: (order) => order.client.cpf_cnpj,
    },
    {
      className: "dDataPedido",
      content: (order) => order.order_date,
    },
    {
      className: "dDataEntrega",
      content: (order) => order.scheduled_date,
    },
    {
      className: "dStatus",
      content: (order) => (
        <>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px",
              backgroundColor: `${color(order.status)}`,
              paddingInline: "8px",
              height: "22px",
              width: "auto",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: 500,
              textTransform: "capitalize",
            }}
          >
            {order.status === "em_transito" ? "Em trânsito" : order.status}
          </span>
        </>
      ),
    },
    {
      className: "dAction",
      content: (order) => (
        <ButtonLink to={`${order.id}/detail`}>Ver detalhes</ButtonLink>
      ),
    },
  ];
};
