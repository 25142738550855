import React from "react";
import Button from "@material-ui/core/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import Menu from "@material-ui/core/Menu";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { makeStyles } from "@material-ui/core/styles";
import { Button as StyledButton } from "./Button";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButton-root": {
      backgroundColor: "black",
    },
  },
  row: {
    "& .MuiFormGroup-row": {
      backgroundColor: "black",
    },
  },
  list: {
    "& .MuiList-root": {
      backgroundColor: "#202020",
    },
  },
  outlined: {
    color: "#202020",
    border: "none",
  },
}));

export default function MenuPopupState({
  filtersState,
  handleChange,
  handleClear,
}) {
  const classes = useStyles();

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <>
          <Button
            variant="outlined"
            classes={{ outlined: classes.outlined }}
            {...bindTrigger(popupState)}
          >
            <FilterListIcon /> Status
          </Button>
          <Menu {...bindMenu(popupState)}>
            {Object.entries(filtersState).map(([key, value]) => {
              return (
                <FormGroup row key={key}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value}
                        onChange={handleChange}
                        name={key}
                        color="primary"
                      />
                    }
                    style={{ padding: "0px 10px" }}
                    label={key}
                  ></FormControlLabel>
                </FormGroup>
              );
            })}
            <Box style={{ padding: ".5rem" }}>
              <StyledButton fullWidth onClick={handleClear}>
                Limpar
              </StyledButton>
            </Box>
          </Menu>
        </>
      )}
    </PopupState>
  );
}
