import { ABACATE_BACKEND_URL } from "../settings";

class RouteManagerService {
  constructor() {
    this.url = ABACATE_BACKEND_URL;
  }

  async csvUploader(formData, token) {
    const url = `${this.url}/api/abacate/v1/logistic/order_route/csv`;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestedOptions = {
      method: "POST",
      headers: myHeaders,
      mode: "cors",
      body: formData,
    };
    return await fetch(url, requestedOptions);
  }

  async orderRoute(deliveryDate, order, token) {
    let url = `${this.url}/api/abacate/v1/logistic/order_route/?`;
    if (deliveryDate) {
      url += `scheduled_date=${deliveryDate}`;
    } else {
      url += `order_id=${order}`;
    }

    new URLSearchParams({ order: order });
    const body = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await fetch(url, body);
  }

  async updateRoute(row, token) {
    const url = `${this.url}/api/abacate/v1/logistic/order_route/`;

    const body = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: row.id,
        route: row.route,
      }),
    };
    return await fetch(url, body);
  }
}

const routeManagerService = new RouteManagerService();
export default routeManagerService;
