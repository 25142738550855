import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import { useCallback, useMemo } from "react";
import { memo } from "react";
import { Button } from "../../../components/Button";
import { SelectClearButtonContent, StyledSelectReason } from "./style";

export const SelectReason = memo(
  ({
    reasonList,
    items,
    handleChangeSelectedReason,
    handleClearSelectedReason,
    statusFilters,
    selectedProductBase,
  }) => {
    const filteredItems = useMemo(() => {
      return items
        ?.filter((item) =>
          statusFilters !== "all"
            ? statusFilters === "unclassified"
              ? !item?.responsibleId
              : item?.responsibleId
            : item
        )
        .filter((item) =>
          selectedProductBase?.length
            ? selectedProductBase.includes(item.productBaseId)
            : item
        );
    }, [statusFilters, items, reasonList, selectedProductBase]);

    const itemsReason = useMemo(() => {
      return filteredItems.reduce((acc, item) => {
        if (!acc?.includes(item?.complaintReason?.id)) {
          return [...acc, item?.complaintReason?.id];
        }
        return acc;
      }, []);
    }, [filteredItems]);

    const qtyReason = useCallback(
      (id) => {
        return filteredItems?.reduce(
          (acc, item) => (item.complaintReason?.id === id ? acc + 1 : acc),
          0
        );
      },
      [itemsReason]
    );

    const menuItems = useMemo(() => {
      return reasonList
        ?.filter(({ id }) => itemsReason?.includes(id))
        ?.map((reason) => {
          return (
            <MenuItem key={reason.id} value={reason.id}>
              <Checkbox
                value={reason.id}
                checked={reason.isChecked}
                onChange={() => handleClickItem(reason.id)}
              />
              <ListItemText
                primary={`${reason.name} (${qtyReason(reason.id)})`}
              />
            </MenuItem>
          );
        });
    }, [itemsReason]);

    const handleClickItem = (reasonId) => {
      !!reasonId && handleChangeSelectedReason(reasonId);
    };

    return (
      <FormControl variant="outlined">
        <InputLabel id="select-product-base">Motivo</InputLabel>
        <StyledSelectReason
          labelId="select-product-base"
          multiple
          value={reasonList?.filter(({ id }) => itemsReason?.includes(id))}
          label="Motivo"
          onClick={(e) => {
            const reasonId = e.target.value?.slice(-1)[0];
            handleClickItem(reasonId);
          }}
          renderValue={() =>
            reasonList
              .filter(({ isChecked }) => isChecked)
              .map((reason) => reason.name)
              .join(", ")
          }
        >
          {menuItems}
          <SelectClearButtonContent>
            <Button
              fullWidth
              onClick={(e) => {
                e.stopPropagation();
                handleClearSelectedReason();
              }}
            >
              Limpar
            </Button>
          </SelectClearButtonContent>
        </StyledSelectReason>
      </FormControl>
    );
  }
);
