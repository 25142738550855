import React, { useState, useEffect } from "react";
import { Box, TextField } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";

import { TitlePage } from "../../../components/titlePage";

import MenuPopupState from "../../../components/menu";
import PageLoader from "../../../components/PageLoader";

import CustomTable from "../../../components/CustomTable";

import OrderServices from "../../../services/orderServices";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { color, useStyles } from "./style";
import { DataRows, HeadRows } from "./tableRows";
import { useMemo } from "react";
import { formatCpfCnpj } from "../../../utils/formatCpfCnpj";
import { formatDate } from "../../../utils/formatDate";
import { useDebaunce } from "../../../utils/debaunce";
import { CustomLineProgress } from "../../../components/CustomLineProgress";
import { Paginate } from "../../../components/Paginate/Paginate";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { SearchFilterTypes } from "../../../types/orderTypes";

export default function OrderConsult() {
  const classes = useStyles();

  const [ordersData, setOrdersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(15);
  const [ordField, setOrdField] = useState("order_date");
  const [ordSort, setOrdSort] = useState("desc");
  const [listFilters, setListFilters] = useState([]);

  const [filtersState, setFiltersState] = useState(SearchFilterTypes);

  const history = useHistory();

  const getOrders = async () => {
    const notChanged = ordSort === "desc" && ordField === "order_date" && !listFilters.length && page === 1 && !searchText 
    try {
      if(ordersData.length === 0 || notChanged) {
        const { data } = await OrderServices.GetInitialOrders(
          page,
          searchText?.trim(),
          listFilters,
          ordField,
          ordSort
        );  

        setOrdersData(data.results);
        setPages(data?.total_count);
      } else {
        const { data } = await OrderServices.GetOrders(
          page,
          searchText?.trim(),
          listFilters,
          ordField,
          ordSort
        ); 

        
      setOrdersData(data.results);
      setPages(data?.total_count);
      }
    } catch {
      toast.error("Erro ao carregar os dados");
    } finally {
      setLoading(false);
      setLoadingSearch(false);
    }
  };

  const getDebaunce = useDebaunce({ fn: getOrders, delay: 800 });

  const formattedData = useMemo(() => {
    return ordersData.map((order) => {
      return {
        ...order,
        client: {
          ...order?.client,
          cpf_cnpj: formatCpfCnpj(order?.client?.cpf_cnpj),
        },
        order_date: formatDate(order?.order_date),
        scheduled_date: formatDate(order?.scheduled_date),
      };
    });
  }, [ordersData]);

  const handleClearFilter = () => {
    setFiltersState(SearchFilterTypes);
    setListFilters([]);
  };

  const handleChangeStatusFilter = (e) => {
    const filterName = e.target.name;
    const isChecked = e.target.checked;
    setFiltersState({ ...filtersState, [filterName]: isChecked });

    isChecked
      ? setListFilters((prevState) => [...prevState, filterName.toLowerCase()])
      : setListFilters(
          listFilters.filter((item) => item !== filterName.toLowerCase())
        );
  };

  const handleSort = (field, sort) => {
    setOrdField(field);
    setOrdSort(sort);
    setPage(1);
  };

  useEffect(() => {
    setLoadingSearch(true);
    getDebaunce();
  }, [ordSort, ordField, listFilters.length, page, searchText]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setPage(1);
    setSearchText(value);
  };

  const handleChangePage = (page) => {
    const anchor = document.querySelector("#back-to-top-anchor");
    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
      });
    }

    setPage(page);
  };

  const headRows = HeadRows(ordSort, ordField, handleSort);

  const dataRows = DataRows(color, history);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <Box id="back-to-top-anchor">
      <TitlePage>Consulta de Pedidos</TitlePage>
      <Grid
        container
        style={{ padding: "0px 0px 36px 0px", display: "flex" }}
        spacing={2}
      >
        <Grid item style={{ display: "flex", flex: 1, gap: "10px" }}>
          <TextField
            name="orderId"
            variant="outlined"
            style={{
              width: "350px",
              height: "50px",
              borderColor: "#c4c4c4",
              borderRadius: "4px",
              fontFamily: '"Roboto", sans-serif',
            }}
            size="medium"
            id="orderId"
            placeholder="O que você busca?"
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    style={{
                      color: "#C4C4C4",
                      height: "18px",
                      width: "18px",
                    }}
                  />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            onChange={handleSearch}
          />
        </Grid>
        <Grid item style={{ alignSelf: "center" }}>
          <MenuPopupState
            filtersState={filtersState}
            handleChange={(e) => handleChangeStatusFilter(e)}
            handleClear={handleClearFilter}
          />
        </Grid>
      </Grid>
      <CustomLineProgress hidden={!loadingSearch} />
      {ordersData.length > 0 ? (
        <Box style={{ overflowX: "auto" }}>
          <CustomTable
            headRows={headRows}
            dataRows={dataRows}
            data={formattedData}
            classes={classes}
          />
        </Box>
      ) : (
        <Box className={classes.msgNotFound}>
          <h1>Ops...</h1>
          <span>Nenhum resultado foi encontrado para a sua busca.</span>
          <span>Tente novamente com outros termos.</span>
        </Box>
      )}

      <Box className={classes.paginationContent}>
        {pages > 1 && (
          <Paginate
            breakLabel="..."
            nextLabel={<AiOutlineRight />}
            forcePage={page - 1}
            onPageChange={(e) => handleChangePage(e.selected + 1)}
            pageRangeDisplayed={3}
            pageCount={pages}
            previousLabel={<AiOutlineLeft />}
            renderOnZeroPageCount={null}
          />
        )}
      </Box>
    </Box>
  );
}
