import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function EditInitialsDialog({
  open,
  setOpen,
  setInitialsInfo,
  onConfirm,
  initialsInfo,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Alterar Sigla</DialogTitle>
        <DialogContent>
          <p>
            Digite a nova sigla referente ao cd{" "}
            <strong>{initialsInfo.id}</strong>
          </p>
          <TextField
            label="Sigla"
            fullWidth
            variant="outlined"
            value={initialsInfo.initials}
            onChange={(event) => {
              setInitialsInfo({
                ...initialsInfo,
                initials: event.target.value,
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ color: "#f44336" }}
          >
            Cancelar
          </Button>
          <Button
            variant="outlined"
            onClick={onConfirm}
            style={{ color: "#8BC34A" }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
