import React, { useState } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../../hooks/useAuth";
import { makeStyles, TextField } from "@material-ui/core";

import { Button } from "../../components/Button";

import "../../assets/css/custom-bootstrap.css";
import BackgroundVideo from "../../assets/videos/home-background.mp4";
import { usePopup } from "../../hooks/usePopup";
import { AuthError } from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  background: {
    width: "100%",
    position: "fixed",
    right: 0,
    bottom: 0,
    minWidth: "100vh",
    minHeight: "100vh",
    zIndex: 0,
    backgroundColor: "#000",
  },
  overlay: {
    height: "100%",
    width: "100%",
    position: "absolute",
    zIndex: 1,
    background: "black",
    opacity: 0.7,
  },
  loginBox: {
    height: "90%",
    width: "95%",
    maxHeight: "500px",
    maxWidth: "700px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    borderRadius: "3%",
    zIndex: 2,
  },
  title: {
    paddingBottom: "2%",
    textAlign: "center",
    width: "95%",
  },
  form: {
    width: "95%",
    display: "flex",
    flexDirection: "column",
  },
  formObjects: {
    width: "100%",
    paddingBottom: "3%",
  },
  formButton: {
    backgroundColor: "#88BC23",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "aquarine",
    },
  },
}));

export default function Login() {
  const classes = useStyles();
  const { addPopup } = usePopup();
  const { signIn } = useAuth();
  const history = useHistory();

  const [user, setUser] = useState({
    username: "",
    password: "",
    rememberMe: false,
  });
  const [loading, setLoading] = useState(false);

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await signIn({
        username: user.username,
        password: user.password,
      });
      history.push("/app/home");
    } catch (error) {
      if (error instanceof AuthError) {
        addPopup({
          type: "error",
          title: error?.message ?? "Credenciais inválidas!",
        });
      } else if (error instanceof TypeError) {
        addPopup({
          type: "error",
          title: "Algo deu errado. Verfique sua conexão e tente novamente!",
        });
      } else {
        addPopup({
          type: "error",
          title: "Algo deu errado. Contate o administrador!",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.overlay}></div>
      <video className={classes.background} autoPlay loop muted>
        <source src={BackgroundVideo} type="video/mp4" />
      </video>

      <div className={classes.loginBox}>
        <h3 className={classes.title}>Login</h3>
        <form onSubmit={onSubmitHandler} className={classes.form}>
          <TextField
            required
            label="Login"
            variant="outlined"
            type="text"
            name="username"
            value={user.username}
            onChange={(event) =>
              setUser({ ...user, username: event.target.value })
            }
            placeholder="Digite o login"
            className={classes.formObjects}
          />

          <TextField
            required
            label="Senha"
            variant="outlined"
            type="password"
            name="password"
            value={user.password}
            onChange={(event) =>
              setUser({ ...user, password: event.target.value })
            }
            placeholder="Digite a senha"
            className={classes.formObjects}
          />

          <Button loading={loading} type="submit">
            Entrar
          </Button>
        </form>
      </div>
    </div>
  );
}
