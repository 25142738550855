import { useTransition } from "react-spring";
import { makeStyles } from "@material-ui/core";

import { Popup } from "./popUp";

export const useStyles = makeStyles(theme => ({
  teste: {
    position: "absolute",
    right: "0",
    top: "0",
    padding: "30px",
    overflow: "hidden",
    [theme.breakpoints.between("0", "600")]: {
      width: "100%"
    }
  }
}));

export const PopupContainer = ({ messages }) => {
  const classes = useStyles();

  const transition = useTransition(messages, {
    keys: message => message.id,
    from: { right: "-120%", opacity: 0 },
    enter: { right: "0%", opacity: 1 },
    leave: { right: "-120%", opacity: 0 }
  });

  return (
    <div className={classes.teste}>
      {transition((style, popup) => (
        <Popup style={style} message={popup} />
      ))}
    </div>
  );
};