import { CardDashBoard } from "../../../components/Cards/CardDashBoard";
import { ContainerPage } from "../../../components/ContainerPage";
import { H1 } from "../../../components/Heading/Heading";
import {
  Content,
  DashBoardCardContent,
  PageTitleContent,
  PaginateContent,
  TableContainer,
} from "./styles";
import { useState } from "react";
import { OrdersRefusedTable, TableComplaintTickets } from "./tables";

import { useEffect } from "react";
import PageLoader from "../../../components/PageLoader";
import { DetailTicket } from "./modalBody";
import { useMemo } from "react";

import { AlertModal } from "../../../components/AlertModal";
import { toast } from "react-toastify";
import { LoadingBackDrop } from "../../../components/LoadingBackDrop";
import { Paginate } from "../../../components/Paginate/Paginate";
import TicketService from "../../../services/ticketService";
import { useQuery } from "../../../utils/useQuery";
import { useHistory } from "react-router-dom";

import formatFinancialData from "./formatFinancialData";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { CustomLineProgress } from "../../../components/CustomLineProgress";
import { useRef } from "react";

export const OrderFinancial = () => {
  const query = useQuery();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [openTicketModal, setOpenTicketModal] = useState(false);
  const [alertText, setAlertText] = useState("");

  const [ticketsCanceledOrders, setTicketsCanceledOrders] = useState([]);
  const [ticketsComplaint, setTicketsComplaint] = useState([]);
  const [ticketDetail, setTicketDetail] = useState({});
  const acceptQueryCard = ["pending", "canceled", "pending-tickets"];
  const queryCard = query.get("card");
  const selectedCard = acceptQueryCard.includes(queryCard)
    ? queryCard
    : acceptQueryCard[1];
  const [pageTicketComplaint, setPageTicketComplaint] = useState(1);
  const [pageTicketCanceledOrder, setPageTicketCanceledOrder] = useState(1);
  const [loadingCardData, setLoadingCardData] = useState(false);
  const firstRender = useRef(true);

  const getTickets = async () => {
    try {
      setLoading(true);
      const params = { status: "aberto", subjectId: 4 };

      const [ticketDataCanceled, ticketDataComplaint] = await Promise.all([
        TicketService.getTickets({
          ...params,
          externalCode: "#cancel",
          page: pageTicketCanceledOrder,
        }),
        TicketService.getTickets({
          ...params,
          externalCode: "#complaint",
          page: pageTicketComplaint,
        }),
      ]);

      setTicketsCanceledOrders(ticketDataCanceled.data);
      setTicketsComplaint(ticketDataComplaint.data);
    } catch {
      toast.error("Erro ao carregar os dados");
    } finally {
      setLoading(false);
      firstRender.current = false;
    }
  };

  const getCardData = () => {
    setLoadingCardData(true);
    const loadData = {
      canceled: async () => {
        try {
          const { data: ticketDataCanceled } = await TicketService.getTickets({
            status: "aberto",
            externalCode: "#cancel",
            subjectId: 4,
            page: pageTicketCanceledOrder,
          });
          setTicketsCanceledOrders(ticketDataCanceled);
        } catch (error) {
          toast.error(`Erro ao carregar os dados`);
        } finally {
          setLoadingCardData(false);
        }
      },
      "pending-tickets": async () => {
        try {
          const { data: ticketDataComplaint } = await TicketService.getTickets({
            status: "aberto",
            externalCode: "#complaint",
            subjectId: 4,
            page: pageTicketComplaint,
          });
          setTicketsComplaint(ticketDataComplaint);
        } catch (error) {
          toast.error(`Erro ao carregar os dados`);
        } finally {
          setLoadingCardData(false);
        }
      },
    }[selectedCard];

    loadData();
  };

  useEffect(() => {
    getTickets();
  }, []);

  useEffect(() => {
    !firstRender.current && getCardData();
  }, [pageTicketComplaint, pageTicketCanceledOrder]);

  const formattedCanceledOrdersTickets = useMemo(
    () => formatFinancialData.formattedCanceledOrdersTickets(ticketsCanceledOrders),

    [ticketsCanceledOrders]
  );

  const formattedComplaintTickets = useMemo(
    () => formatFinancialData.formattedComplaintTickets(ticketsComplaint),

    [ticketsComplaint]
  );

  const formattedTicket = useMemo(
    () => formatFinancialData.formattedTicket(ticketDetail),
    [ticketDetail]
  );

  const handleTicketShowDetail = async (ticketId) => {
    try {
      setSubmitLoading(true);
      const { data } = await TicketService.getTicketById({
        ticketId,
      });
      setTicketDetail(data);
      setOpenTicketModal(true);
    } catch {
      toast.error("Erro ao carregar os dados");
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleCloseTicketDetail = () => {
    setOpenTicketModal(false);
  };

  const showAlert = () => {
    setOpenAlert(true);
  };

  const closeAlert = () => {
    setOpenAlert(false);

    const updatedTickets = (ticketsToUpdate) =>
      ticketsToUpdate.map((ticket) =>
        ticket.id === ticketDetail.id
          ? { ...ticket, status: "fechado" }
          : ticket
      );

    const { results: tickets } = {
      canceled: ticketsCanceledOrders,
      "pending-tickets": ticketsComplaint,
    }[selectedCard];

    const results = updatedTickets(tickets);
    const newState = (prev) => ({ ...prev, count: prev.count - 1, results });

    const dataSetter = {
      canceled: () => setTicketsCanceledOrders(newState),
      "pending-tickets": () => setTicketsComplaint(newState),
    }[selectedCard];

    dataSetter();

    handleCloseTicketDetail();
  };

  const handleSubmitTicket = async (image) => {
    const ticketOrderId = formattedTicket.orderId;
    const ticketId = formattedTicket?.id;
    const ticketData = {
      ...ticketDetail,
      status: "fechado",
      actionJson: {
        ...ticketDetail?.actionJson,
        refund: {
          ...ticketDetail?.actionJson?.refund,
          receiptImage: image,
        },
      },
    };
    try {
      setSubmitLoading(true);
      await TicketService.updateTicket({
        ticketId,
        ticketData,
      });
      setAlertText(
        `Reembolso para o pedido #${ticketOrderId} realizado com sucesso`
      );
      showAlert();
    } catch (error) {
      toast.error(`Erro ao atualizar ticket ${error?.message}`);
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleChangePage = (page) => {
    const anchor = document.querySelector("#back-to-top-anchor");
    !!anchor && anchor.scrollIntoView({ behavior: "smooth" });

    const changePage = {
      canceled: () => setPageTicketCanceledOrder(page),
      "pending-tickets": () => setPageTicketComplaint(page),
    }[selectedCard];

    changePage();
  };

  const handleCardClick = (card) => {
    history.push(`?card=${card}`);
  };

  const renderTable = {
    [acceptQueryCard[1]]: (
      <TableContainer>
        <OrdersRefusedTable
          tickets={formattedCanceledOrdersTickets}
          handleShowDetail={handleTicketShowDetail}
        />
        <PaginateContent>
          {ticketsCanceledOrders?.pages > 1 && (
            <Paginate
              breakLabel="..."
              nextLabel={<AiOutlineRight />}
              forcePage={pageTicketCanceledOrder - 1}
              onPageChange={(e) => handleChangePage(e.selected + 1)}
              pageRangeDisplayed={3}
              pageCount={ticketsCanceledOrders.pages || 0}
              previousLabel={<AiOutlineLeft />}
              renderOnZeroPageCount={null}
            />
          )}
        </PaginateContent>
      </TableContainer>
    ),
    [acceptQueryCard[2]]: (
      <TableContainer>
        <TableComplaintTickets
          tickets={formattedComplaintTickets}
          handleShowDetail={handleTicketShowDetail}
        />
        <PaginateContent>
          {ticketsComplaint?.pages > 1 && (
            <Paginate
              breakLabel="..."
              nextLabel={<AiOutlineRight />}
              forcePage={pageTicketComplaint - 1}
              onPageChange={(e) => handleChangePage(e.selected + 1)}
              pageRangeDisplayed={3}
              pageCount={ticketsComplaint.pages || 0}
              previousLabel={<AiOutlineLeft />}
              renderOnZeroPageCount={null}
            />
          )}
        </PaginateContent>
      </TableContainer>
    ),
  }[selectedCard];

  if (loading) {
    return <PageLoader />;
  }
  return (
    <ContainerPage>
      <PageTitleContent>
        <H1>Quadro de Avisos - Financeiro</H1>
      </PageTitleContent>
      <DashBoardCardContent>
        <CardDashBoard
          qty={ticketsCanceledOrders.count || "0"}
          text="Pedidos cancelados"
          isSelected={selectedCard === acceptQueryCard[1]}
          onClick={() => handleCardClick(acceptQueryCard[1])}
        />
        <CardDashBoard
          qty={ticketsComplaint.count || "0"}
          text="Tratativas pendentes tickets"
          isSelected={selectedCard === acceptQueryCard[2]}
          onClick={() => handleCardClick(acceptQueryCard[2])}
        />
      </DashBoardCardContent>

      <Content>
        {loadingCardData && <CustomLineProgress />}
        {renderTable}
      </Content>

      <DetailTicket
        handleClose={handleCloseTicketDetail}
        ticket={formattedTicket}
        loading={submitLoading}
        handleSubmit={handleSubmitTicket}
        handleCloseTicketDetail={handleCloseTicketDetail}
        openTicketModal={openTicketModal}
        selectedCard={selectedCard}
      />

      <AlertModal handleClose={closeAlert} open={openAlert} text={alertText} />
      <LoadingBackDrop open={submitLoading} />
    </ContainerPage>
  );
};
