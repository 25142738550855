import {
  Box,
  FormControlLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { styled } from "@material-ui/styles";
import { DefaultInput } from "../../../components/Inputs/DefaultInput";
import ModalCard from "../../../components/Modals/ModalCard";

export const DivTitle = styled(Box)({
  marginTop: "2.75rem",
});

export const DivSearch = styled(Box)(({ theme }) => ({
  marginTop: "3rem",
  marginBottom: "1.5rem",
  display: "flex",
  gap: "1.5rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const SearchInput = styled(DefaultInput)(({ theme }) => ({
  width: "21.875rem",
  height: "3.125rem",
  borderColor: theme.colors.gray,
  borderRadius: "0.25rem",
  fontFamily: theme.font.Roboto,
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },

  "& svg": {
    color: theme.colors.gray,
    height: "1.125rem",
    width: "1.125rem",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

export const DivSearchDate = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
    gap: "2rem",
  },
}));

export const SelectDateFrom = styled(KeyboardDatePicker)(({ theme }) => ({
  width: "11rem",
  height: "3.125rem",
  borderColor: theme.colors.gray,

  fontFamily: theme.font.Roboto,
  "& fieldset": {
    borderTopLeftRadius: "0.25rem",
    borderBottomLeftRadius: "0.25rem",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down("xs")]: {
      borderRadius: "0.25rem",
    },
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

export const SelectDateTo = styled(KeyboardDatePicker)(({ theme }) => ({
  width: "11rem",
  height: "3.125rem",
  borderColor: theme.colors.gray,

  fontFamily: theme.font.Roboto,
  "& fieldset": {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: "0.25rem",
    borderBottomRightRadius: "0.25rem",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "0.25rem",
    },
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

export const TableContainer = styled(Box)({
  padding: "1rem .5rem",
  overflow: "hide",
  background: "#FFF",
});

export const TableContent = styled(Box)({
  background: "#FFF",
});

export const StyledTable = styled(Table)({
  minWidth: "60.4375rem",
  overflowY: "auto",
});

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  "& tr th": {
    fontFamily: theme.font.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1rem",
    color: theme.colors.primary,
    paddingInline: ".5rem",
    paddingBlock: "0 1.6875rem",
    backgroundColor: theme.colors.white,
  },

  "& .check": {
    width: "2.5rem",
  },
  "& .status": {
    width: "1.5rem",
  },
  "& .breakDate": {
    width: "4.75rem",
  },
  "& .scheduleDate": {
    width: "5rem",
  },
  "& .orderId": {
    width: "5.5rem",
  },
  "& .clientName": {
    width: "9.0625rem",
  },
  "& .sku": {
    width: "3.25rem",
  },
  "& .productName": {
    width: "7rem",
  },
  "& .qtyOriginal": {
    width: "3.36875rem",
    textAlign: "right",
  },

  "& .qtyComplaint": {
    width: "3.5rem",
    textAlign: "right",
  },

  "& .responsibleReclassification": {
    width: "6rem",
  },
  "& .responsibleName": {
    width: "7rem",
  },
  "& .photo": {
    width: "2.25rem",
  },
}));

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  "& tr td": {
    fontFamily: theme.font.Roboto,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.1875rem",
    color: theme.colors.primary,
    paddingInline: ".5rem",
    paddingBlock: "0.875rem 1.0265rem",
  },

  "& tr": {
    "&:hover": {
      "& .status, & .breakDate,& .breakDate,& .scheduleDate,& .orderId, & .clientName,& .sku,& .productName,& .qtyOriginal,& .qtyComplaint,& .responsibleReclassification,& .responsibleName":
        {
          cursor: "pointer",
          backgroundColor: theme.colors.lightAvocado,
        },
    },
  },
}));

export const StyledTableRow = styled(TableRow)({
  "& .check": {
    maxWidth: "2.5rem",
  },
  "& .status": {
    maxWidth: "1.5rem",
  },
  "& .breakDate": {
    maxWidth: "4.75rem",
  },
  "& .scheduleDate": {
    maxWidth: "5rem",
  },
  "& .orderId": {
    maxWidth: "5.5rem",
  },
  "& .clientName": {
    maxWidth: "9.0625rem",
  },
  "& .sku": {
    maxWidth: "3.25rem",
  },
  "& .productName": {
    maxWidth: "7rem",
  },
  "& .qtyOriginal": {
    maxWidth: "3.36875rem",
    textAlign: "right",
  },

  "& .qtyComplaint": {
    maxWidth: "3.5rem",
    textAlign: "right",
  },

  "& .responsibleReclassification": {
    maxWidth: "7.8rem",
    textAlign: "right",
  },
  "& .responsibleName": {
    maxWidth: "7.2  rem",
  },
  "& .photo": {
    maxWidth: "2.25rem",
  },
});

export const SeeMoreContainer = styled(Box)(({ theme }) => ({
  background: "#FFF",
  padding: "1rem",
  overflow: "auto",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    margin: "1rem",
    height: "90vh",
  },
}));

export const SeeMoreCloseButton = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  textAlign: "right",
  "& svg": {
    width: "1.25rem",
    height: "1.25rem",
    color: theme.colors.lightGray,
    cursor: "pointer",
  },
}));

export const SeeMoreTitleContent = styled(Box)({
  marginTop: ".5rem",
});

export const SeeMoreContent = styled(Box)(({ theme }) => ({
  display: "flex",
  paddingBlock: "1.5rem",
  gap: "1.5rem",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    padding: "1rem",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0",
  },
}));

export const SeeMoreInfoContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  gap: "1rem",
  maxWidth: "33rem",
  minWidth: "20rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const SeeMoreFooter = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",

  "& .createdBy": {
    textAlign: "left",
    flexGrow: 1,
  },
  "& .action": {
    textAlign: "right",
  },
});

export const SeeMoreTableHead = styled(TableHead)(({ theme }) => ({
  "& tr th": {
    fontFamily: theme.font.Roboto,
    fontStyle: "normal",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "1rem",
    color: theme.colors.primary,
    padding: ".5rem",
  },
}));

export const SeeMoreTable = styled(Table)({
  width: "100%",
  overflow: "auto",
});

export const SeeMoreTableRow = styled(TableRow)({
  "& td, th": {
    border: "none",
  },
  "& .sku": {
    maxWidth: "3.1875rem",
  },
  "& .name": {
    maxWidth: "10rem",
  },
  "& .qtyOriginal": {
    maxWidth: "6.5rem",
    textAlign: "right",
  },
  "& .qtyComplaint": {
    maxWidth: "6.3125rem",
    textAlign: "right",
  },
  "& .image": {
    maxWidth: "2.75rem",

    "& img": {
      maxWidth: "100%",
      objectFit: "contain",
      cursor: "pointer",
    },
  },
});

export const SeeMoreTableBody = styled(TableBody)(({ theme }) => ({
  "& tr td": {
    fontFamily: theme.font.Roboto,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1rem",
    color: theme.colors.primary,
    padding: "0 .5rem",
  },
}));

export const SeeMoreSelectResponsible = styled(Select)(({ theme }) => ({
  width: "10.75rem",
  height: "2.1875rem",
}));

export const ItensCard = styled(ModalCard.Card)({
  height: "8.75rem",
});
export const BreakInfoCard = styled(ModalCard.Card)({
  height: "14rem",
});

export const FilterContent = styled(Box)(({ theme }) => ({
  display: "flex",
  maxWidth: "100%",
  gap: "1.5rem",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const Filter = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "0.5rem",
  cursor: "pointer",

  "& svg": {
    width: "1.5rem",
    color: theme.colors.primary,
  },
}));

export const CheckBoxContent = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  padding: "0.5rem",
  gap: "0.5rem",
  borderRadius: 0,
});

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  "& span": {
    padding: 0,
    fontFamily: theme.font.Roboto,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1rem",
  },
}));

export const ActionFilterContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  padding: "0.5rem",
});

export const CheckAllContent = styled(Box)({
  padding: "1rem 0.5rem",
});

export const ClassifiedAllButton = styled("span")(({ theme }) => ({
  fontFamily: theme.font.Roboto,
  fontSize: "0.875rem",
  fontWeight: 400,
  lineHeight: "1.025625rem",
  color: theme.colors.link,
  cursor: "pointer",
}));

export const ResolveSelectedContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  marginTop: "0.5rem",
});

export const TitleResolveSelectedModal = styled(Box)({
  paddingInline: "0.5rem",
});

export const ResolveSelectedSolutionContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "1.5rem",
  padding: "0.5rem",
  height: "18rem",
});
export const ResolveSelectedActionContent = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  gap: "1.5rem",
  padding: "0.5rem",
});

export const FilterLabelContent = styled(Box)(({ theme }) => ({
  fontFamily: theme.font.Roboto,
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  color: theme.colors.lightGray,
}));

export const StyledSelectProductBase = styled(Select)(({ theme }) => ({
  width: "10rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const StyledSelectReason = styled(Select)(({ theme }) => ({
  width: "10rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const StyledSelectResponsible = styled(Select)(({ theme }) => ({
  width: "10rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const StyledSelectStatus = styled(Select)(({ theme }) => ({
  width: "10rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const StyledSelectOrderBy = styled(Select)(({ theme }) => ({
  width: "10rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const ClearFilterButtonContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  justifyContent: "flex-end",
}));

export const FilterTitleLabel = styled("span")(({ theme }) => ({
  fontFamily: theme.font.Roboto,
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "1",
  lineHeight: "0.875rem",
  color: theme.colors.primary,
}));

export const FilterLabel = styled("span")(({ theme }) => ({
  fontFamily: theme.font.Roboto,
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  color: theme.colors.primary,
  [theme.breakpoints.down("md")]: {
    visibility: "hidden",
    display: "none",
  },
}));

export const FilterLabelDescription = styled("span")(({ theme }) => ({
  fontFamily: theme.font.Roboto,
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  color: theme.colors.primary,
}));

export const FilterContentHeader = styled(Box)({
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  gap: "1rem",
});

export const SelectClearButtonContent = styled(Box)({
  padding: "1rem",
});

export const FilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "100%",
  gap: "1.5rem",
}));
