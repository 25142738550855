import formatCurrency from "../../../utils/formatCurrency";
import { formatDate } from "../../../utils/formatDate";

export const formattedData = (complaint) => ({
  ...complaint,
  complaintId: complaint.id,
  orderId: complaint.order_id,
  clientName: complaint.client?.trade_name || complaint.client?.name,
  createdDate: formatDate(complaint.created),
  createdTime: new Date(complaint.created).toLocaleTimeString(),
  complaintValue: formatCurrency(getComplaintValue(complaint.items)),
  updatedDate: formatDate(complaint.updated),
  updatedTime: new Date(complaint.updated).toLocaleTimeString(),
});

export const getComplaintValue = (items) => {
  return items?.reduce((acc, { complaint_value }) => acc + complaint_value, 0);
};
