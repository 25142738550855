import { Box, styled } from "@material-ui/core";

export const ResolveModalContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "34rem",
  overflow: "auto",
  paddingBlock: "0.5rem",
  paddingInline: "0.5rem",
  backgroundColor: "#FFF",

  "@media (max-width:800px)": {
    width: "95%",
    height: "95%",
  },
  
  "& .cardAlert": {
    display: "flex",
    gap: 24,

    "@media (max-width:1200px)": {
      flexDirection: "column",
    },
  },

  "& .notReturnedCard": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    padding: 8,
    width: "100%",
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 500,

    "& .major": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },

    "& p": {
      margin: 0,
      marginLeft: 18,
      color: "#E11414",
    },
  },
}));

export const CloseIconContent = styled(Box)(({ theme }) => ({
  textAlign: "end",
  "& svg": {
    color: theme.colors.lightGray,
    width: "1.25rem",
    height: "1.25rem",
    cursor: "pointer",
  },
}));

export const ResolveContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingInline: "1rem",
  gap: "1.5rem",

  "& .action": {},
}));

export const ResolveHead = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "2.75rem",
  "& span": {
    fontFamily: theme.font.Inter,
    fontSize: "1rem",
    fontWeight: 600,
    color: theme.colors.primary,
  },
}));

export const ResolveHeadIcons = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "1.125rem",
  "& svg": {
    color: theme.colors.lightGray,
    width: "1.25rem",
    height: "1.375rem",
    cursor: "pointer",
  },
}));

export const ResolveAlertInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: 8,

  "& h3": {
    fontFamily: theme.font.Roboto,
    fontSize: "0.875rem",
    fontWeight: 400,
    color: theme.colors.alertRed,
  },
}));

export const ResolveAction = styled(Box)(({ theme }) => ({
  marginBottom: "2rem",
  "& button": {
    flex: "1 1 1",
  },
  "& .action": {
    display: "flex",
    marginBottom: "0.5rem",
    gap: "1rem",
    width: "100%",
    "& button": {
      width: "100%",
    }
  },
  "& .principal-action": {
    display: "flex",
    marginBottom: "0.5rem",
    width: "100%",
    "& button": {
      width: "100%",
    }
  },
  "@media (max-width:800px)": {
    "& button": {
      width: "100%",
    },
    "& .action": {
      flexDirection: "column",
      gap: "0.5rem",
  },
}
}));
