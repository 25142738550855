import { FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { memo } from "react";
import { StyledSelectOrderBy } from "./style";

export const SelectOrderBy = memo(
  ({ selectedOrderBy, handleChangeSelectedOrderBy }) => {
    return (
      <FormControl variant="outlined">
        <InputLabel id="select-order-by">Ordenar por</InputLabel>
        <StyledSelectOrderBy
          labelId="select-order-by"
          value={selectedOrderBy}
          label="Ordenar por"
          onChange={(e) => handleChangeSelectedOrderBy(e.target.value)}
        >
          <MenuItem value="deliveryDate">Data da Entrega</MenuItem>

          <MenuItem value="orderId">Pedido</MenuItem>

          <MenuItem value="productName">Produto</MenuItem>

          <MenuItem value="complaintId">Quebra</MenuItem>

          <MenuItem value="complaintDate">Data Quebra</MenuItem>

          <MenuItem value="complaintQty">Quantidade da Quebra</MenuItem>

          <MenuItem value="clientTradeName">Nome Fantasia</MenuItem>

          <MenuItem value="responsibleName">Responsável da Quebra</MenuItem>
        </StyledSelectOrderBy>
      </FormControl>
    );
  }
);
