export const orderTypes = {
  billing_address: {
    city: "",
    client: null,
    created: "",
    id: null,
    lat: null,
    lng: null,
    neighborhood: "",
    number: "",
    state: "",
    street: "",
  },
  client: {
    cpf_cnpj: "",
    email: "",
    id: null,
    municipal_registration: "",
    name: "",
    opt_simples_nacional: false,
    phone_number_1: "",
    phone_number_2: "",
    responsible: "",
    state_registration: "",
    trade_name: "",
    type: "",
  },
  client_id: null,
  complaints: [],
  created: "",
  delivered_date: "",
  delivery_value: null,
  discount_value: null,
  id: "string",
  instructions: "",
  item: [],
  nf_paulista: "",
  order_date: "",
  payment: [],
  resources: [],
  scheduled_date: "",
  scheduled_time_from: "",
  scheduled_time_to: "",
  shipping_address: {
    city: "",
    client: null,
    complement: "",
    created: "",
    id: null,
    lat: null,
    lng: null,
    neighborhood: "",
    number: "",
    state: "",
    street: "",
    zip: "",
  },
  status: "",
  status_history: [],
  store_id: null,
  store_name: "",
  sub_total_value: null,
  total_discount_value: null,
  total_value: null,
  updated: "",
};

export const breakTypes = {
  negotiation: "",
  redeliveryOption: "",
  refundOption: "",
  typePix: "",
  favored: "",
  typeAccount: "",
  bankName: "",
  bankBranch: "",
  bankAccount: "",
  bankDocument: "",
  breakList: [],
  observation: "",
};

export const SearchFilterTypes = {
  Cancelado: false,
  Pendente: false,
  Aprovado: false,
  Trânsito: false,
  Entregue: false,
};

export const canceledTypes = {
  refund: {
    type: "",
  },
};
