import { api } from "../utils/Api/apiClient";

const login = async (username, password) => {
  const url = "/api/auth-internal/v2/token";
  return await api.post(url, {
    username,
    password,
  });
};

const refresh = async (refresh) => {
  const url = "/api/auth-internal/v2/token/refresh";
  return await api.post(url, { refresh });
};

const authenticationService = {
  login,
  refresh,
};

export default authenticationService;
