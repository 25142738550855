import { ABACATE_BACKEND_URL } from "../settings";

class DepotCityService {
  constructor() {
    this.url = ABACATE_BACKEND_URL;
  }

  async createDepot(name, external_id, initials, token) {
    let url = `${this.url}/api/abacate/v1/logistic/depot/`;

    const req = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name.toUpperCase(),
        external_id: external_id,
        initials: initials,
      }),
    };
    return await fetch(url, req);
  }

  async changeDepotInitials(id, initials, token) {
    let url = `${this.url}/api/abacate/v1/logistic/depot/${id}`;

    const req = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        initials: initials,
      }),
    };
    return await fetch(url, req);
  }

  async getDepot(token) {
    let url = `${this.url}/api/abacate/v1/logistic/depot/`;

    const req = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await fetch(url, req);
  }

  async getCity(token) {
    let url = `${this.url}/api/abacate/v1/logistic/city/`;

    const req = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await fetch(url, req);
  }

  async changePriority(id, priority, token) {
    let url = `${this.url}/api/abacate/v1/logistic/city/${id}`;

    const req = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        priority: priority,
      }),
    };
    return await fetch(url, req);
  }

  async createCity(name, state, depot, token) {
    let url = `${this.url}/api/abacate/v1/logistic/city/`;

    const req = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name.toUpperCase(),
        state: state,
        depot: depot,
      }),
    };
    return await fetch(url, req);
  }

  async deleteCity(id, token) {
    let url = `${this.url}/api/abacate/v1/logistic/city/${id}`;

    const req = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    return await fetch(url, req);
  }

  async deleteDepot(id, token) {
    let url = `${this.url}/api/abacate/v1/logistic/depot/${id}`;

    const req = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    return await fetch(url, req);
  }
}

const depotCityService = new DepotCityService();
export default depotCityService;
