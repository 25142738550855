import { IconButton, styled, Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { MdDeleteOutline } from "react-icons/md";
import { IoChevronBackSharp } from "react-icons/io5";
import { MdOutlineBorderColor } from "react-icons/md";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { MdOutlineAddComment } from "react-icons/md";
import { IoSendSharp } from "react-icons/io5";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: "2rem",
  height: "2rem",
  padding: "0.1rem",
  "&:hover": {
    backgroundColor: theme.colors.lightAvocado,
    "& svg": {
      width: "1.25rem",
      height: "1.25rem",
      color: theme.colors.darkAvocado,
      cursor: "pointer",
    },
  },
  "& svg": {
    width: "1.25rem",
    height: "1.25rem",
    color: theme.colors.lightGray,
    cursor: "pointer",
  },
}));

export const CloseIconButton = ({ onClick, rest }) => {
  return (
    <StyledIconButton onClick={onClick} {...rest}>
      <CloseIcon />
    </StyledIconButton>
  );
};

export const DeleteIconButton = ({ onClick, rest }) => {
  return (
    <StyledIconButton onClick={onClick} {...rest}>
      <MdDeleteOutline />
    </StyledIconButton>
  );
};

export const BackIconButton = ({ onClick, rest }) => {
  return (
    <StyledIconButton onClick={onClick} {...rest}>
      <IoChevronBackSharp />
    </StyledIconButton>
  );
};

export const EditIconButton = ({ onClick, rest }) => {
  return (
    <StyledIconButton onClick={onClick} {...rest}>
      <MdOutlineBorderColor />
    </StyledIconButton>
  );
};

export const PaymentIconButton = ({ onClick, rest }) => {
  return (
    <StyledIconButton
      onClick={onClick}
      {...rest}
      style={{ marginRight: "10px" }}
    >
      <RequestQuoteIcon />
    </StyledIconButton>
  );
};

export const CopyIconButton = ({ onClick, rest }) => {
  return (
    <StyledIconButton onClick={onClick} {...rest}>
      <ContentCopyIcon />
    </StyledIconButton>
  );
};

export const SortArrowUp = ({ onClick, rest }) => {
  return (
    <StyledIconButton onClick={onClick} {...rest}>
      <Tooltip
        title={"Exibindo mais antigos primeiro"}
        aria-label="Ordenação mais antigos primeiro"
      >
        <ArrowDropUpIcon />
      </Tooltip>
    </StyledIconButton>
  );
};

export const SortArrowDown = ({ onClick, rest }) => {
  return (
    <StyledIconButton onClick={onClick} {...rest}>
      <Tooltip
        title={"Exibindo mais recentes primeiro"}
        aria-label="Ordenação mais recentes primeiro"
      >
        <ArrowDropDownIcon />
      </Tooltip>
    </StyledIconButton>
  );
};

export const AddComment = ({ onClick, rest }) => {
  return (
    <StyledIconButton onClick={onClick} {...rest}>
      <Tooltip
        title={"Adicionar/editar comentário"}
        aria-label="Adicionar/editar comentário"
      >
        <MdOutlineAddComment />
      </Tooltip>
    </StyledIconButton>
  );
};

export const SendIconButton = ({ onClick, rest }) => {
  return (
    <StyledIconButton onClick={onClick} {...rest}>
      <IoSendSharp />
    </StyledIconButton>
  );
};
