import {
  Box,
  FormControl,
  styled,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ModalCard from "../../../components/Modals/ModalCard";

export const PageTitleContent = styled(Box)({
  marginTop: "5.5rem",
});

export const DashBoardCardContent = styled(Box)({
  display: "flex",
  gap: "1.5rem",
  marginTop: "5.5rem",
  flexWrap: "wrap",
  marginBottom: "1.5rem",
});

export const FilterContent = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "1rem",
  height: "1.625rem",
});

export const Filter = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "0.5rem",
  cursor: "pointer",

  "& svg": {
    width: "1.5rem",
    color: theme.colors.primary,
  },
}));

export const CheckBoxContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  paddingInline: "0.625rem",
  paddingBlock: "0.25rem",
});

export const MsgNotFoundContent = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: "6.25rem",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  "& h1": {
    fontFamily: theme.font.Inter,
    fontWeight: 600,
    fontSize: "1.375rem",
  },

  "& span": {
    fontFamily: theme.font.Roboto,
    fontWeight: 400,
    fontSize: "0.875rem",
    color: theme.colors.lightGray,
  },
}));

export const ModalContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  paddingInline: "1rem",
  gap: "1.5rem",
});

export const ModalHeading = styled(Box)(({ theme }) => ({
  fontFamily: theme.font.Inter,
  fontSize: 18,
  fontWeight: 600,
  color: theme.colors.primary,
  marginTop: "1.5rem",
}));

export const ModalBillLink = styled(Box)(({ theme }) => ({
  "& a": {
    alignItems: "center",
    fontFamily: theme.font.Roboto,
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1rem",
    color: theme.colors.link,
    cursor: "pointer",
    textDecoration: "none",

    "& svg": {
      width: "1.25rem",
      height: "1.25rem",
      color: theme.colors.darkAvocado,
      cursor: "pointer",
    },
  },
}));

export const ModalAttachment = styled(Box)(({ theme }) => ({
  "& span": {
    alignItems: "center",
    "& svg": {
      width: "1.25rem",
      height: "1.25rem",
      color: theme.colors.darkAvocado,
    },

    "& a": {
      textDecoration: "none",
      fontFamily: theme.font.Roboto,
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "1rem",
      color: theme.colors.link,
      cursor: "pointer",
    },
  },
}));

export const Content = styled(Box)({});

export const PendingTableContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
  border: `0.5px solid ${theme.colors.gray}`,
  marginTop: "1.5rem",
  paddingInline: "1.5rem",
}));

export const PendingTable = styled(Table)(({ theme }) => ({
  minWidth: 968,
  marginBottom: "1rem",

  "& .updateTitle": {
    fontFamily: theme.font.Roboto,
    fontSize: "0.875rem",
    fontWeight: 400,
    color: theme.colors.lightGray,
    paddingInline: 8,

    "& span": {
      display: "block",
    },
  },

  "& tr th": {
    fontFamily: theme.font.Roboto,
    fontSize: "0.875rem",
    fontWeight: 500,
    color: theme.colors.primary,
  },

  "& tr td": {
    fontFamily: theme.font.Roboto,
    fontSize: "1rem",
    fontWeight: 400,
    color: theme.colors.primary,
  },

  "& .titleStatus": {
    paddingInline: "0.5rem",
    width: "1rem",
  },

  "& .titleId": {
    paddingInline: "0.5rem",
    width: "5.0625rem",
  },

  "& .titleOrderId": {
    paddingInline: "0.5rem",
    width: "6.0625rem",
  },

  "& .titleDate": {
    paddingInline: "0.5rem",
    width: "7.0625rem",
  },

  "& .titleValue": {
    paddingInline: "0.5rem",
    width: "6rem",
  },

  "& .titleNegotiation": {
    paddingInline: "0.5rem",
    width: "12.375rem",
  },

  "& .titleUpdated": {
    paddingInline: "0.5rem",
    width: "8rem",
  },
  "& .titleAction": {
    paddingInline: "0.5rem",
    width: "7.9375rem",
  },

  "& .contentStatus": {
    paddingInline: "0.5rem",
    width: "1rem",
  },

  "& .contentId": {
    paddingInline: "0.5rem",
    width: "5.0625rem",
  },

  "& .contentOrderId": {
    paddingInline: "0.5rem",
    width: "6.0625rem",
  },

  "& .contentDate": {
    paddingInline: "0.5rem",
    width: "7.0625rem",

    "& span": {
      display: "block",

      "& .date": {
        fontFamily: theme.font.Roboto,
        fontSize: "1rem",
        fontWeight: 400,
        color: theme.colors.primary,
      },

      "& .time": {
        fontFamily: theme.font.Roboto,
        fontSize: "0.75rem",
        fontWeight: 400,
        color: theme.colors.primary,
      },
    },
  },

  "& .contentValue": {
    paddingInline: "0.5rem",
    width: "6rem",
  },

  "& .contentNegotiation": {
    paddingInline: "0.5rem",
    width: "12.375rem",
  },

  "& .contentUpdated": {
    paddingInline: "0.5rem",
    width: "8rem",

    "& span": {
      display: "block",

      "& .date": {
        fontFamily: theme.font.Roboto,
        fontSize: "1rem",
        fontWeight: 400,
        color: theme.colors.primary,
      },

      "& .time": {
        fontFamily: theme.font.Roboto,
        fontSize: "0.75rem",
        fontWeight: 400,
        color: theme.colors.primary,
      },
    },
  },

  "& .contentAction": {
    textAlign: "end",
    paddingInline: "0.5rem",
    width: "7.9375rem",
  },
}));

export const ItensCard = styled(ModalCard.Card)({
  height: "auto",
});

export const StyledItensTable = styled(Table)({
  width: "100%",
  overflow: "auto",
});

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  "& tr th": {
    fontFamily: theme.font.Roboto,
    fontStyle: "normal",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "1rem",
    color: theme.colors.primary,
    padding: ".5rem",
  },
}));

export const StyledTableRow = styled(TableRow)({
  "& td, th": {
    border: "none",
  },
  "& .sku": {
    maxWidth: "3.1875rem",
  },
  "& .name": {
    maxWidth: "10rem",
  },
  "& .qtyComplaint": {
    maxWidth: "6.3125rem",
    textAlign: "right",
  },
});

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  "& tr td": {
    fontFamily: theme.font.Roboto,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1rem",
    color: theme.colors.primary,
    padding: ".5rem",
  },
}));

export const ExpectedTableContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
  border: `0.5px solid ${theme.colors.gray}`,
  paddingInline: "1.25rem",
}));

export const ExpectedTable = styled(Table)(({ theme }) => ({
  minWidth: 968,
  marginBottom: "1rem",
}));

export const ExpectedTableHead = styled(TableHead)(({ theme }) => ({
  "& tr th": {
    fontFamily: theme.font.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1rem",
    color: theme.colors.primary,
    paddingInline: ".5rem",
    paddingBlock: "1.5rem 2rem",
  },
}));

export const ExpectedTableBody = styled(TableBody)(({ theme }) => ({
  "& tr td": {
    fontFamily: theme.font.Roboto,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.1875rem",
    color: theme.colors.primary,
    paddingInline: ".5rem",
    paddingBlock: "1.0625rem 1rem",
  },
}));

export const ExpectedTableRow = styled(TableRow)(({ theme }) => ({
  "& .status": {
    width: "2.5rem",
  },
  "& .complaintId": {
    width: "5.0625rem",
  },
  "& .orderId": {
    width: "6.0625rem",
  },
  "& .clientName": {
    width: "17.5rem",
  },
  "& .complaintDate": {
    width: "7.0625rem",
    "& span": {
      display: "block",
      fontFamily: theme.font.Roboto,
      fontWeight: 400,
      color: theme.font.primary,

      "& .date": {
        fontSize: "1rem",
      },

      "& .time": {
        fontSize: "0.75rem",
      },
    },
  },
  "& .value": {
    width: "6rem",
  },
  "& .updated": {
    width: "8.5rem",
    "& span": {
      display: "block",
      fontFamily: theme.font.Roboto,
      fontWeight: 400,
      color: theme.font.primary,

      "& .date": {
        fontSize: "1rem",
      },

      "& .time": {
        fontSize: "0.75rem",
      },
    },
  },
  "& .action": {
    textAlign: "end",
    width: "8.325rem",
  },
}));

export const ResolveModalContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "34rem",
  overflow: "auto",
  paddingBlock: "0.5rem",
  paddingInline: "0.5rem",
  backgroundColor: "#FFF",

  "@media (max-width:800px)": {
    width: "95%",
    height: "95%",
  },

  "& .closeIcon": {
    textAlign: "end",
    "& svg": {
      color: "#757575",
      width: 20,
      height: 20,
      cursor: "pointer",
    },
  },

  "& .content": {
    display: "flex",
    flexDirection: "column",
    paddingInline: "1rem",
    gap: "1.5rem",

    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "2.75rem",
      "& span": {
        fontFamily: "Inter",
        fontSize: 16,
        fontWeight: 600,
        color: "#353535",
      },

      "& .icons": {
        display: "flex",
        gap: "1.125rem",
        "& svg": {
          color: "#757575",
          width: 20,
          height: 22,
          cursor: "pointer",
        },
      },
    },

    "& .alertInfo": {
      display: "flex",
      flexDirection: "column",
      padding: 8,

      "& h3": {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 400,
        color: "#E11414",
      },
    },

    "& .action": {
      display: "flex",
      flexGrow: 1,
      marginBottom: "2rem",
      justifyContent: "flex-end",
      gap: "1.5rem",
      "@media (max-width:800px)": {
        flexDirection: "column",
        gap: "0.5rem",

        "& button": {
          width: "100%",
        },
      },
    },
  },
}));

export const CouponBodyContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  padding: "2rem 1rem",
});

export const ModalTitle = styled(Box)({
  paddingInline: "1.5rem",
});

export const DivOneField = styled(Box)({});

export const DivTwoField = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",
  gap: "1rem",
  marginBottom: "1rem",
});

export const CouponActionContent = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  gap: "1.5rem",
  marginTop: "1.5rem",
});

export const OtherNegotiationActionContent = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  gap: "1.5rem",
  marginTop: "1rem",
});

export const DateTimeContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",

  "& .msgError": {
    marginTop: 8,
    fontFamily: "Roboto",
    fontSize: 11,
    fontWeight: 400,
    color: "#E11414",
    width: 163,
  },
});

export const CalendarContent = styled(Box)({
  width: "3.125rem",
});

export const FormControlSelectTime = styled(FormControl)(({ theme }) => ({
  width: "15.0625rem",
  "& > *": {
    fontFamily: theme.font.Roboto,
    fontSize: "0.875rem",
    fontWeight: 400,

    "& svg": {
      color: theme.colors.gray,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "7.5rem",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    backgroundColor: "black",
  },
}));

export const OtherNegotiationContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  padding: "2rem 1rem",
});

export const RedeliveryModalContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: ".5rem",
});

export const RedeliveryIfoodModalContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
});

export const NoSolutionModalTitleContent = styled(Box)({
  paddingInline: "1rem",
});

export const NoSolutionModalContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  paddingInline: "1rem",
  marginTop: "1.5rem",
});

export const NoSolutionModalSubTitle = styled(Box)(({ theme }) => ({
  fontFamily: theme.font.Roboto,
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "1rem",
  lineHeight: "1.1875rem",
}));

export const NoSolutionModalAction = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  paddingInline: "1rem",
  gap: "1.5rem",
  marginBlock: "1.5rem",
});

export const PaginateContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingBlock: "1rem",
});

export const WithoutSolutionTableContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
  border: `0.5px solid ${theme.colors.gray}`,
  paddingInline: "1.25rem",
}));

export const StyledWithoutSolutionTable = styled(Table)(({ theme }) => ({
  minWidth: 968,
  marginBottom: "1rem",
}));

export const WithoutSolutionTableHead = styled(TableHead)(({ theme }) => ({
  "& tr th": {
    fontFamily: theme.font.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1rem",
    color: theme.colors.primary,
    paddingInline: ".5rem",
    paddingBlock: "1.5rem 2rem",
  },
}));

export const WithoutSolutionTableBody = styled(TableBody)(({ theme }) => ({
  "& tr td": {
    fontFamily: theme.font.Roboto,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.1875rem",
    color: theme.colors.primary,
    paddingInline: ".5rem",
    paddingBlock: "1.0625rem 1rem",
  },
}));

export const WithoutSolutionTableRow = styled(TableRow)(({ theme }) => ({
  "& .status": {
    width: "2.5rem",
  },
  "& .complaintId": {
    width: "6.0625rem",
  },
  "& .orderId": {
    width: "6.0625rem",
  },
  "& .clientName": {
    width: "14.5rem",
  },
  "& .origin": {
    width: "5rem",
  },
  "& .complaintDate": {
    width: "7.0625rem",
    "& span": {
      display: "block",
      fontFamily: theme.font.Roboto,
      fontWeight: 400,
      color: theme.font.primary,

      "& .date": {
        fontSize: "1rem",
      },

      "& .time": {
        fontSize: "0.75rem",
      },
    },
  },
  "& .value": {
    width: "6rem",
  },
  "& .updated": {
    width: "7.5rem",
    "& span": {
      display: "block",
      fontFamily: theme.font.Roboto,
      fontWeight: 400,
      color: theme.font.primary,

      "& .date": {
        fontSize: "1rem",
      },

      "& .time": {
        fontSize: "0.75rem",
      },
    },
  },
  "& .action": {
    textAlign: "end",
    width: "8.325rem",
  },
}));

export const AlertInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  "& h3": {
    fontFamily: theme.font.Roboto,
    fontStyle: "normal",
    fontSize: "0.875rem",
    fontWeight: 400,
    color: "#E11414",
    lineHeight: "1rem",
  },
}));

export const NoContactModal = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  fontFamily: theme.font.Inter,
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "1.125rem",
  lineHeight: "1.375rem",
}));

export const PixContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
}));
