export default function setInitialDate() {
  let currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  let timeNow = new Date().getHours();
  let day = currentDate.getDate();
  let month = currentDate.getMonth() + 1;
  let year = currentDate.getFullYear();
  month = String(month).padStart(2, "0");
  day = String(day).padStart(2, "0");

  if (timeNow < 12) {
    day = String(day - 1).padStart(2, "0");
  }

  let tomorrow = year + "-" + month + "-" + day;

  return tomorrow;
}
