import { Link } from "react-router-dom";
import {
  Button as MuiButton,
  CircularProgress,
  styled,
} from "@material-ui/core";

export const ButtonLink = styled(Link)(({ disabled }) => ({
  backgroundColor: disabled ? "#c4c4c4" : "#DBE4C7",
  fontFamily: "Roboto",
  fontSize: "0.875rem",
  fontWeight: 500,
  paddingInline: "1rem",
  paddingBlock: "0.5rem",
  borderRadius: ".375rem",
  color: disabled ? "#393939" : "#6E8247",
  textDecoration: "none",
  letterSpacing: 0,
  lineHeight: "1rem",
  minHeight: "2rem",
  "&:hover": {
    textDecoration: "none",
    color: "#6E8247",
  },
  pointerEvents: disabled ? "none" : "auto",
}));

const StyledButton = styled(MuiButton)(({ loading, btn }) => ({
  backgroundColor: btn === "secondary" ? "#DBE4C7" : "#89BD23",
  fontFamily: "Roboto",
  fontSize: "0.875rem",
  fontWeight: 500,
  borderRadius: ".375rem",
  color: btn === "secondary" ? "#6E8247" : "#FAFAFA",
  textTransform: "none",
  letterSpacing: 0,
  lineHeight: "1rem",
  paddingInline: "1rem",
  minHeight: "2rem",
  "& .MuiCircularProgress-root": {
    maxWidth: "1.875rem",
    maxHeight: "1.875rem",
    position: "absolute",
    color: "#FFF",
  },
  "& .content": {
    fontFamily: "Roboto",
    fontSize: "0.875rem",
    fontWeight: 500,
    opacity: loading ? 0 : 1,
  },
  "&:hover": {
    backgroundColor: btn === "secondary" ? "#DBE4C7" : "#89BD23",
  },

  "&:disabled": {
    backgroundColor: btn === "secondary" ? "#C4C4C4" : "#C4C4C4",
    color: btn === "secondary" ? "#353535" : "#353535",
    padding: "0.5rem 1rem",
  },
}));

export const Button = ({ children, loading, btn, ...rest }) => {
  return (
    <StyledButton
      loading={loading}
      btn={btn?.toLowerCase()}
      disabled={loading}
      {...rest}
    >
      {loading && <CircularProgress />}{" "}
      <span className="content">{children}</span>
    </StyledButton>
  );
};
