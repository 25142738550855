import * as React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const statusList = ["Entregar", "Pendente", "Cancelado"];

export default function StatusSelect(props) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    props.handleChangeStatusFilter(value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-name-label">Filtrar por</InputLabel>
        <Select
          id="filter"
          disabled={props.disabled}
          value={props.statusFilter}
          onChange={handleChange}
          MenuProps={MenuProps}
        >
          {statusList.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
