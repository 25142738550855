import {
  Box,
  styled,
} from "@material-ui/core";

export const WalletBodyContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  padding: "2rem 1rem",
});

export const ModalTitle = styled(Box)({
  paddingInline: "1.5rem",
});

export const DivOneField = styled(Box)({});

export const DivTwoField = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",
  gap: "1rem",
  marginBottom: "1rem",
});

export const WalletActionContent = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  gap: "1.5rem",
  marginTop: "1.5rem",
});