import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";

import orderGroupService from "../../../services/orderGroupService.js";
import setInitialDate from "../../../utils/date";
import StatusSelect from "./select";
import { TitlePage } from "../../../components/titlePage.js";
import CustomDataGrid from "../../../components/dataGrid.js";
import { formatOrderGroupItems } from "./formatOrderGroupData.js";
import { toast } from "react-toastify";
import { PaginateContent } from "./style.js";
import { showPaginate } from "../../../utils/showPaginate.js";
import { Paginate } from "../../../components/Paginate/Paginate.js";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 5,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  downloadButton: {
    backgroundColor: "#88BC23",
    color: "#fff",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#8bc34a",
    color: "white",
  },
}));

const columns = [
  { field: "id", headerName: "", width: 60 },
  { field: "tipo_cliente", headerName: "Tipo", width: 110 },
  { field: "id_item", headerName: "ID", width: 110 },
  { field: "created", headerName: "Criado em", width: 170 },
  { field: "status", headerName: "Status", width: 120 },
  { field: "cpf_cnpj", headerName: "Documento", width: 155 },
  { field: "trade_name", headerName: "Nome de Negócio", width: 200 },
  { field: "dd_name", headerName: "DD Nome", width: 200 },
  { field: "name", headerName: "Nome", width: 200 },
  { field: "external_id", headerName: "ID Externo", width: 150 },
  { field: "item_sku", headerName: "SKU", width: 110 },
  { field: "description", headerName: "Descrição", width: 300 },
  { field: "quantity", headerName: "Qtd", width: 100 },
  { field: "address", headerName: "Endereço", width: 300 },
  { field: "neighborhood", headerName: "Bairro", width: 160 },
  { field: "postal_code", headerName: "CEP", width: 110 },
  { field: "delivery_date", headerName: "Data de Entrega", width: 200 },
  { field: "separation_order", headerName: "Separação", width: 150 },
  { field: "region", headerName: "Região", width: 160 },
  { field: "route", headerName: "Rota", width: 120 },
  { field: "weight_kilo", headerName: "Peso unidade", width: 165 },
  { field: "item_weight_sum", headerName: "Peso item", width: 145 },
  { field: "order_weight_sum", headerName: "Peso pedido", width: 155 },
];

export default function OrderGroup() {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState(setInitialDate());
  const [statusFilter, setStatusFilter] = useState("Entregar");
  const [rows, setRows] = useState([]);
  const [doc, setDoc] = useState("");
  const [orderId, setOrderId] = useState("");
  const [isConsulting, setIsConsulting] = useState(true);
  const [orderField, setOrderField] = useState(false);
  const [docField, setDocField] = useState(false);
  const [dateField, setDateField] = useState(true);
  const [filterField, setFilterField] = useState(false);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const isPaginate = showPaginate(pages, totalCount);

  useEffect(() => {
    changeData(deliveryDate);
  }, [deliveryDate, statusFilter, page]);

  const changeData = async (deliveryDate) => {
    setLoading(true);
    try {
      setDocField(true);
      setOrderField(true);
      setDateField(true);
      setIsConsulting(true);

      const { data } = await orderGroupService.getOrderItems({
        scheduled_date: deliveryDate,
        status: statusFilter,
        client_doc: doc,
        order_id: orderId,
        page,
      });
      setRows(() => formatOrderGroupItems(data.results));
      setPages(data.pages);
      setTotalCount(data.count);
    } catch (error) {
      toast.error(`Operação não realizada ${error}`);
    } finally {
      const hasOrderId = !!orderId;
      const hasDoc = !!doc;

      setOrderField(hasDoc);
      setFilterField(hasOrderId);
      setDocField(hasOrderId);
      setDateField(hasOrderId);

      setLoading(false);
    }
  };

  const downloadXlsx = async () => {
    setLoading(true);
    try {
      const { data } = await orderGroupService.getOrderItems({
        client_doc: doc,
        order_id: orderId,
        scheduled_date: deliveryDate,
        status: statusFilter,
        export_file: "xlsx",
      });

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `items_pedidos_${new Date().toISOString().replaceAll(":", "-")}.xlsx`
      );

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
    } catch (error) {
      toast.error(`Operação não realizada ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const date = e.target.value;
    setDeliveryDate(date);
    setPage(1);
  };

  const changeDocField = (e) => {
    const docValue = e.target.value?.trim();
    const isSearchDoc = !!docValue && !orderId;

    setDoc(isSearchDoc ? docValue : "");
    setIsConsulting(!isSearchDoc);
    setOrderField(isSearchDoc);
  };

  const changeOrderField = (e) => {
    const orderIdValue = e.target.value?.trim();
    const isSearchOrder = !!orderIdValue && !doc;

    setOrderId(isSearchOrder ? orderIdValue : "");
    setIsConsulting(!isSearchOrder);
    setDocField(isSearchOrder);
    setFilterField(isSearchOrder);
    setDateField(isSearchOrder);
  };

  const handleChangeStatusFilter = (status) => {
    setStatusFilter(status);
    setPage(1);
  };

  const handleConsult = () => {
    page > 1 ? setPage(1) : changeData(deliveryDate);
  };

  const handleChangePage = (page) => {
    const anchor = document.querySelector("#back-to-top-anchor");
    anchor.scrollIntoView({ behavior: "smooth" });
    setPage(page);
  };

  return (
    <div id="back-to-top-anchor">
      <TitlePage>Itens de Pedidos</TitlePage>
      <p>Algumas dicas para utilização do sistema:</p>
      <ul>
        <li>
          Inicialmente, a busca é feita por <strong>Data de Entrega</strong> e{" "}
          <strong>"Entregar" como Filtro de Status do Pedido</strong>
        </li>
        <li>
          Ao utilizar o campo <strong>Número do Pedido</strong> todos os outros
          filtros serão desabilitados
        </li>
        <li>
          Ao utilizar o campo <strong>Documento do Cliente</strong> os filtros
          de <strong>Data</strong> e <strong>Status do Pedido</strong> serão
          obrigatóriamente utilizados na consulta, atente-se para a condição dos
          mesmos
        </li>
        <li>
          Ao utilizar o campo <strong>Data de entrega</strong> ou{" "}
          <strong>Filtro de Status do Pedido</strong>, a pesquisa é feita
          automaticamente, então se deseja utilizar o campo{" "}
          <strong>Documento do Cliente</strong>, é recomendável preenchê-lo
          primeiro
        </li>
        <li>
          Não é possível pesquisar por <strong>Documento do Cliente</strong> e{" "}
          <strong>Número do Pedido</strong> ao mesmo tempo
        </li>
        <li>
          O botão consultar só será habilitado se algum dos campos:{" "}
          <strong>Documento do Cliente</strong> ou{" "}
          <strong>Número do Pedido</strong> estiverem preenchidos
        </li>
      </ul>

      <form className={classes.container} noValidate>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <TextField
              id="date"
              label="Data de Entrega"
              type="date"
              disabled={dateField}
              value={deliveryDate}
              onChange={handleChange}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <StatusSelect
              disabled={filterField}
              className={classes.commands}
              statusFilter={statusFilter}
              handleChangeStatusFilter={handleChangeStatusFilter}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={loading || rows.length === 0}
              variant="contained"
              endIcon={<GetAppIcon />}
              className={classes.downloadButton}
              onClick={downloadXlsx}
            >
              Download
            </Button>
          </Grid>
        </Grid>
      </form>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ padding: 6 }}
        spacing={2}
      >
        <Grid item>
          <TextField
            name="orderId"
            variant="outlined"
            required
            style={{ width: 105, marginTop: 8 }}
            size="small"
            id="orderId"
            label="nº Pedido"
            autoFocus
            disabled={orderField}
            value={orderId}
            autoComplete="off"
            onChange={(event) => changeOrderField(event)}
          />
          <TextField
            name="orderId"
            variant="outlined"
            required
            style={{ width: 200, marginTop: 8, marginLeft: 5 }}
            size="small"
            id="orderId"
            label="doc. Cliente"
            autoFocus
            disabled={docField}
            value={doc}
            autoComplete="off"
            onChange={(event) => changeDocField(event)}
          />
          <Button
            onClick={handleConsult}
            variant="contained"
            style={{ marginTop: 10 }}
            className={classes.button}
            type="submit"
            disabled={isConsulting}
          >
            Consultar
          </Button>
        </Grid>
      </Grid>
      <div style={{ height: 480, width: "100%", marginTop: 5 }}>
        <CustomDataGrid
          rows={rows}
          columns={columns}
          loading={loading}
          showPaginate={false}
        />
        <PaginateContent>
          {isPaginate && !loading && (
            <Paginate
              breakLabel="..."
              nextLabel={<AiOutlineRight />}
              forcePage={page - 1}
              onPageChange={(e) => handleChangePage(e.selected + 1)}
              pageRangeDisplayed={3}
              pageCount={pages}
              previousLabel={<AiOutlineLeft />}
              renderOnZeroPageCount={null}
            />
          )}
        </PaginateContent>
      </div>
    </div>
  );
}
