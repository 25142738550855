import {
  Box,
  Checkbox,
  InputAdornment,
  Select,
  TableCell,
  TextField,
  Tooltip,
} from "@material-ui/core";
import formatCurrency from "../../../utils/formatCurrency";
import { BsPaperclip } from "react-icons/bs";
import { MdOutlineAddComment } from "react-icons/md";

import {
  BreakTableContent,
  BreakTableProductContent,
  BreakTableQtyContent,
  StyledBreakTable,
  StyledBreakTableFooterContainer,
  StyledTableBody,
  StyledTableHead,
  StyledTableRow,
} from "./style";

import { Button } from "../../../components/Button";
import { useRef, useState } from "react";
import { PopOverComment } from "./PopOverComment";
import { DefaultInput } from "../../../components/Inputs/DefaultInput";
import { useDebaunce } from "../../../utils/debaunce";
import { TooltipIcon } from "./tables.styled";
export const BreakTableFooter = ({
  complaintValue,
  breakInfo,
  handleBreakInfoChange,
  handleSave,
  loading,
  selectedOriginReport,
  handleChangeOriginReport,
  originReportList,
}) => {
  return (
    <StyledBreakTableFooterContainer>
      <Box className="information">
        <Box>
          <span className="total">
            <span className="totalText">Valor a ser devolvido:</span>
            <span className="totalValue">{formatCurrency(complaintValue)}</span>
          </span>
        </Box>
        <Box className="observation">
          <Box className="selectReportedBy">
            <Select
              native
              variant="outlined"
              fullWidth
              value={selectedOriginReport}
              onChange={handleChangeOriginReport}
            >
              <option value="" selected disabled>
                Origem do contato
              </option>
              {originReportList?.map((originReport) => (
                <option key={originReport.id} value={originReport.id}>
                  {originReport.name}
                </option>
              ))}
            </Select>
          </Box>

          <TextField
            classes={{ root: "inputObservation" }}
            label="Comentários"
            value={breakInfo.observation}
            onChange={(e) =>
              handleBreakInfoChange("observation", 0, e.target.value)
            }
            multiline
            rows={4}
            variant="outlined"
          />
        </Box>
      </Box>
      <Box className="actions">
        <Button
          disabled={complaintValue <= 0 || loading}
          onClick={handleSave}
          loading={loading}
        >
          Salvar
        </Button>
      </Box>
    </StyledBreakTableFooterContainer>
  );
};

export const BreakTable = ({
  items,
  handleCheck,
  breakList,
  handleBreakInfoChange,
  reasonList,
  searchText,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popOverContentInfo, setPopOverContentInfo] = useState({
    inputValue: null,
    itemSku: null,
  });
  const [openPopOver, setOpenPopOver] = useState(false);
  const [idPopOver, setIdPopOver] = useState(null);
  const observationRef = useRef();

  const handleClick = (event, value, sku) => {
    setAnchorEl(event.currentTarget);
    setPopOverContentInfo({ inputValue: value, itemSku: sku });
    setIdPopOver(sku);
    setOpenPopOver(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopOverContentInfo({
      inputValue: null,
      itemSku: null,
    });
    setOpenPopOver(false);
  };

  const handleChangeItemObservation = () => {
    const sku = popOverContentInfo.itemSku;
    const text = observationRef.current?.value;
    handleBreakInfoChange("itemObservation", sku, text);
  };

  const debounceChangeItemObservation = useDebaunce({
    fn: handleChangeItemObservation,
    delay: 700,
  });

  return (
    <BreakTableContent>
      <PopOverComment
        anchorEl={anchorEl}
        content={
          <DefaultInput
            variant="outlined"
            inputRef={observationRef}
            multiline
            fullWidth
            rows={2}
            defaultValue={popOverContentInfo.inputValue}
            onChange={debounceChangeItemObservation}
          />
        }
        handleClose={handleClose}
        id={idPopOver}
        open={openPopOver}
        handleSave={handleClose}
      />

      <StyledBreakTable>
        <StyledTableHead>
          <StyledTableRow>
            <TableCell className="checkBox"></TableCell>
            <TableCell className="sku">SKU</TableCell>
            <TableCell className="itemName">Item</TableCell>
            <TableCell className="qty">Quant itens</TableCell>
            <TableCell className="value">Valor</TableCell>
            <TableCell className="qtyComplaint">Quant Faltante</TableCell>
            <TableCell className="qtyTotal">Total do Pedido</TableCell>
            <TableCell className="reason">Motivo</TableCell>
            <TableCell className="reason">Devolvido</TableCell>
            <TableCell className="obs">Obs</TableCell>
            <TableCell className="photo">Foto</TableCell>
          </StyledTableRow>
        </StyledTableHead>

        <StyledTableBody>
          {items
            .filter(
              (item) =>
                item.sku.toLowerCase().includes(searchText) ||
                item.productName.toLowerCase().includes(searchText)
            )
            .map((item, index) => {
              const breakItem = breakList?.find(({ sku }) => sku === item.sku);
              return (
                <StyledTableRow key={index}>
                  <TableCell className="checkBox">
                    <Checkbox
                      id={item.sku}
                      checked={item?.isChecked || false}
                      onChange={(e) => handleCheck(e)}
                    />
                  </TableCell>
                  <TableCell className="sku">{item.sku}</TableCell>
                  <TableCell className="itemName">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <BreakTableProductContent>
                        <Box>{item.productName}</Box>
                        <Box className="portionName">{item.portionName}</Box>
                      </BreakTableProductContent>
                      {item.itemWeightInfo ? (
                        <Tooltip title={item.itemWeightInfo}>
                          <TooltipIcon />
                        </Tooltip>
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell className="qty">{item.quantity}</TableCell>
                  <TableCell className="value">
                    {item.totalItensValue}
                  </TableCell>
                  <TableCell className="qtyComplaint">
                    {item?.isChecked && (
                      <TextField
                        variant="outlined"
                        type="number"
                        value={breakItem?.quantity_complaint || 0}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            step: item.portionType === "Kg" ? 0.001 : 1,
                            style: { padding: "0.5rem" },
                          },
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ padding: "0", margin: "0" }}
                            >
                              {item.portionType}
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) =>
                          handleBreakInfoChange(
                            "quantity_complaint",
                            item.sku,
                            e.target.value
                          )
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell className="qtyTotal">
                    {item.availableQuantity < (item.receivedQuantity ?? item.totalQuantity) ? (
                      <BreakTableQtyContent>
                        <Box className="originalQty">{`${item.totalQuantity} ${item.portionType}`}</Box>
                        <Box>{`${item.availableQuantity} ${item.portionType}`}</Box>
                      </BreakTableQtyContent>
                    ) : (
                      `${String(item.receivedQuantity ?? item.totalQuantity).replace('.', ',')} ${item.portionType}`
                    )}
                  </TableCell>
                  <TableCell className="reason">
                    {item?.isChecked && (
                      <Select
                        native
                        variant="outlined"
                        value={breakItem?.reason || null}
                        onChange={(e) =>
                          handleBreakInfoChange(
                            "reason_id",
                            item.sku,
                            e.target.value
                          )
                        }
                      >
                        <option value="" selected disabled>
                          Motivo
                        </option>
                        {reasonList?.map(({ id, name }) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </Select>
                    )}
                  </TableCell>
                  <TableCell className="returned">
                  {item?.isChecked && (
                      <Checkbox
                        id={`${item.sku}#returned`}
                        checked={item?.wasReturned || false}
                        onChange={(e) =>
                          handleBreakInfoChange(
                            "quantity_returned",
                            item.sku,
                            e.target.checked
                          )
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell className="obs">
                    {item?.isChecked && (
                      <MdOutlineAddComment
                        color={breakItem?.observation?.trim() && "#6E8247"}
                        onClick={(e) =>
                          handleClick(e, breakItem?.observation, item?.sku)
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell className="photo">
                    {item?.isChecked && (
                      <>
                        <label htmlFor={`fileUpload${item.sku}`}>
                          {breakItem?.item_image ? (
                            <img
                              className="complaintImg"
                              src={breakItem?.item_image}
                              alt="Produto"
                            />
                          ) : (
                            <BsPaperclip />
                          )}

                          <input
                            type="file"
                            multiple={false}
                            accept="image/*"
                            id={`fileUpload${item.sku}`}
                            style={{ display: "none" }}
                            onChange={(e) =>
                              e.target.files.length &&
                              handleBreakInfoChange(
                                "item_image",
                                item.sku,
                                e.target.files
                              )
                            }
                          />
                        </label>
                      </>
                    )}
                  </TableCell>
                </StyledTableRow>
              );
            })}
        </StyledTableBody>
      </StyledBreakTable>
    </BreakTableContent>
  );
};
