import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useMemo } from "react";
import { memo } from "react";
import { StyledSelectStatus } from "./style";

export const SelectStatus = memo(
  ({ items, statusFilters, handleChangeStatusFilters }) => {
    const qtyClassified = useMemo(
      () =>
        items?.reduce((acc, item) => (item.responsibleId ? acc + 1 : acc), 0),
      [items]
    );

    const qtyUnClassified = useMemo(
      () =>
        items?.reduce((acc, item) => (!item.responsibleId ? acc + 1 : acc), 0),
      [items]
    );

    const selectValue = [
      {
        name: "Todos",
        value: "all",
      },
      {
        name: "Classificados",
        value: "classified",
      },
      {
        name: "Não classificados",
        value: "unclassified",
      },
    ];

    return (
      <FormControl variant="outlined">
        <InputLabel id="select-product-base">Status</InputLabel>
        <StyledSelectStatus
          labelId="select-product-base"
          multiple
          value={selectValue}
          label="Status"
          renderValue={() =>
            selectValue.find(({ value }) => value === statusFilters)?.name
          }
        >
          <Box style={{ padding: "1rem" }}>
            <RadioGroup
              defaultValue="all"
              name="radio-buttons-status"
              value={statusFilters}
              onChange={handleChangeStatusFilters}
            >
              <FormControlLabel
                value="all"
                control={<Radio color="#757575" />}
                label={`Todos (${items?.length || 0})`}
              />

              <FormControlLabel
                value="classified"
                control={<Radio color="#757575" />}
                disabled={!qtyClassified}
                label={`Classificados (${qtyClassified})`}
              />
              <FormControlLabel
                value="unclassified"
                control={<Radio color="#757575" />}
                disabled={!qtyUnClassified}
                label={`Não classificados (${qtyUnClassified})`}
              />
            </RadioGroup>
          </Box>
        </StyledSelectStatus>
      </FormControl>
    );
  }
);
