import axios from "axios";
import { ABACATE_BACKEND_URL } from "../../settings";

export function setupApiClient() {
  const api = axios.create({
    baseURL: ABACATE_BACKEND_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return api;
}
