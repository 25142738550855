import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";

import setInitialDate from "../../../utils/date";
import { usePopup } from "../../../hooks/usePopup";
import { TitlePage } from "../../../components/titlePage";
import CustomDataGrid from "../../../components/dataGrid";
import { Button } from "../../../components/Button";

import billsAndInvoicesService from "../../../services/billsAndInvoicesService";
import { useAuth } from "../../../hooks/useAuth";
import { showPaginate } from "../../../utils/showPaginate";
import { PaginateContent } from "./styles";
import { Paginate } from "../../../components/Paginate/Paginate";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const columns = [
  { field: "scheduled_date", headerName: "Data Entrega", width: 160 },
  { field: "id", headerName: "Pedido", width: 125 },
  { field: "value", headerName: "Valor", width: 115 },
  { field: "order_status", headerName: "Status Pedido", width: 170 },
  { field: "nf_status", headerName: "Status NF", width: 140 },
  {
    field: "invoice_url",
    headerName: "Link NF",
    width: 130,
    renderCell: (params) => (
      <a href={params.value} target="_blank" rel="noreferrer">
        Link-NF
      </a>
    ),
  },
  {
    field: "bill_url",
    headerName: "Link Boleto",
    width: 150,
    renderCell: (params) => (
      <a href={params.value} target="_blank" rel="noreferrer">
        Link-Boleto
      </a>
    ),
  },
];

export default function BillsAndInvoices() {
  const { addPopup } = usePopup();
  const { token } = useAuth();

  const [deliveryDate, setdeliveryDate] = useState(setInitialDate());
  const [dateButton, setDateButton] = useState(false);
  const [orderField, setOrderField] = useState(false);
  const [orderValue, setOrderValue] = useState("");
  const [checkButton, setCheckButton] = useState(true);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const isPaginate = showPaginate(pages, totalCount);

  const onInit = (date) => {
    setRows([]);
    setLoading(true);
    setOrderField(true);
    setDateButton(true);
    billsAndInvoicesService
      .getBillsAndInvoices({
        deliveryDate: date,
        page: page,
        token: token,
      })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error();
        }
        return response;
      })
      .then(({ data }) => {
        setRows(data?.results || []);
        setPages(data?.total_page);
        setTotalCount(data?.total_count);
      })
      .catch(() => {
        addPopup({
          type: "error",
          title: `Operação não realizada!`,
        });
      })
      .finally(() => {
        setLoading(false);
        setOrderField(false);
        setDateButton(false);
      });
  };

  useEffect(() => {
    onInit(deliveryDate);
  }, [deliveryDate, page]);

  const handleChange = (e) => {
    setPage(1);
    setdeliveryDate(e.target.value);
  };

  const setOrder = (e) => {
    if (e.target.value.trim() !== "") {
      setOrderValue(e.target.value);
      setDateButton(true);
      setCheckButton(false);
    } else {
      setOrderValue("");
      setDateButton(false);
      setCheckButton(true);
    }
  };

  const getByOrder = (e) => {
    setRows([]);
    setLoading(true);
    setOrderField(true);
    setDateButton(true);
    setCheckButton(true);
    billsAndInvoicesService
      .getBillsAndInvoices({ order: orderValue, token: token })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error();
        }
        return response;
      })
      .then(({ data }) => {
        setRows(data?.results);
        setPages(data?.total_page);
        setTotalCount(data?.total_count);
      })
      .catch(() => {
        addPopup({
          type: "error",
          title: "Operação não realizada!",
        });
      })
      .finally(() => {
        setDateButton(false);
        setOrderValue("");
        setLoading(false);
        setOrderField(false);
      });
    e.preventDefault();
  };

  const handleChangePage = (page) => {
    const anchor = document.querySelector("#back-to-top-anchor");
    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
      });
    }

    setPage(page);
  };

  return (
    <div>
      <div>
        <TitlePage>Consulta de Notas e Boletos</TitlePage>
        <p>
          Selecione a data de entrega desejada e serão apresentados os pedidos
          respectivas Notas fiscais e Boletos (se houver). Também é possível
          pesquisar por pedidos individualmente, basta digitar seu número no
          campo abaixo.
        </p>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{ padding: 6 }}
          spacing={2}
        >
          <Grid item>
            <form noValidate>
              <TextField
                id="date"
                label="Data de Entrega"
                type="date"
                value={deliveryDate}
                disabled={dateButton}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </Grid>
          <Grid item>
            <TextField
              name="orderId"
              variant="outlined"
              required
              style={{ width: 105, marginTop: 8 }}
              size="small"
              id="orderId"
              label="nº Pedido"
              autoFocus
              disabled={orderField}
              value={orderValue}
              autoComplete="off"
              onChange={(event) => setOrder(event)}
            />
          </Grid>
          <Grid item>
            <Button
              onClick={(event) => getByOrder(event)}
              style={{ marginTop: 10 }}
              disabled={checkButton}
              loading={loading}
            >
              Consultar
            </Button>
          </Grid>
        </Grid>
      </div>
      <CustomDataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        showPaginate={false}
      />
      <PaginateContent>
        {isPaginate && !loading && (
          <Paginate
            breakLabel="..."
            nextLabel={<AiOutlineRight />}
            forcePage={page - 1}
            onPageChange={(e) => handleChangePage(e.selected + 1)}
            pageRangeDisplayed={3}
            pageCount={pages}
            previousLabel={<AiOutlineLeft />}
            renderOnZeroPageCount={null}
          />
        )}
      </PaginateContent>
    </div>
  );
}
