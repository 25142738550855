import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { api } from "../apiClient";
import { AuthTokenError } from "../errors/AuthTokenError";

export const ApiInterceptor = ({ children }) => {
  const history = useHistory();
  const { signOut } = useAuth();

  useEffect(() => {
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const statusError = error.response.status;
        if (statusError === 401) {
          if (typeof window !== undefined) {
            signOut();
            history.push("/login");
          } else {
            return Promise.reject(new AuthTokenError());
          }
        }

        return Promise.reject(error);
      }
    );
  }, [history]);
  return children;
};
