import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import { ModalBody } from "../../../components/Modals/ModalBody";
import ModalCard from "../../../components/Modals/ModalCard";
import { Modal } from "../../../components/Modals/Modal";
import {
  AttachmentContent,
  RemoveAttachmentContent,
  StyledIconButton,
  StyledModalCardTextValue,
  useStyles,
} from "./styles";
import { BsPaperclip } from "react-icons/bs";
import {
  ItemsOrderTable,
  ItemsTable,
} from "../../../components/Table/ItemsTable";
import CloseIcon from "@material-ui/icons/Close";
import { useRef } from "react";

const formattedItems = (items) => {
  return (items || []).map((item) => {
    return {
      itemId: item.id,
      itemSku: item.sku,
      itemName: item.product_name,
      complaintQty: item.quantity_complaint,
    };
  });
};

const formattedOrderItems = (items) => {
  if (!items) {
    return [];
  }
  return items?.map((item) => {
    return {
      itemId: item.id,
      itemSku: item.sku,
      itemName: item.description,
      itemQty: item.purchase_value,
    };
  });
};

export const DetailRefund = ({
  complaint,
  handleClose,
  loading,
  handleSubmit,
}) => {
  const classes = useStyles();
  const [chargeBackOnCard, setChargeBackOnCard] = useState(false);
  const [checkedNewTicket, setCheckedNewTicket] = useState(false);
  const [paymentReceiptImage, setPaymentReceiptImage] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const items = formattedItems(complaint?.items);
  const inputFileRef = useRef(null);

  const encodeBase64 = (image) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(image[0]);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleChangeFile = async (selectedFile) => {
    if (selectedFile) {
      const fileName = selectedFile[0].name;
      const receipt = await encodeBase64(selectedFile);
      setPaymentReceiptImage(receipt);
      setSelectedFileName(fileName);
    }
  };

  const handleRemoveAttachment = () => {
    setPaymentReceiptImage(null);
    setSelectedFileName("");
    inputFileRef.current.value = null;
  };

  return (
    <ModalBody handleClose={handleClose}>
      {complaint.action === "Desconto no boleto" && (
        <Box className={classes.modalContent}>
          <Box className={classes.modalHeading}>
            {`Detalhes da Quebra - Pedido #${complaint.order_id}`}
          </Box>
          <ModalCard.Card>
            <ModalCard.Title>Dados do Cliente</ModalCard.Title>
            <ModalCard.Text>
              Nome:
              <ModalCard.TextValue>
                {complaint.client.trade_name || complaint.client.name}
              </ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              CPF/CNPJ:
              <ModalCard.TextValue>{complaint?.CpfCnpj}</ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>
          <ItemsTable items={items} />
          <ModalCard.Card>
            <ModalCard.Title>Dados de Tratativa</ModalCard.Title>
            <ModalCard.Text>
              Tratativa:
              <ModalCard.TextValue>Desconto no boleto</ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              Valor de Devolução:
              <ModalCard.TextValue>
                {complaint.complaintValue}
              </ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>

          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedNewTicket}
                  onChange={(e) => setCheckedNewTicket(e.target.checked)}
                />
              }
              label="Gerar desconto no boleto"
            />
          </Box>

          <ModalCard.Text>
            Criado por:
            <ModalCard.TextValue>
              {complaint.responsible?.created_by}
            </ModalCard.TextValue>
          </ModalCard.Text>
          <ModalCard.ActionContent>
            <Button
              disabled={!checkedNewTicket}
              loading={loading}
              onClick={() =>
                handleSubmit(
                  complaint.id,
                  paymentReceiptImage,
                  chargeBackOnCard
                )
              }
            >
              Finalizar
            </Button>
          </ModalCard.ActionContent>
        </Box>
      )}
      {complaint.action === "Reembolso" && (
        <Box className={classes.modalContent}>
          <Box className={classes.modalHeading}>
            {`Detalhes da Quebra - Pedido #${complaint.order_id}`}
          </Box>
          <ModalCard.Card>
            <ModalCard.Title>Dados do Cliente</ModalCard.Title>
            <ModalCard.Text>
              Nome:
              <ModalCard.TextValue>
                {complaint.client.trade_name || complaint.client.name}
              </ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              CPF/CNPJ:
              <ModalCard.TextValue>{complaint.CpfCnpj}</ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>
          <ItemsTable items={items} />
          <ModalCard.Card>
            <ModalCard.Title>Dados de Tratativa</ModalCard.Title>
            <ModalCard.Text>
              Tratativa:
              <ModalCard.TextValue>Reembolso</ModalCard.TextValue>
            </ModalCard.Text>
            {complaint?.solution?.action_json?.refund?.refundOption ===
              "pix" && (
              <>
                <ModalCard.Text>
                  Tipo Chave Pix:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.typePix}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Chave Pix:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.keyPix}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Favorecido:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.favored ||
                      "Não informado"}
                  </ModalCard.TextValue>
                </ModalCard.Text>
              </>
            )}
            {complaint?.solution?.action_json?.refund?.refundOption ===
              "transferBank" && (
              <>
                <ModalCard.Text>
                  Tipo de Conta:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.typeAccount}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Banco:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.bankName}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Agência:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.bankBranch}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Conta:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.bankAccount}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  CPF/CNPJ:
                  <ModalCard.TextValue>
                    {complaint?.solution?.action_json?.refund?.bankDocument}
                  </ModalCard.TextValue>
                </ModalCard.Text>
              </>
            )}
            <ModalCard.Text>
              Valor de Devolução:
              <ModalCard.TextValue>
                {complaint.complaintValue}
              </ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>
          <ModalCard.Card>
            <ModalCard.Title>Nota fiscal</ModalCard.Title>
            <ModalCard.Text>
              Nº:
              <ModalCard.TextValue>
                {complaint?.solution?.invoice_number}
              </ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={chargeBackOnCard}
                  onChange={(e) => setChargeBackOnCard(e.target.checked)}
                />
              }
              label="Estorno parcial no cartão"
            />
          </Box>
          <AttachmentContent>
            <Box
              className={`${
                paymentReceiptImage
                  ? "modalAttachmentFill"
                  : "modalAttachmentNull"
              }`}
            >
              <label htmlFor={`fileUpload${complaint?.id}`}>
                <BsPaperclip className="clip" />{" "}
                <Box className="fileName">
                  {!selectedFileName ? "Anexar comprovante" : selectedFileName}
                </Box>
                <input
                  type="file"
                  ref={inputFileRef}
                  multiple={false}
                  accept="image/*,application/pdf"
                  id={`fileUpload${complaint?.id}`}
                  style={{ display: "none" }}
                  onChange={(e) =>
                    e.target.files.length && handleChangeFile(e.target.files)
                  }
                />
              </label>
            </Box>
            <RemoveAttachmentContent>
              {paymentReceiptImage && (
                <StyledIconButton onClick={handleRemoveAttachment}>
                  <CloseIcon />
                </StyledIconButton>
              )}
            </RemoveAttachmentContent>
          </AttachmentContent>
          <ModalCard.Text>
            Criado por:
            <ModalCard.TextValue>
              {complaint.responsible?.created_by}
            </ModalCard.TextValue>
          </ModalCard.Text>
          <ModalCard.ActionContent>
            <Button
              disabled={!paymentReceiptImage}
              loading={loading}
              onClick={() =>
                handleSubmit(
                  complaint.id,
                  paymentReceiptImage,
                  chargeBackOnCard
                )
              }
            >
              Confirmar
            </Button>
          </ModalCard.ActionContent>
        </Box>
      )}
    </ModalBody>
  );
};

export const DetailTicket = ({
  ticket,
  handleClose,
  loading,
  handleSubmit,
  selectedCard,
  openTicketModal,
  handleCloseTicketDetail,
}) => {
  const classes = useStyles();
  const [paymentReceiptImage, setPaymentReceiptImage] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const items = formattedOrderItems(ticket?.items);
  const complaintItems = formattedItems(ticket?.complaintItems);
  const inputFileRef = useRef(null);

  const encodeBase64 = (image) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(image[0]);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleChangeFile = async (selectedFile) => {
    if (selectedFile) {
      const fileName = selectedFile[0].name;
      const receipt = await encodeBase64(selectedFile);
      setPaymentReceiptImage(receipt);
      setSelectedFileName(fileName);
    }
  };

  const handleRemoveAttachment = () => {
    setPaymentReceiptImage(null);
    setSelectedFileName("");
    inputFileRef.current = null;
  };

  useEffect(() => {
    handleRemoveAttachment();
  }, [openTicketModal]);

  const modalTitle = {
    canceled: `Detalhes do cancelamento - Pedido #${ticket?.orderId}`,
    "pending-tickets": `Detalhes da quebra - Pedido #${ticket?.orderId}`,
  }[selectedCard];

  const ticketType = {
    canceled: "cancel",
    "pending-tickets": "complaint",
  }[selectedCard];

  return (
    <Modal open={openTicketModal} onClose={handleCloseTicketDetail}>
      <ModalBody handleClose={handleClose}>
        <Box className={classes.modalContent}>
          <Box className={classes.modalHeading}>{modalTitle}</Box>
          <ModalCard.Card>
            <ModalCard.Title>Dados do Cliente</ModalCard.Title>
            <ModalCard.Text>
              Nome:
              <ModalCard.TextValue>{ticket?.clientName}</ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              CPF/CNPJ:
              <ModalCard.TextValue>{ticket?.clientCpfCnpj}</ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>

          {ticketType === "complaint" && <ItemsTable items={complaintItems} />}

          <ModalCard.Card>
            <ModalCard.Title>Dados do Pagamento</ModalCard.Title>
            <ModalCard.Text>
              Tipo de pagamento:
              <ModalCard.TextValue>{ticket?.paymentType}</ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              Pagamento:
              <StyledModalCardTextValue>
                {ticket?.paymentStatus}
              </StyledModalCardTextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              Valor do pedido:
              <ModalCard.TextValue>{ticket?.orderValue}</ModalCard.TextValue>
            </ModalCard.Text>
            <ModalCard.Text>
              Valor do frete:
              <ModalCard.TextValue>{ticket?.deliveryValue}</ModalCard.TextValue>
            </ModalCard.Text>
          </ModalCard.Card>

          <ModalCard.Card>
            <ModalCard.Title>Informações de Devolução</ModalCard.Title>
            {ticket?.refundType === "pix" && (
              <>
                <ModalCard.Text>
                  Chave Pix:
                  <ModalCard.TextValue>{ticket?.keyPix}</ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Favorecido:
                  <ModalCard.TextValue>
                    {ticket?.favoredPix || "Não informado"}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Valor de devolução:
                  <ModalCard.TextValue>
                    {ticket?.refundValue}
                  </ModalCard.TextValue>
                </ModalCard.Text>
              </>
            )}
            {ticket?.refundType === "transferBank" && (
              <>
                <ModalCard.Text>
                  Tipo de Conta:
                  <ModalCard.TextValue>
                    {ticket?.typeAccount}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Banco:
                  <ModalCard.TextValue>{ticket?.bankName}</ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Agência:
                  <ModalCard.TextValue>
                    {ticket?.bankBranch}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Conta:
                  <ModalCard.TextValue>
                    {ticket?.bankAccount}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  CPF/CNPJ:
                  <ModalCard.TextValue>
                    {ticket?.bankDocument}
                  </ModalCard.TextValue>
                </ModalCard.Text>
                <ModalCard.Text>
                  Valor de devolução:
                  <ModalCard.TextValue>
                    {ticket?.refundValue}
                  </ModalCard.TextValue>
                </ModalCard.Text>
              </>
            )}
          </ModalCard.Card>
          {ticketType === "cancel" && (
            <ModalCard.Card>
              <ModalCard.Title>Motivo do Cancelamento</ModalCard.Title>
              <ModalCard.Text>
                Motivo:
                <ModalCard.TextValue>
                  {ticket?.reason?.name}
                </ModalCard.TextValue>
              </ModalCard.Text>
              <ModalCard.Text>
                Comentário:
                <ModalCard.TextValue>
                  {ticket?.canceledObservation}
                </ModalCard.TextValue>
              </ModalCard.Text>
            </ModalCard.Card>
          )}

          <AttachmentContent>
            <Box
              className={`${
                paymentReceiptImage
                  ? "modalAttachmentFill"
                  : "modalAttachmentNull"
              }`}
            >
              <label htmlFor={`fileUpload${ticket?.id}`}>
                <BsPaperclip className="clip" />{" "}
                <Box className="fileName">
                  {!selectedFileName ? "Anexar comprovante" : selectedFileName}
                </Box>
                <input
                  type="file"
                  ref={inputFileRef}
                  multiple={false}
                  accept="image/*,application/pdf"
                  id={`fileUpload${ticket?.id}`}
                  style={{ display: "none" }}
                  onChange={(e) =>
                    e.target.files.length && handleChangeFile(e.target.files)
                  }
                />
              </label>
            </Box>
            <RemoveAttachmentContent>
              {paymentReceiptImage && (
                <StyledIconButton onClick={handleRemoveAttachment}>
                  <CloseIcon />
                </StyledIconButton>
              )}
            </RemoveAttachmentContent>
          </AttachmentContent>
          <ModalCard.Text>
            Criado por:
            <ModalCard.TextValue>{ticket?.createdBy?.name}</ModalCard.TextValue>
          </ModalCard.Text>
          <ModalCard.ActionContent>
            <Button
              disabled={!paymentReceiptImage}
              loading={loading}
              onClick={() => handleSubmit(paymentReceiptImage)}
            >
              Confirmar
            </Button>
          </ModalCard.ActionContent>
        </Box>
      </ModalBody>
    </Modal>
  );
};
