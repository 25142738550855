import { api } from "../utils/Api/apiClient";

const getOrderItems = async ({
  scheduled_date,
  status,
  order_id,
  client_doc,
  export_file,
  page,
}) => {
  const url = `/api/abacate/v1/order-items`;
  return await api.get(url, {
    params: {
      scheduled_date,
      status,
      client_doc,
      order_id,
      export: export_file,
      page,
    },
    responseType: export_file ? "blob" : "json",
  });
};

const orderGroupService = {
  getOrderItems,
};
export default orderGroupService;
