import { Switch, Redirect, Route } from "react-router-dom";

import RouteApp from "./route";
import Login from "../pages/public/login";
import Layout from "./private";

const Routes = () => {
  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <RouteApp
        path="/app"
        component={Layout}
        isPrivate
        permissionsRoute={[]}
      />
      <Redirect from="*" to="/app/home" />
    </Switch>
  );
};

export default Routes;
