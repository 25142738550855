import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";

const Calendar = ({
  label = "Data",
  format = "dd/MM/yyyy",
  value,
  minDate,
  onChange,
  ...rest
}) => {
  const todayDate = new Date();
  const itsSunday = (day) => !day;
  const isSundayOrSaturday = (day) => [0, 6].includes(day);
  const allowedTime = isSundayOrSaturday(todayDate.getDay())
    ? new Date().getHours() >= 18
    : new Date().getHours() >= 19;

  const addDays = allowedTime ? 2 : 1;
  todayDate.setDate(todayDate.getDate() + addDays);
  if (itsSunday(todayDate.getDay())) {
    todayDate.setDate(todayDate.getDate() + 1);
  }

  return (
    <KeyboardDatePicker
      label={label}
      format={format}
      minDate={minDate ?? todayDate}
      variant="static"
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};

export default Calendar;
