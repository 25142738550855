import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import { useCallback, useMemo } from "react";
import { memo } from "react";
import { Button } from "../../../components/Button";
import { SelectClearButtonContent, StyledSelectProductBase } from "./style";

export const SelectProductsBase = memo(
  ({
    productsBase,
    items,
    handleChangeSelectedProductBase,
    handleClearSelectedProductBase,
    statusFilters,
    reasonFilters,
  }) => {
    const filteredItems = useMemo(() => {
      return items
        ?.filter((item) =>
          statusFilters !== "all"
            ? statusFilters === "unclassified"
              ? !item?.responsibleId
              : item?.responsibleId
            : item
        )
        .filter((item) =>
          reasonFilters?.length
            ? reasonFilters.includes(item?.complaintReason?.id)
            : item
        );
    }, [statusFilters, items, productsBase, reasonFilters]);

    const itemsProductBaseId = useMemo(() => {
      return filteredItems?.reduce((acc, item) => {
        if (!acc?.includes(item?.productBaseId)) {
          return [...acc, item?.productBaseId];
        }
        return acc;
      }, []);
    }, [filteredItems]);

    const qtyProductBase = useCallback(
      (id) => {
        return filteredItems.reduce(
          (acc, item) => (item.productBaseId === id ? acc + 1 : acc),
          0
        );
      },
      [filteredItems]
    );

    const menuItems = useMemo(() => {
      return productsBase
        ?.filter(({ id }) => itemsProductBaseId?.includes(id))
        ?.map((product) => {
          return (
            <MenuItem key={product.id} value={product.id}>
              <Checkbox
                value={product.id}
                checked={product.isChecked}
                onChange={() => handleClickItem(product.id)}
              />
              <ListItemText
                primary={`${product.name} (${qtyProductBase(product.id)})`}
              />
            </MenuItem>
          );
        });
    }, [itemsProductBaseId]);

    const handleClickItem = (productsBaseId) => {
      handleChangeSelectedProductBase(productsBaseId);
    };

    return (
      <FormControl variant="outlined">
        <InputLabel id="select-product-base">Produto base</InputLabel>
        <StyledSelectProductBase
          labelId="select-product-base"
          multiple
          value={productsBase?.filter(({ id }) =>
            itemsProductBaseId?.includes(id)
          )}
          onClick={(e) => {
            const productsBaseId = e.target.value?.slice(-1)[0];
            handleClickItem(productsBaseId);
          }}
          label="Produto base"
          renderValue={() =>
            productsBase
              .filter(({ isChecked }) => isChecked)
              .map((product) => product.name)
              .join(", ")
          }
        >
          {menuItems}
          <SelectClearButtonContent>
            <Button
              fullWidth
              onClick={(e) => {
                e.stopPropagation();
                handleClearSelectedProductBase();
              }}
            >
              Limpar
            </Button>
          </SelectClearButtonContent>
        </StyledSelectProductBase>
      </FormControl>
    );
  }
);
