import { DivSearchDate, SelectDateFrom, SelectDateTo } from "./styles";

export const SelectDateSearch = ({
  selectedSearchDateFrom,
  handleChangeSearchDateFrom,
  selectedSearchDateTo,
  handleChangeSearchDateTo,
}) => {
  return (
    <DivSearchDate>
      <SelectDateFrom
        inputVariant="outlined"
        autoOk
        label="De"
        format="dd/MM/yyyy"
        InputProps={{
          readOnly: true,
        }}
        value={selectedSearchDateFrom}
        onChange={handleChangeSearchDateFrom}
        KeyboardButtonProps={{
          "aria-label": "Data a partir de",
        }}
        InputAdornmentProps={{ position: "start" }}
      />
      <SelectDateTo
        inputVariant="outlined"
        autoOk
        label="Até"
        format="dd/MM/yyyy"
        InputProps={{
          readOnly: true,
        }}
        value={selectedSearchDateTo}
        onChange={handleChangeSearchDateTo}
        KeyboardButtonProps={{
          "aria-label": "Data até",
        }}
        InputAdornmentProps={{ position: "start" }}
      />
    </DivSearchDate>
  );
};
