import { makeStyles } from "@material-ui/core";

export const color = (status) => {
  const color = {
    aprovado: "#89BD23",
    recusado: "#FF5722",
    pendente: "#F6BE15",
    cancelado: "#520000",
    em_transito: "#9C27B0",
    entregue: "#2089DC",
    reagendado: "#520000"
  };

  return color[status] || "#F6BE15";
};

export const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "20px",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#DBE4C7",
    color: "#6E8247",

    "&:hover": {
      backgroundColor: "#DBE4C7",
    },
  },
  paginationContent: {
    display: "flex",
    justifyContent: "center",
    overflow: "auto",
    margin: ".5rem 0 1rem 0",
  },
  pagination: {
    "& ul li .Mui-selected": {
      color: "#fff",
      background: "#618619",
    },

    "& ul li:last-child button font": {
      display: "none",
    },
  },
  dataTable: {
    borderCollapse: "separate",
    border: "1px solid #F1F2F6",
    borderRadius: "10px",
    paddingInline: "8px",
    minWidth: 1024,
    overflowX: "auto",
    marginBottom: "1em",
    alignItems: "center",
    backgroundColor: "#FFF",

    "& .pagination": {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      margin: "16px 0 0 16px",
      border: "1px solid black",
    },
  },
  tableHeader: {
    background: "#FFF",
    "& .hId": {
      fontWeight: "bold",
      borderBottom: "2px solid #F1F2F6",
      width: 64,
      paddingBlock: 16,
      paddingInline: 8,
    },
    "& .hName": {
      fontWeight: "bold",
      width: 243,
      paddingBlock: 16,
      paddingInline: 8,
      borderBottom: "2px solid #F1F2F6",
    },
    "& .hCpf_CNPJ": {
      fontWeight: "bold",
      width: 128,
      paddingBlock: 16,
      paddingInline: 8,
      borderBottom: "2px solid #F1F2F6",
    },
    "& .hDataPedido": {
      fontWeight: "bold",
      width: 110,
      paddingBlock: 16,
      paddingInline: 8,
      gap: "4px",
      borderBottom: "2px solid #F1F2F6",
      "& svg": {
        cursor: "pointer",
      },
    },
    "& .hDataEntrega": {
      fontWeight: "bold",
      width: 116,
      paddingBlock: 16,
      paddingInline: 8,
      gap: "4px",
      borderBottom: "2px solid #F1F2F6",

      "& svg": {
        cursor: "pointer",
      },
    },
    "& .hStatus": {
      fontWeight: "bold",
      textAlign: "center",
      width: 86,
      paddingBlock: 16,
      paddingInline: 8,
      borderBottom: "2px solid #F1F2F6",
    },
    "& .hAction": {
      textAlign: "center",
      fontWeight: "bold",
      width: 133,
      paddingBlock: 16,
      paddingInline: 8,
      borderBottom: "2px solid #F1F2F6",
    },
  },
  tableBody: {
    "& tr:hover td": {
      transition: "200ms ease-in-out",
      backgroundColor: "#F0F0F0",
    },
  },
  tableData: {
    "& .dId": {
      width: 64,
      paddingBlock: 16,
      paddingInline: 8,
      borderBottom: "2px solid #F1F2F6",
    },
    "& .dName": {
      width: 243,
      paddingBlock: 16,
      paddingInline: 8,
      borderBottom: "2px solid #F1F2F6",
    },
    "& .dCpf_CNPJ": {
      width: 128,
      paddingBlock: 16,
      paddingInline: 8,
      borderBottom: "2px solid #F1F2F6",
    },
    "& .dDataPedido": {
      width: 110,
      paddingBlock: 16,
      paddingInline: 8,
      borderBottom: "2px solid #F1F2F6",
    },
    "& .dDataEntrega": {
      width: 116,
      paddingBlock: 16,
      paddingInline: 8,
      borderBottom: "2px solid #F1F2F6",
    },
    "& .dStatus": {
      textAlign: "center",
      width: 86,
      paddingBlock: 16,
      paddingInline: 8,
      borderBottom: "2px solid #F1F2F6",
    },
    "& .dAction": {
      textAlign: "center",
      width: 133,
      paddingBlock: 16,
      paddingInline: 8,
      borderBottom: "2px solid #F1F2F6",
    },
  },
  msgNotFound: {
    display: "flex",
    marginTop: "100px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    "& h1": {
      fontFamily: "Inter",
      fontWeight: "600",
      fontSize: "22px",
    },

    "& span": {
      fontFamily: "Roboto",
      fontWeight: "400",
      fontSize: "14px",
      color: "#757575",
    },
  },
}));
