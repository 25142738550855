import { api } from "../utils/Api/apiClient";

const getProductsWeight = async (data) => {
  const url = `/api/products/v2/products-unit-weight`;
  return await api.post(url, data).then(x => x.data);
};

const ProductsServices = {
  getProductsWeight,
};

export default ProductsServices;
