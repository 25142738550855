import {
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

const SelectTime = ({ value, onChange, label, icon, menuItems }) => {
  return (
    <>
      <InputLabel id={`labelTime`}>{label}</InputLabel>
      <Select
        labelId={`labelTime`}
        value={value}
        onChange={onChange}
        label={label}
        startAdornment={
          <InputAdornment position="start">{icon}</InputAdornment>
        }
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            disabled={item.disabled || false}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default SelectTime;
