import { CardDashBoard } from "../../../components/Cards/CardDashBoard";
import { ContainerPage } from "../../../components/ContainerPage";
import { H1 } from "../../../components/Heading/Heading";
import {
  Content,
  DashBoardCardContent,
  PageTitleContent,
  PaginateContent,
  TableContainer,
} from "./styles";
import { useState, useRef } from "react";
import { OrdersRefusedTable, TableComplaintTickets, OrdersRescheduledTable } from "./tables";
import { useEffect } from "react";
import PageLoader from "../../../components/PageLoader";
import { DetailTicket } from "./modalBody";
import { useMemo } from "react";
import { AlertModal } from "../../../components/AlertModal";
import { toast } from "react-toastify";
import { LoadingBackDrop } from "../../../components/LoadingBackDrop";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { Paginate } from "../../../components/Paginate/Paginate";
import TicketService from "../../../services/ticketService";
import { useQuery } from "../../../utils/useQuery";
import { useHistory } from "react-router-dom";
import formatInvoicingData from "./formatInvoicingData";
import { CustomLineProgress } from "../../../components/CustomLineProgress";

export const OrderInvoicing = () => {
  const query = useQuery();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [ticketDetail, setTicketDetail] = useState({});
  const acceptQueryCard = ["pending", "canceled", "pending-tickets", "rescheduled"];
  const queryCard = query.get("card");
  const selectedCard = acceptQueryCard.includes(queryCard)
    ? queryCard
    : acceptQueryCard[1];
  const [openTicketModal, setOpenTicketModal] = useState(false);
  const [ticketsCanceledOrders, setTicketsCanceledOrders] = useState([]);
  const [ticketsReschedule, setTicketsReschedule] = useState([]);
  const [ticketsComplaint, setTicketsComplaint] = useState([]);
  const [pageTicketComplaint, setPageTicketComplaint] = useState(1);
  const [pageTicketCanceledOrder, setPageTicketCanceledOrder] = useState(1);
  const [pageTicketReschedule, setPageTicketReschedule] = useState(1);
  const [loadingCardData, setLoadingCardData] = useState(false);
  const firstRender = useRef(true);
  const params = { status: "aberto", subjectId: 6 };

  const getComplaints = async () => {
    try {
      setLoading(true);

      const [ticketDataReschedule, ticketDataCanceled, ticketDataComplaint] = await Promise.all([
        TicketService.getTickets({
          ...params,
          externalCode: "#reschedule",
          page: pageTicketReschedule,
        }),
        TicketService.getTickets({
          ...params,
          externalCode: "#cancel",
          page: pageTicketCanceledOrder,
        }),
        TicketService.getTickets({
          ...params,
          externalCode: "#complaint",
          page: pageTicketComplaint,
        }),
      ]);
      setTicketsReschedule(ticketDataReschedule.data)
      setTicketsCanceledOrders(ticketDataCanceled.data);
      setTicketsComplaint(ticketDataComplaint.data);
    } catch {
      toast.error("Erro ao carregar dados");
    } finally {
      setLoading(false);
      firstRender.current = false;
    }
  };

  const getCardData = () => {
    setLoadingCardData(true);
    const loadData = {
      canceled: async () => {
        try {
          const { data: ticketDataCanceled } = await TicketService.getTickets({
            ...params,
            externalCode: "#cancel",
            page: pageTicketCanceledOrder,
          });
          setTicketsCanceledOrders(ticketDataCanceled);
        } catch (error) {
          toast.error(`Erro ao carregar os dados`);
        } finally {
          setLoadingCardData(false);
        }
      },
      rescheduled: async () => {
        try {
          const { data: ticketDataReschedule } = await TicketService.getTickets({
            ...params,
            externalCode: "#reschedule",
            page: pageTicketReschedule,
          });
          setTicketsReschedule(ticketDataReschedule);
        } catch (error) {
          toast.error(`Erro ao carregar os dados`);
        } finally {
          setLoadingCardData(false);
        }
      },
      "pending-tickets": async () => {
        try {
          const { data: ticketDataComplaint } = await TicketService.getTickets({
            ...params,
            externalCode: "#complaint",
            page: pageTicketComplaint,
          });
          setTicketsComplaint(ticketDataComplaint);
        } catch (error) {
          toast.error(`Erro ao carregar os dados`);
        } finally {
          setLoadingCardData(false);
        }
      },
    }[selectedCard];

    loadData();
  };

  useEffect(() => {
    getComplaints();
  }, []);

  useEffect(() => {
    !firstRender.current && getCardData();
  }, [pageTicketComplaint, pageTicketCanceledOrder, pageTicketReschedule]);

  const formattedCanceledOrdersTickets = useMemo(
    () => formatInvoicingData.formattedCanceledOrdersTickets(ticketsCanceledOrders),
    [ticketsCanceledOrders]
  );

  const formattedRescheduleTickets = useMemo(
    () => formatInvoicingData.formattedRescheduleTickets(ticketsReschedule),
    [ticketsReschedule]
  );

  const formattedTicket = useMemo(
    () => formatInvoicingData.formattedTicket(ticketDetail),
    [ticketDetail]
  );

  const formattedComplaintTickets = useMemo(
    () => formatInvoicingData.formattedComplaintTickets(ticketsComplaint),
    [ticketsComplaint]
  );

  const showAlert = () => {
    setOpenAlert(true);
  };

  const closeAlert = () => {
    setOpenAlert(false);

    const updatedTickets = (ticketsToUpdate) =>
      ticketsToUpdate.map((ticket) =>
        ticket.id === ticketDetail.id
          ? { ...ticket, status: "fechado" }
          : ticket
      );

    const { results: tickets } = {
      canceled: ticketsCanceledOrders,
      rescheduled: ticketsReschedule,
      "pending-tickets": ticketsComplaint,
    }[selectedCard];

    const results = updatedTickets(tickets);
    const newState = (prev) => ({ ...prev, count: prev.count - 1, results });

    const dataSetter = {
      canceled: () => setTicketsCanceledOrders(newState),
      rescheduled: () => setTicketsReschedule(newState),
      "pending-tickets": () => setTicketsComplaint(newState),
    }[selectedCard];

    dataSetter();

    handleCloseTicketDetail();
  };

  const handleChangePage = (page) => {
    const anchor = document.querySelector("#back-to-top-anchor");
    !!anchor && anchor.scrollIntoView({ behavior: "smooth" });

    const changePage = {
      canceled: () => setPageTicketCanceledOrder(page),
      rescheduled: () => setPageTicketReschedule(page),
      "pending-tickets": () => setPageTicketComplaint(page),
    }[selectedCard];

    changePage();
  };

  const handleCardClick = (card) => {
    history.push(`?card=${card}`);
  };

  const handleTicketShowDetail = async (ticketId) => {
    try {
      setSubmitLoading(true);
      const { data } = await TicketService.getTicketById({
        ticketId,
      });
      setTicketDetail(data);
      setOpenTicketModal(true);
    } catch {
      toast.error("Erro ao carregar os dados");
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleCloseTicketDetail = () => {
    setOpenTicketModal(false);
  };

  const handleSubmitTicket = async (invoiceAction, invoiceNumber) => {
    const ticketId = formattedTicket?.id;
    const ticketData = {
      ...ticketDetail,
      status: "fechado",
      actionJson: {
        ...ticketDetail?.actionJson,
        invoiceAction: invoiceAction,
        invoiceNumber,
      },
    };
    try {
      setSubmitLoading(true);
      await TicketService.updateTicket({
        ticketId,
        ticketData,
      });
      showAlert();
    } catch (error) {
      toast.error(`Erro ao atualizar ticket ${error?.message}`);
    } finally {
      setSubmitLoading(false);
    }
  };

  const renderTable = {
    [acceptQueryCard[1]]: (
      <TableContainer>
        <OrdersRefusedTable
          tickets={formattedCanceledOrdersTickets}
          handleShowDetail={handleTicketShowDetail}
        />
        <PaginateContent>
          {ticketsCanceledOrders?.pages > 1 && (
            <Paginate
              breakLabel="..."
              nextLabel={<AiOutlineRight />}
              forcePage={pageTicketCanceledOrder - 1}
              onPageChange={(e) => handleChangePage(e.selected + 1)}
              pageRangeDisplayed={3}
              pageCount={ticketsCanceledOrders.pages || 0}
              previousLabel={<AiOutlineLeft />}
              renderOnZeroPageCount={null}
            />
          )}
        </PaginateContent>
      </TableContainer>
    ),
    [acceptQueryCard[2]]: (
      <TableContainer>
        <TableComplaintTickets
          tickets={formattedComplaintTickets}
          handleShowDetail={handleTicketShowDetail}
        />
        <PaginateContent>
          {ticketsComplaint?.pages > 1 && (
            <Paginate
              breakLabel="..."
              nextLabel={<AiOutlineRight />}
              forcePage={pageTicketComplaint - 1}
              onPageChange={(e) => handleChangePage(e.selected + 1)}
              pageRangeDisplayed={3}
              pageCount={ticketsComplaint.pages || 0}
              previousLabel={<AiOutlineLeft />}
              renderOnZeroPageCount={null}
            />
          )}
        </PaginateContent>
      </TableContainer>
    ),
    [acceptQueryCard[3]]: (
      <TableContainer>
        <OrdersRescheduledTable
          tickets={formattedRescheduleTickets}
          handleShowDetail={handleTicketShowDetail}
        />
        <PaginateContent>
          {ticketsReschedule?.pages > 1 && (
            <Paginate
              breakLabel="..."
              nextLabel={<AiOutlineRight />}
              forcePage={pageTicketReschedule - 1}
              onPageChange={(e) => handleChangePage(e.selected + 1)}
              pageRangeDisplayed={3}
              pageCount={ticketsReschedule.pages || 0}
              previousLabel={<AiOutlineLeft />}
              renderOnZeroPageCount={null}
            />
          )}
        </PaginateContent>
      </TableContainer>
    ),
  }[selectedCard];

  if (loading) {
    return <PageLoader />;
  }
  return (
    <ContainerPage>
      <PageTitleContent>
        <H1>Quadro de Avisos - Faturamento</H1>
      </PageTitleContent>
      <DashBoardCardContent>
        <CardDashBoard
          qty={ticketsCanceledOrders.count || "0"}
          text="Pedidos cancelados"
          isSelected={selectedCard === acceptQueryCard[1]}
          onClick={() => handleCardClick(acceptQueryCard[1])}
        />
        <CardDashBoard
          qty={ticketsComplaint.count || "0"}
          text="Tratativas pendentes"
          isSelected={selectedCard === acceptQueryCard[2]}
          onClick={() => handleCardClick(acceptQueryCard[2])}
        />
        <CardDashBoard
          qty={ticketsReschedule.count || "0"}
          text="Pedidos reagendados"
          isSelected={selectedCard === acceptQueryCard[3]}
          onClick={() => handleCardClick(acceptQueryCard[3])}
        />
      </DashBoardCardContent>

      <Content>
        {loadingCardData && <CustomLineProgress />}
        {renderTable}
      </Content>

      <AlertModal
        handleClose={closeAlert}
        open={openAlert}
        text={`Tratativa foi salva com sucesso`}
      />

      <DetailTicket
        handleClose={handleCloseTicketDetail}
        ticket={formattedTicket}
        loading={submitLoading}
        handleSubmit={handleSubmitTicket}
        handleCloseTicketDetail={handleCloseTicketDetail}
        openTicketModal={openTicketModal}
        selectedCard={selectedCard}
      />
      <LoadingBackDrop open={submitLoading} />
    </ContainerPage>
  );
};
