import { Box, styled } from "@material-ui/core";
import { IoMdDownload } from "react-icons/io";
import CloseIcon from "@material-ui/icons/Close";
import { Modal } from "./Modal";

const Content = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100vw",
  height: "100vh",
  padding: "2rem",
  gap: "2rem",
  "@media (max-width:1200px)": {
    padding: 16,
  },
});

const ActionContent = styled("span")({
  width: "100%",
  textAlign: "end",
  color: "#fff",
});

const ActionButton = styled("span")({
  cursor: "pointer",
  "& a": {
    textDecoration: "none",
    color: "#fff",
  },
});

const Image = styled("span")({
  display: "flex",
  justifyContent: "center",
  maxHeight: "80%",
  maxWidth: "100%",
  "& img": {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  "@media (max-width:1200px)": {
    "& img": {
      maxWidth: "100%",
    },
  },
});

export const ModalImage = ({ open, url, handleClose, alt }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Content>
        <ActionContent>
          <ActionButton onClick={handleClose}>
            <CloseIcon /> Fechar
          </ActionButton>
        </ActionContent>
        <Image>
          <img src={url} alt={alt} />
        </Image>
        <ActionContent>
          <ActionButton>
            <IoMdDownload />
            <a href={url} download={`Foto do produto`} rel="noreferrer">
              Baixar
            </a>
          </ActionButton>
        </ActionContent>
      </Content>
    </Modal>
  );
};
