import {
  Box,
  IconButton,
  makeStyles,
  styled,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ModalCard from "../../../components/Modals/ModalCard";

export const useStyles = makeStyles((theme) => ({
  pageTitleContent: {
    marginTop: "5.5rem",
  },
  dashBoardCardContent: {
    marginTop: "2rem",
  },
  filterContent: {
    display: "flex",
    justifyContent: "flex-end",
  },
  filter: {
    display: "flex",
    gap: "0.5rem",
    cursor: "pointer",

    "& svg": {
      width: "1.5rem",
      color: theme.colors.primary,
    },
  },

  checkBoxContent: {
    display: "flex",
    flexDirection: "column",
    paddingInline: "0.625rem",
    paddingBlock: "0.25rem",
  },

  historyTableContainer: {
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
    border: "0.5px solid #C4C4C4",
    marginTop: 24,
    paddingInline: 24,
  },

  historyTable: {
    minWidth: 968,
    marginBottom: "1em",

    "& .updateTitle": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
      color: "#757575",
      paddingInline: 8,

      "& span": {
        display: "block",
      },
    },

    "& tr th": {
      fontFamily: theme.font.Roboto,
      fontSize: "0.875rem",
      fontWeight: 500,
      color: theme.colors.primary,
    },

    "& tr td": {
      fontFamily: theme.font.Roboto,
      fontSize: "1rem",
      fontWeight: 400,
      color: theme.colors.primary,
    },
    "& .titleStatus": {
      paddingInline: 8,
      width: 16,
    },

    "& .titleId": {
      paddingInline: 8,
      width: 81,
    },

    "& .titleOrderId": {
      paddingInline: 8,
      width: 97,
    },

    "& .titleDate": {
      paddingInline: 8,
      width: 113,
    },

    "& .titleValue": {
      paddingInline: 8,
      width: 96,
    },

    "& .titleNegotiation": {
      paddingInline: 8,
      width: 198,
    },
    "& .titleUpdated": {
      paddingInline: 8,
      width: 128,
    },
    "& .titleAction": {
      paddingInline: 8,
      width: 127,
    },

    "& .contentStatus": {
      paddingInline: 8,
      width: 16,
    },

    "& .contentId": {
      paddingInline: 8,
      width: 81,
    },

    "& .contentOrderId": {
      paddingInline: 8,
      width: 97,
    },

    "& .contentDate": {
      paddingInline: 8,
      width: 113,

      "& span": {
        display: "block",

        "& .date": {
          fontFamily: "Roboto",
          fontSize: 16,
          fontWeight: 400,
          color: "#353535",
        },

        "& .time": {
          fontFamily: "Roboto",
          fontSize: 12,
          fontWeight: 400,
          color: "#353535",
        },
      },
    },

    "& .contentValue": {
      paddingInline: 8,
      width: 96,
    },

    "& .contentNegotiation": {
      paddingInline: 8,
      width: 198,
    },

    "& .contentUpdated": {
      paddingInline: 8,
      width: 128,

      "& span": {
        display: "block",

        "& .date": {
          fontFamily: "Roboto",
          fontSize: 16,
          fontWeight: 400,
          color: "#353535",
        },

        "& .time": {
          fontFamily: "Roboto",
          fontSize: 12,
          fontWeight: 400,
          color: "#353535",
        },
      },
    },

    "& .contentAction": {
      textAlign: "end",
      paddingInline: 8,
      width: 127,
    },
  },
  msgNotFound: {
    display: "flex",
    marginTop: "100px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    "& h1": {
      fontFamily: "Inter",
      fontWeight: "600",
      fontSize: "22px",
    },

    "& span": {
      fontFamily: "Roboto",
      fontWeight: "400",
      fontSize: "14px",
      color: "#757575",
    },
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    paddingInline: "1rem",
    gap: "1.5rem",
  },
  modalHeading: {
    fontFamily: theme.font.Inter,
    fontSize: 18,
    fontWeight: 600,
    color: theme.colors.primary,
    marginTop: "1.5rem",
  },
}));

export const AttachmentContent = styled(Box)(({ theme }) => ({
  display: "flex",
  "& .modalAttachmentNull": {
    display: "flex",
    alignItems: "center",
    "& label": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: theme.font.Roboto,
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "1rem",
      color: theme.colors.link,
      height: "1.5rem",
      cursor: "pointer",
      "& .fileName": {
        padding: "0.125rem 0.250rem",
      },
    },

    "& .clip": {
      padding: "0.125rem 0.250rem",
      width: "1.5rem",
      height: "1.5rem",
      color: theme.colors.link,
      cursor: "pointer",
    },
  },
  "& .modalAttachmentFill": {
    display: "flex",
    alignItems: "center",
    "& label": {
      backgroundColor: "#E7E7E7",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: theme.font.Roboto,
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "1rem",
      color: theme.colors.link,
      height: "1.5rem",
      cursor: "pointer",
      "& .fileName": {
        backgroundColor: "#E7E7E7",
        padding: "0.125rem 0.250rem",
      },
    },

    "& .clip": {
      padding: "0.125rem 0.250rem",
      width: "1.5rem",
      height: "1.5rem",
      color: theme.colors.darkAvocado,
      cursor: "pointer",
    },
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: "1.5rem",
  height: "1.5rem",
  "&:hover": {
    backgroundColor: theme.colors.lightAvocado,
    "& svg": {
      width: "1.25rem",
      height: "1.25rem",
      color: theme.colors.darkAvocado,
      cursor: "pointer",
    },
  },
  "& svg": {
    width: "1.25rem",
    height: "1.25rem",
    color: theme.colors.lightGray,
    cursor: "pointer",
  },
}));

export const RemoveAttachmentContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.whiteGray,
  height: "1.5rem",
}));

export const PaginateContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingBlock: "1rem",
});

export const PageTitleContent = styled(Box)({
  marginTop: "5.5rem",
});

export const DashBoardCardContent = styled(Box)({
  display: "flex",
  gap: "1.5rem",
  flexWrap: "wrap",
  marginTop: "5.5rem",
  marginBottom: "1.5rem",
});

export const FilterContent = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "1rem",
  height: "1.625rem",
});

export const Filter = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "0.5rem",
  cursor: "pointer",

  "& svg": {
    width: "1.5rem",
    color: theme.colors.primary,
  },
}));

export const CheckBoxContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  paddingInline: "0.625rem",
  paddingBlock: "0.25rem",
});

export const RefuseTableContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
  border: "0.5px solid #C4C4C4",
  marginTop: 24,
  paddingInline: 24,
});

export const HistoryTableContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
  border: "0.5px solid #C4C4C4",
  marginTop: 24,
  paddingInline: 24,
});

export const StyledRefusedTable = styled(Table)({
  minWidth: "60.4375rem",
  overflowY: "auto",
  marginBottom: "1em",
});

export const StyledRefusedTableHead = styled(TableHead)(({ theme }) => ({
  "& tr th": {
    fontFamily: theme.font.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1rem",
    color: theme.colors.primary,
    paddingInline: ".5rem",
    paddingBlock: "1.5rem 2rem",
  },
}));

export const StyledRefusedTableBody = styled(TableBody)(({ theme }) => ({
  "& tr td": {
    fontFamily: theme.font.Roboto,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.1875rem",
    color: theme.colors.primary,
    paddingInline: ".5rem",
    paddingBlock: "1.0625rem 1rem",
  },
}));

export const StyledRefusedTableRow = styled(TableRow)(({ theme }) => ({
  "& .status": {
    MaxWidth: "3rem",
  },

  "& .orderId": {
    MaxWidth: "7.0625rem",
  },
  "& .clientName": {
    MaxWidth: "12.25rem",
  },

  "& .clientType": {
    MaxWidth: "5.9375rem",
  },
  "& .reason": {
    MaxWidth: "16.3125rem",
  },

  "& .updated": {
    MaxWidth: "9rem",
    "& span": {
      display: "block",

      "& .date": {
        fontFamily: theme.font.Roboto,
        fontSize: "1rem",
        fontWeight: 400,
        color: theme.colors.primary,
      },

      "& .time": {
        fontFamily: theme.font.Roboto,
        fontSize: 12,
        fontWeight: 400,
        color: theme.colors.primary,
      },
    },
  },

  "& .action": {
    MaxWidth: "8.125rem",
    textAlign: "end",
  },
}));

export const StyledModalCardTextValue = styled(ModalCard.TextValue)({
  textTransform: "capitalize",
});

export const TableContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
  border: "0.5px solid #C4C4C4",
  paddingInline: "1.5rem",
});

export const Content = styled(Box)({});
