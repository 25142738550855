import { createTheme } from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue";

export const useTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 380,
      md: 800,
      lg: 1280,
      xl: 1920,
    },
  },
  colors: {
    primary: "#353535",
    avocado: "#89BD23",
    darkAvocado: "#6E8247",
    mediumAvocado: "#618619",
    lightAvocado: "#DBE4C7",
    white: "#FFF",
    lightGray: "#757575",
    gray: "#C4C4C4",
    whiteGray: "#E7E7E7",
    link: "#3EA0DE",
    alertRed: "#E11414",
  },
  font: {
    Inter: "'Inter', Sans-serif",
    Roboto: "'Roboto', Sans-serif",
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "#FFF",
        backgroundColor: "#89BD23",
        fontFamily: "'Roboto', Sans-serif",
        fontSize: "0.7rem",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#43A047",
        marginTop: 8,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: "#353535",
      },
      daySelected: {
        backgroundColor: "#43A047",
      },
      dayDisabled: {
        color: lightBlue["100"],
      },
      current: {
        color: lightBlue["900"],
      },
    },
    MuiMenu: {
      list: {
        "& li:hover ": {
          backgroundColor: "#D8E5BF",
        },
      },
    },

    MuiCheckbox: {
      root: {
        "& .MuiSvgIcon-root": {
          color: "#757575",
        },
        "&$checked .MuiSvgIcon-root": {
          color: "#89BD23",
        },
      },
    },
  },
});
