import { Box, makeStyles, Modal, styled } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { toast } from "react-toastify";
import { CloseIconButton, CopyIconButton } from "./StyledIconButton";

const useStyles = makeStyles(() => ({
  Modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ModalBody: {
    display: "flex",
    flexDirection: "column",
    width: "30.8125rem",
    backgroundColor: "#FFF",
    padding: ".8125rem",

    "@media (max-width:800px)": {
      maxWidth: "90vw",
    },
  },
  ModalContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "1.6875rem 0 1.6875rem 0",
    gap: "1rem",
  },
  IconAlert: {
    width: "4.5rem",
    height: "4.5rem",
    color: "#89BD23",
  },
  IconCloseContent: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
  },
  IconClose: {
    width: "1.25rem",
    height: "1.25rem",
    color: "#757575",
    cursor: "pointer",
  },
  Text: {
    fontFamily: '"Inter", Sans serif',
    fontSize: "1.125rem",
    fontWeight: 500,
    textAlign: "center",
    maxWidth: "17.4375rem",
    lineHeight: "1.4375rem",
    "@media (max-width:800px)": {
      maxWidth: "100%",
    },
  },
}));

const LinkContent = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "0.5rem",
  justifyContent: "center",
  alignItems: "center",
  "& a": {
    alignItems: "center",
    fontFamily: theme.font.Roboto,
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1rem",
    color: theme.colors.link,
    cursor: "pointer",
    textDecoration: "none",
  },

  "& svg": {
    width: "1.25rem",
    height: "1.25rem",
    color: theme.colors.darkAvocado,
    cursor: "pointer",
  },
}));

export const AlertModal = ({
  open,
  text,
  handleClose,
  link,
  textLink,
  copyText,
}) => {
  const classes = useStyles();

  const handleCopyText = () => {
    navigator.clipboard.writeText(copyText);
    toast.info("Copiado para o clipboard");
  };
  return (
    <Modal className={classes.Modal} open={open} onClose={handleClose}>
      <Box className={classes.ModalBody}>
        <Box className={classes.IconCloseContent}>
          <CloseIconButton onClick={handleClose} />
        </Box>
        <Box className={classes.ModalContent}>
          <CheckCircleOutlineIcon className={classes.IconAlert} />
          <Box className={classes.Text}>{text}</Box>
          {link && (
            <LinkContent>
              <a href={link} rel="noreferrer" target="_blank">
                {textLink}
              </a>
              <CopyIconButton onClick={handleCopyText} />
            </LinkContent>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
