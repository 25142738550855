import { Box, styled } from "@material-ui/core";
import { Modal } from "./Modal";
import { Button } from "../Button";
import { CloseIconButton } from "../StyledIconButton";

const ConfirmModalContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  width: "30.8125rem",
  minHeight: "13.0625rem",
  overflow: "auto",
  padding: "0.8125rem",
  backgroundColor: "#FFF",

  "& .closeIcon": {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    color: theme.colors.lightGray,
    "& svg": {
      cursor: "pointer",
    },
  },

  "& .title": {
    marginTop: "2.5rem",
    fontFamily: theme.font.Inter,
    fontSize: "1.125rem",
    fontWeight: 500,
  },

  "& .actions": {
    display: "flex",
    gap: "3rem",
    marginTop: "3rem",
  },
}));

export const ConfirmModal = ({
  handleClose,
  handleAction,
  confirmText,
  open,
  onClose,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ConfirmModalContent>
        <span className="closeIcon">
          <CloseIconButton onClick={handleClose} />
        </span>
        <span className="title">{confirmText}</span>
        <span className="actions">
          <Button btn="secondary" onClick={handleClose}>
            Não
          </Button>
          <Button onClick={handleAction}>Sim</Button>
        </span>
      </ConfirmModalContent>
    </Modal>
  );
};
